import { doc, DocumentData, getFirestore, OrderByDirection, updateDoc } from "firebase/firestore";
import { ChangeEvent, SetStateAction, useEffect, useMemo, useState } from "react";
import { AppContext, db } from "src";
import Modal from "src/components/modal";
import { withContext } from "src/context";
import { addFirebaseDocument } from "src/firebase/add-firebase-document";
import { IGetCurrency } from "src/types/currency-types";
import { Collections } from "src/utils/enums";
import { IBrand, ICategoryItem } from "src/utils/interfaces";
import CurrencyList from 'currency-list';
import ItemCardInput from "../../pages/Categories/ItemCardInput";
import { deleteFirebaseDocumentByID } from "src/firebase/delete-firebase-document-by-id";
import { useFirebaseCollection } from "src/firebase/use-firebase-collection";
import BrandItemButtom from "src/components/BrandItemButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle, faSquareMinus, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../pages/Categories/Dropdown";
import ItemCardPreview from "../../pages/Categories/ItemCardPreview";
import { useAuth } from "src/pages/auth/hooks";
// import { db } from "src/firebase/firebase-config";
export const DEFAULT_ITEM: ICategoryItem = {
  brand_id: '',
  cat_id: '',
  currency: '',
  description: '',
  favorite: false,
  gift: false,
  id: '',
  img_url: '',
  link_urls: [''],
  price: 0,
  purchased: false,
  title: '',
};

interface IItemModalProps extends Partial<AppContext> {
  item?: ICategoryItem;
  // setNewItem: (item: SetStateAction<ICategoryItem>) => void;
  activeCategoryID: string;
  show: boolean;
  setShow: (value: SetStateAction<boolean>) => void;
}
const ItemModal = (props: IItemModalProps) => {
  const { setIsModalOpen, item, activeCategoryID, show, setShow } = props;
  // const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [shouldResetFields, setShouldResetFields] = useState(false);
  const [activeOption, setActiveOption] = useState<IGetCurrency>(CurrencyList.get('EUR'));
  const [linkUrls, setLinkUrls] = useState<string[]>(['']);
  const [isValidForm, setIsValidForm] = useState(false);
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [dropdownBrandsShow, setDropdownBrandsShow] = useState(false);
  const [currencyList, setCurrencyList] = useState<IGetCurrency[]>([]);
  const [activeBrand, setActiveBrand] = useState<IBrand | undefined>(undefined);
  // const [brandsList, setBrandsList] = useState([]);
  const [newBrandName, setNewBrandName] = useState('');
  const [sortDirection, setSortDirection] = useState<OrderByDirection>('asc');
  const [filteredData, setFilteredData] = useState<IBrand[]>([]);
  const [isAddingBrand, setIsAddingBrand] = useState(false);
  const [editBrandsModalOpen, setEditBrandsModalOpen] = useState(false);
  // const [itemBackup, setItemBackup] = useState<ICategoryItem | undefined>(undefined);

  const currenciesStrings = ['EUR', 'USD', 'GBP', 'RON'];
  const initialItemState = useMemo(() => {
    if (typeof item === 'undefined')
      return {
        ...DEFAULT_ITEM,
        cat_id: activeCategoryID,
        currency: activeOption.code,
        link_urls: linkUrls
      };
    else {
      const backup = { ...item, link_urls: item.link_urls };
      // setItemBackup(backup);
      return backup;
    }
  }, [item]);
  const [newItem, setNewItem] = useState<ICategoryItem>(item ?? initialItemState);

  const { data } = useFirebaseCollection('brands', 'name', sortDirection);
  const { data: allItems } = useFirebaseCollection('items', 'title', sortDirection);
  const { authUser } = useAuth();

  useEffect(() => {
    if (item) {
      setNewItem({ ...item });
      setLinkUrls(item.link_urls);
      // setItemBackup(item);
    }
  }, [item]);

  // console.log('new item ', newItem);

  useEffect(() => {
    if (CurrencyList && !currencyList.length) {
      const newArr: IGetCurrency[] = [];
      currenciesStrings.map(currency => {
        const newObj = CurrencyList.get(currency);
        newArr.push(newObj);
      });

      setCurrencyList(newArr);
    }
  }, [CurrencyList, currenciesStrings]);


  useEffect(() => {

    if (data?.length && newBrandName.length) {
      const filtered = data.filter(item => (item.name.toLowerCase() as string).indexOf(newBrandName) !== -1) as IBrand[];
      if (filtered.length) {
        // const mapped = filtered.map(item => ({
        //   ...item.data(),
        //   id: item.id
        // }));
        setFilteredData(filtered);
      } else {
        setFilteredData([]);
      }
    } else if (data?.length) {
      // const mapped = data.map(item => ({
      //   ...item.data(),
      //   id: item.id
      // }));
      setFilteredData(data as IBrand[]);
    } else {
      setFilteredData([]);
    }

  }, [data, newBrandName]);

  /**
  * Update item when link url changes
  */
  useEffect(() => {
    setNewItem(prev => ({
      ...prev,
      link_urls: linkUrls
    }));
  }, [linkUrls]);

  /**
   * Initialize selected brand
   */
  useEffect(() => {
    if (data.length)
      setActiveBrand({ name: data[0].name, id: data[0].id });
  }, [data]);


  useEffect(() => {
    if (item) {
      setActiveOption(CurrencyList.get(item.currency));
      // setActiveBrand()
      const found = data.find(brand => brand.id === item.brand_id);
      if (found) {
        setActiveBrand({
          id: found.id,
          name: found.name
        });
      }
    }

  }, [item, data, CurrencyList]);

  /** 
   * Check if form is valid to submit
   */
  useEffect(() => {
    const { brand_id, cat_id, currency, img_url, link_urls, price, title } = newItem;
    // console.log('new item', newItem);

    if (
      brand_id.length &&
      cat_id.length &&
      currency.length &&
      img_url.length &&
      link_urls.length &&
      link_urls[0].length &&
      (typeof price === 'number' && price !== 0)
      || (!isNaN(price) && parseInt(`${isNaN(price) ? 0 : price}`, 10) !== 0) &&
      title.length
    ) {
      console.log('is valid');
      setIsValidForm(true);
    }
  }, [newItem]);

  const handleAddItem = async () => {
    // what happens when you click save
    if (authUser) {
      try {
        const response = await addFirebaseDocument<ICategoryItem>(Collections.items, {
          ...newItem,
          // force price 
          price: isNaN(newItem.price) ? 0 : parseInt(`${newItem.price}`, 10),
          // add uid for user specific items
          uid: authUser.uid
        });
        if (response) {

          try {
            // const db = getFirestore();
            const ref = doc(db, 'items', response.id);
            console.log('ref ', ref);


            await updateDoc(ref, { id: response.id });


            resetFields();
            closeAllModals();
          } catch (error) {
            console.log('Error updating document id', error);
          }

        }
      } catch (error) {
        console.log("Error adding firebase document", error);
      }
    }

  };

  const handleEditItem = async () => {
    if (item) {
      try {
        const db = getFirestore();
        const ref = doc(db, Collections.items, item.id);
        console.log('ref ', ref);

        const res = await updateDoc(ref, {
          ...newItem,
          id: item.id
        });

        console.log('res is ', res);

        // resetFields();
        closeAllModals();
      } catch (error) {
        console.log('Error updating document id', error);
      }
    }


  };

  const handleItemChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof ICategoryItem) => {
    // what happens when the newItem changes
    if (key === 'price') {
      // console.log(typeof event.target.value);
      // console.log('is nan ', isNaN(parseInt(event.target.value, 10)));


      setNewItem(prev => ({ ...prev, [key]: isNaN(event.target.value as any) ? NaN : parseInt(event.target.value, 10) }));
    } else
      setNewItem(prev => ({ ...prev, [key]: event.target.value }));
  };

  const handleLinksItemChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    // what happens when the newItem changes
    setLinkUrls(prev => {
      const arr = prev;
      arr[index] = event.target.value;

      return [...arr];
    });
  };

  const handleDeleteItem = async (id: string) => {
    // delete the item
    try {
      const res = await deleteFirebaseDocumentByID(Collections.items, id);
      console.log('deleted item res', res);

    } catch (error) {
      console.log("Error deleting document with id", id, error);
    }
  };

  const handleFavorite = (favorite: boolean) => {
    setNewItem(prev => ({ ...prev, favorite }));
  };
  const handleGift = (gift: boolean) => {
    setNewItem(prev => ({ ...prev, gift }));
  };
  const handlePurchase = (purchased: boolean) => {
    setNewItem(prev => ({ ...prev, purchased }));
  };

  const handleCurrencyDropdownClose = () => {
    setDropdownPopoverShow(false);
  };
  const handleCurrencyDropdownOpen = () => {
    setDropdownPopoverShow(true);
  };

  const handleBrandDropdownClose = () => {
    setDropdownBrandsShow(false);
  };
  const handleBrandDropdownOpen = () => {
    setDropdownBrandsShow(true);
  };

  const handleChangeCurrency = (currency: IGetCurrency) => {
    setActiveOption(currency);
    setNewItem(prev => ({
      ...prev,
      currency: currency.code
    }));
  };

  const handleChangeBrand = (brand: IBrand) => {
    setActiveBrand(brand);
    setNewItem(prev => ({
      ...prev,
      brand_id: brand.id
    }));
  };

  const addNewBrand = async () => {
    console.log('brandName', newBrandName);
    setIsAddingBrand(true);
    // const db = getFirestore();
    console.log('filtered data', filteredData);
    const found = filteredData.find(item => (item.name as string).toLowerCase() === newBrandName.toLowerCase());
    console.log('found', found);
    // Save only when there isn't a brand with the same name in the list
    if (typeof found === 'undefined') {
      // Add a new document with a generated id.
      try {
        console.log('Adding brand...');
        const response = await addFirebaseDocument<Partial<IBrand>>(Collections.brands, { name: newBrandName });
        if (response) {
          try {
            const db = getFirestore();
            const ref = doc(db, Collections.brands, response.id);

            await updateDoc(ref, { id: response.id });

            setIsAddingBrand(false);
          } catch (error) {
            console.log('Error updating document id', error);
            setIsAddingBrand(false);
          }
        }
      } catch (error) {
        console.log("Error adding firebase doc", error);
        setIsAddingBrand(false);
      }
    }
  };

  const deleteBrand = async (id: string) => {
    await deleteFirebaseDocumentByID('brands', id);
  };

  const addLinkItemField = () => {
    setLinkUrls(prev => [...prev, '']);
  };

  const removeLinkItemField = (index: number) => {
    if (index !== 0) {
      // const arr = linkUrls;
      // arr.filter((_, i) => i !== index);
      // const filtered = arr.filter(((_, i) => i !== index));

      setLinkUrls(prev => {
        const arr = prev;
        const filtered = arr.filter(((_, i) => i !== index));

        return [...filtered];
      });
    }
  };

  const closeAllModals = () => {
    setShow(false);
    typeof setIsModalOpen !== 'undefined' && setIsModalOpen(false);
  };

  const resetFields = () => {
    setShouldResetFields(true);
    setNewItem(initialItemState);
    setLinkUrls(['']);
    setIsValidForm(true);
  };

  return (
    <>
      <Modal
        isOpen={show}
        onClose={() => {

          // console.log('paisfpasifapfj');
          closeAllModals();
          // if (itemBackup) setNewItem({ ...itemBackup, link_urls: itemBackup.link_urls });
          // if (typeof item !== 'undefined') {
          //   // console.log('apsfapofapfoaasfoapfoamsf', item);
          //   console.log('ori aici');

          //   // setShow(false);
          //   // typeof setIsModalOpen !== 'undefined' && setIsModalOpen(false);
          //   // setNewItem({ ...item, link_urls: item.link_urls });
          // } else {
          //   console.log('sau aici', itemBackup);

          //   // closeAllModals();
          // }

        }}
        onSubmit={typeof item !== 'undefined' ? handleEditItem : handleAddItem}
        submitDisabled={!isValidForm}
        title={typeof item !== 'undefined' ? "Edit item" : "Add a new item to your wishlist"}
        submitTitle={typeof item !== 'undefined' ? "Update item" : "Add item"}
        dismissTitle="Cancel"
        submitColor="default"
        containerClassName="min-w-[500px]"
      >
        <div className="flex flex-row">
          <div className="flex w-96 flex-col p-3 ml-2 overflow-scroll h-[550px]">
            <ItemCardInput
              value={newItem.title}
              onInput={(event) => handleItemChange(event, "title")}
              placeholder="Title"
              required
              reset={shouldResetFields}
            />

            <ItemCardInput
              value={newItem.img_url}
              onInput={(event) => handleItemChange(event, "img_url")}
              placeholder="Image URL"
              required
              reset={shouldResetFields}
            />

            <div className="flex flex-col">
              {linkUrls.map((link, index, arr) => (
                <div key={index} className="flex">
                  <ItemCardInput
                    value={link}
                    onInput={(event) => handleLinksItemChange(event, index)}
                    placeholder="Link URL"
                    required
                    reset={shouldResetFields}
                  />
                  {index !== 0 && (
                    <FontAwesomeIcon
                      onClick={() =>
                        removeLinkItemField(index)
                      }
                      icon={faSquareMinus}
                      size="2x"
                      className={`
                      text-accent 
                      hover:scale-110 hover:text-secondary
                      ease-in duration-100 transition-all
                      cursor-pointer active:scale-90
                      mt-5 pt-0.5 ml-4
                    `} />
                  )}
                  {arr.length - 1 === index && (
                    <FontAwesomeIcon
                      onClick={() =>
                        addLinkItemField()
                      }
                      icon={faSquarePlus}
                      size="2x"
                      className={`
                        text-accent 
                        hover:scale-110 hover:text-secondary
                        ease-in duration-100 transition-all
                        cursor-pointer active:scale-90
                        mt-5 pt-0.5 ml-4
                      `} />
                  )}
                </div>
              ))}
            </div>

            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center w-5/6">
                <Dropdown
                  containerClassName="flex mt-0.5 items-center justify-end"
                  onClick={handleChangeBrand}
                  isOpen={dropdownBrandsShow}
                  onClose={handleBrandDropdownClose}
                  onOpen={handleBrandDropdownOpen}
                  firstKey="name"
                  secondKey="name"
                  options={data}
                  selectedOption={activeBrand}
                  placement="bottom"
                  placeholder="Brands"
                  simple
                />

                <span className="ml-5 overflow-hidden text-ellipsis whitespace-nowrap text-secondary w-full">{activeBrand?.name}</span>


              </div>
              <FontAwesomeIcon
                onClick={() => {
                  // setEditBrandsModalOpen(true)
                  setShow(false);
                  setEditBrandsModalOpen(true);
                  typeof setIsModalOpen !== 'undefined' && setIsModalOpen(true);
                }}
                icon={faEdit}
                size="2x"
                className={`
                text-accent 
                hover:scale-100 hover:text-secondary
                ease-in duration-100 transition-all
                cursor-pointer scale-90 active:scale-90 translate-x-1
                mt-0.5
              `} />

            </div>

            <div className="flex justify-between">

              <Dropdown
                containerClassName="flex mt-0.5 items-center"
                onClick={handleChangeCurrency}
                isOpen={dropdownPopoverShow}
                onClose={handleCurrencyDropdownClose}
                onOpen={handleCurrencyDropdownOpen}
                firstKey="code"
                secondKey="name"
                options={currencyList}
                selectedOption={activeOption}
              />

              <ItemCardInput
                value={newItem.price}
                onInput={(event) => handleItemChange(event, "price")}
                placeholder="Price"
                type="number"
                required
                containerClassName="w-full ml-3"
                reset={shouldResetFields}
              />

            </div>

            <ItemCardInput
              value={newItem.description}
              onInput={(event) => handleItemChange(event, "description")}
              placeholder="Description"
              long
              type="text"
            />

            {/* <div className="w-full h-40 aspect-square overflow-hidden p-4">
              <h2 className="text-lg">Are you sure you want to the delete the entire category?</h2>
              <h4 className="font-light mt-5">This is also going to delete <span className="font-semibold text-rose-600">every item</span> in the category !</h4>
            </div> */}
          </div>
          <div className="flex w-96 px-4 mr-3">
            {/* <img className="flex w-2/4" src={catGif} alt="Add a cat item" /> */}
            <ItemCardPreview item={newItem} handleFavorite={handleFavorite} handleGift={handleGift} handlePurchase={handlePurchase} />
          </div>
        </div>
      </Modal>
      {/* Edit Brands modal */}
      <Modal
        isOpen={editBrandsModalOpen}
        onClose={() => {
          setEditBrandsModalOpen(false);
          setShow(true);
          typeof setIsModalOpen !== 'undefined' && setIsModalOpen(true);
        }}
        onSubmit={handleAddItem}
        submitDisabled={false}
        title="Edit brands  &nbsp;"
        submitTitle="Update"
        dismissTitle="Close"
        submitColor="default"
        containerClassName="min-w-[500px]"
        hideSubmitButton
      // onReset={resetItem}
      >
        <div>

          <div className="flex px-3">
            <ItemCardInput value={newBrandName} onInput={(event) => setNewBrandName(event.target.value)} placeholder="New brand name" />
            <FontAwesomeIcon
              onClick={() =>
                addNewBrand()
              }
              icon={faSquarePlus}
              size="2x"
              className={`
                text-accent 
                hover:scale-110 hover:text-secondary
                ease-in duration-100 transition-all
                cursor-pointer active:scale-90
                mt-5 pt-0.5 ml-4
                ${isAddingBrand ? 'disabled' : ''}
              `} />
          </div>
          <div className="flex flex-col px-3 my-3">

            <div className="flex justify-between items-center">
              <h3 className="text-lg text-bg-light ml-2 mb-3">Brands list</h3>
              <div className="flex">
                Sort <button
                  className="text-bg-dark-50 ml-3"
                  onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
                >
                  {sortDirection === 'asc' ? 'ASC' : 'DESC'}
                </button>
              </div>
            </div>

            {filteredData.length ? (
              <ul className="flex flex-col h-64 overflow-scroll no-scrollbar">
                {filteredData.map((item, index) => (
                  <li key={index} className={`pl-2 py-2 odd:bg-bg-cold-100 flex justify-between`}>
                    <span>
                      {item?.name}
                    </span>
                    <div className="mx-4 text-secondary">
                      <BrandItemButtom
                        onClick={() => deleteBrand(item.id)}
                        id={item.id}
                        allItems={allItems}
                      />
                      {/* <button onClick={() => deleteBrand(item.id)}>
                        {getItemCount(item.id)}
                        <FontAwesomeIcon icon={faTrash} />
                      </button> */}
                    </div>
                  </li>
                ))}
              </ul>
            )
              : (
                newBrandName.length && filteredData.length
                  ?
                  <div className="flex flex-col h-64 overflow-scroll">
                    <p className="ml-2"></p>
                  </div>
                  :
                  <div className="flex flex-col h-64 overflow-scroll">
                    <p className="ml-2">List is empty</p>
                  </div>
              )}
          </div>
        </div>
        <div className="ml-2 my-2">
          <FontAwesomeIcon icon={faInfoCircle} className='text-bg-cold-500 mx-2' />
          <span className="text-bg-cold-500">Brands with no items can be deleted</span>
        </div>
      </Modal>
    </>
  );
};

export default withContext(ItemModal);