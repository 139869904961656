import { createContext, JSXElementConstructor, PropsWithChildren, useContext } from 'react';
import { AppContext } from 'src';

const initialContext: AppContext = {
    isAuthLoading: false,
    isLoggedIn: false,
    isModalOpen: false,
    setIsModalOpen: () => { },
    setUserDetails: () => { },
    user: null,
    userDetails: {
        firstName: '',
        lastName: ''
    }
};

export const MidnightContext = createContext(initialContext);

export const withContext = <T,>(Component: JSXElementConstructor<T>) => (props: PropsWithChildren<T>) => (
    <MidnightContext.Consumer>{value => <Component {...value} {...props} />}</MidnightContext.Consumer>
);

export const useMidnightContext = (): AppContext => {
    return useContext(MidnightContext);
};