import { FC } from "react";

interface IProps { }

const NotFound: FC<IProps> = () => {
    return (
        <div className="flex w-full">
            Page not found</div>
    );
};

export default NotFound;