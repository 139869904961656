import { ChangeEvent, useMemo } from "react";

interface IProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  scale: number;
  disabled: boolean;
}
const NewImageRangeInput = ({ scale, onChange, disabled }: IProps) => {
  const ticks = useMemo(() => {
    let arr = [];
    for (let i = 0; i <= 2.1; i += 0.5) {
      arr.push(parseFloat(i.toFixed(1)));
    }
    return arr;
  }, []);

  return (
    <>
      <input
        type="range"
        style={{ marginTop: 0 }}
        className="bg-transparent form-range appearance-none w-full h-6 p-0 focus:outline-none focus:ring-0 focus:shadow-none"
        onChange={onChange}
        step="0.1"
        disabled={disabled}
        min="0"
        max="2"
        value={scale}
        list="steplist"
      />
      <div id="steplist" className="flex flex-row w-full justify-between h-full">
        {ticks.map((elem, index) =>
          <span className="flex text-bg-cold-600" key={index}>{elem}%</span>
        )}
      </div>
    </>
  );
};

export default NewImageRangeInput;