import { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentReference, getDoc, getDocs, query, QueryDocumentSnapshot, updateDoc, where } from "firebase/firestore";
import { useCollection, useCollectionOnce } from "react-firebase-hooks/firestore";
import { deleteObject, getDownloadURL, getStorage, ref, ref as storeRef, uploadBytesResumable, UploadMetadata } from "firebase/storage";
import Modal from "src/components/modal";
import { useDebounceEffect } from "../Home/useDebounceEffect";
import CatLoader from "./CatLoader";
import { Collections } from "src/utils/enums";
import { db } from "src";
import { useAuth } from "../auth/hooks";
import { Header } from "src/components";
import AddNewCategoryButton from "./AddNewCategoryButton";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { ETabsNames } from "../Home/utils/category-tabs";
import { getSlug } from "../Home/utils/get-slug";
import { toast } from "react-toastify";
import TabButton from "../Home/TabButton";
import { centerAspectCrop } from "../Home/utils/center-aspect-crop";
import { canvasPreview } from "../Home/CanvasPreview";
import InputFile from "../Home/InputFile";
import NewCategoryTabPane from "./NewCategoryTabPane";
import NewCategoryImage, { CategoryImageData } from "./NewCategoryImage";
import SimpleButton from "./SimpleButton";
import Tooltip from "./Tooltip";
import NewImageRangeInput from "./NewImageRangeInput";
import AddImageHint from "./AddImageHint";
import ItemCardInput from "./ItemCardInput";
import CategoryImagePreview from "./CategoryImagePreview";
import CategoryItem from "./CategoryItem";
import { ICategory } from "src/utils/interfaces";


const Categories = () => {
  const { authUser } = useAuth();
  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadedCategories] = useCollection(
    query(
      collection(db, Collections.categories) as CollectionReference<ICategory>,
      where('uid', '==', authUser?.uid ?? '')
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [showEditCategoryNameModal, setShowEditCategoryNameModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<QueryDocumentSnapshot<ICategory> | undefined>(undefined);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleNavlinkClick = (event: MouseEvent, isActive: boolean) => {
    if (isActive)
      event.preventDefault();
  };
  const handleDeleteCategory = async () => {
    if (selectedCategory?.id) {
      try {
        const q = query<any>(
          collection(db, Collections.items),
          where("cat_id", "==", selectedCategory.id),
        );
        const snapshot = await getDocs(q);
        const results = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        results.forEach(async result => {
          const docRef = doc(db, Collections.items, result.id);
          await deleteDoc(docRef);
        });

        const response = await deleteDoc(doc(db, `${Collections.categories}/${selectedCategory?.id}`));
        console.log("Category deleted", response);
      } catch (error) {
        console.log('Error deleting category', error);
      }
      setShowConfirmDeleteModal(false);
    }
  };

  // const [editedCategoryName, setEditedCategoryName] = useState(selectedCategory?.data().name);

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [selectedImage, setSelectedImage] = useState<CategoryImageData | undefined>(undefined);
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
  const [fileName, setFileName] = useState<string | null>(null);
  const [scaleValue, setScaleValue] = useState(1);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [crop, setCrop] = useState<Crop>();

  const [activeTab, setActiveTab] = useState(ETabsNames.Browse);
  // Need to separate 'active' and 'show' classes for the fade effect to work
  const [shownTab, setShownTab] = useState(ETabsNames.Browse);
  const [categoryName, setCategoryName] = useState<string>('');

  const [showModal, setShowModal] = useState(false);
  const [isEditingCategory, setIsEditingCategory] = useState(false);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const categoryInputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const storageRef = useMemo(() => query(
    collection(db, Collections.storage),
    // where("name", "!=", "default.jpg"),
    where("uid", "==", authUser?.uid ?? ''),
  ), [authUser]);

  const defaultRef = query(
    collection(db, Collections.storage),
    where("name", "==", "default.jpeg"),
  );
  const [defaultPhoto] = useCollectionOnce(defaultRef);
  const [collectionValues] = useCollection(storageRef,
    { snapshotListenOptions: { includeMetadataChanges: true } },
  );
  const [uploadedDocID, setUploadedDocID] = useState<string | undefined>(undefined);
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleUpdateCategoryName = async () => {
    if (selectedCategory) {
      const docref = doc(db, `${Collections.categories}/${selectedCategory.id}`) as DocumentReference<ICategory>;

      const slug = getSlug(newCategoryName);

      // Check if category exists
      const q = query(
        collection(db, Collections.categories),
        where("slug", "==", slug),
      );
      try {
        const snapshot = await getDocs(q);

        if (snapshot.docs[0]) {
          toast.error(`There is already a category with this name: ${newCategoryName}`,
            { position: 'bottom-center' });
          return;
        }

        try {
          await updateDoc<ICategory>(docref, {
            name: newCategoryName,
            slug
          });
          setShowEditCategoryNameModal(false);
          setSelectedCategory(undefined);
        } catch (error) {
          console.log("Error updating category name");
        }
      } catch (error) {
        console.log('Error getting docs', error);
      }
    }
  };


  useEffect(() => {
    if (uploadedDocID) {
      clearFile();

      const found = collectionValues?.docs.find(value => value.id === uploadedDocID);
      const data = found?.data() as CategoryImageData;

      if (data) {
        setSelectedImage(data);
        setUploadedDocID(undefined);
      }
    }
  }, [collectionValues, uploadedDocID]);

  const defaultImage = useMemo(() =>
    defaultPhoto?.docs[0]
    , [defaultPhoto]);

  const resetUploadFile = () => {
    // setCreatedImgUrl('');
    setFileName(null);
    setScaleValue(1);
    setCompletedCrop(undefined);
    setCrop(undefined);
    setImgSrc(undefined);
    // setSelectedImage(undefined)
  };

  const handleOnOpen = () => {
    setShowModal(true);
    setTimeout(() => {
      if (categoryInputRef.current) {
        categoryInputRef.current.focus();
      }
    }, 200);
  };

  const handleCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 1)
      setCategoryName(event.target.value.toUpperCase());
    else setCategoryName(event.target.value);
  };

  const [shouldResetLinkInput, setShouldResetLinkInput] = useState(false);
  const [shouldResetEditCategoryInput, setShouldResetEditCategoryInput] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    resetUploadFile();
    setSelectedImage(undefined);
    setCategoryName('');
    setImageLink('');
    setShouldResetLinkInput(true);
    setSelectedCategory(undefined);
    setIsEditingCategory(false);
  };

  // const categoryChange = useCallback(
  //   (ev: ChangeEvent<HTMLInputElement>) =>
  //     setCategoryToAdd(ev.target.value)
  //   ,
  //   [],
  // );

  const handleImageSelect = (data: CategoryImageData) => {
    setCompletedCrop(undefined);

    // resetUploadFile();
    setSelectedImage(data);
  };
  const handleImageDelete = async (image: CategoryImageData) => {
    console.log('data', image);
    // deleteObject()
    // get storage ref
    console.log('image.name', image.name);

    if (authUser) {
      // const reference = ref(storageRef, 'images/desert.jpg');
      const storage = getStorage();
      const storageRef = storeRef(storage, `${Collections.categories}/${authUser.uid}/${image.name}`);
      console.log('.ref', ref);

      const q = query(
        collection(db, Collections.storage),
        where("name", "==", image.name),
      );
      // const unsub = onSnapshot(q, (doc: any) => {
      //   console.log("Current data: ", doc.data());
      // });
      try {
        console.log('Getting doc...');
        const snapshot = await getDocs(q);
        const dbRef = snapshot.docs[0].ref;
        console.log('Db ref ', dbRef);

        if (storageRef && dbRef) {
          try {
            console.log('Deleting storage object...');
            const deleteValue = await deleteObject(storageRef);
            console.log('deleted value', deleteValue);

            try {
              console.log('Getting db doc...');
              deleteDoc(dbRef);
              if (defaultImage)
                handleImageSelect(defaultImage.data() as CategoryImageData);
            } catch (error) {
              console.log("Error deleting firestore document");
            }
          } catch (error) {
            console.log('Error deleting storage object', error);
          }
        }
      } catch (error) {
        console.log('Error getting query snapshot', error);
      }
    }

  };

  const handleTabChange = (_event: MouseEvent, title: number) => {
    setActiveTab(title);
  };

  const handleOnUpdate = async () => {
    // setIsEditingCategory(false);
    if (imageLink.length && activeTab === ETabsNames.Link && selectedCategory) {
      try {
        await updateDoc(selectedCategory.ref, {
          img_url: imageLink,
          name: categoryName,
        });

        console.log("Document updated.");
        closeModal();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
    else if (selectedImage && selectedCategory) {
      // save an already uploaded image
      try {
        await updateDoc(selectedCategory.ref, {
          img_url: selectedImage.url,
          name: categoryName,
        });
        console.log("Updated document");
        closeModal();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
    else {
      // display an error or disable the button
      console.log('error');
    }
  };

  const handleOnSubmit = async () => {
    // First should check for the category name
    // Should not be able to add duplicate names

    const slug = getSlug(categoryName);

    const q = query(
      collection(db, Collections.categories),
      where("slug", "==", slug),
    );
    const snapshot = await getDocs(q);

    if (snapshot.docs[0]) {
      toast.error(`There is already a category with this name: ${categoryName}`,
        { position: 'bottom-center' });
      return;
    }
    console.log(snapshot.docs[0]);

    if (imageLink.length && activeTab === ETabsNames.Link) {
      try {
        const docRef = await addDoc(collection(db, Collections.categories), {
          img_url: imageLink,
          name: categoryName,
          uid: authUser?.uid,
          slug
        });

        console.log("Document written with ID: ", docRef.id);
        closeModal();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
    else if (selectedImage) {
      // save an already uploaded image
      try {
        const docRef = await addDoc(collection(db, Collections.categories), {
          img_url: selectedImage.url,
          name: categoryName,
          uid: authUser?.uid,
          slug
        });
        console.log("Document written with ID: ", docRef.id);
        closeModal();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
    else {
      // display an error or disable the button
      console.log('error');
    }
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader?.result?.toString() || ''),
      );
      reader.readAsDataURL(e.target.files[0]);
      // const url = URL.createObjectURL(e.target.files[0]);
      // setCreatedImgUrl(url);
      setFileName(e.target.files[0].name);
      console.log('name', e.target.files[0].name);
    }
  };

  const onRemoveFile = () => {
    clearFile();
  };

  const clearFile = () => {
    setCrop(undefined); // Makes crop preview update between images.
    const reader = new FileReader();
    reader.removeEventListener('load', () =>
      setImgSrc(undefined),
    );
    setImgSrc(undefined);
    // setCreatedImgUrl(undefined);
    setFileName(null);
    setProgress(undefined);
    setCompletedCrop(undefined);
    setScaleValue(1);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  const uploadImage = () => {
    if (completedCrop && fileName && authUser) {
      // Create the file metadata
      /** @type {UploadMetadata} */
      const metadata: UploadMetadata = {
        contentType: 'image/jpeg',
      };

      const storage = getStorage();
      const uniqueFileName = `${fileName}-${new Date().toISOString()}`;
      const ref = storeRef(storage, `${Collections.categories}/${authUser.uid}/${uniqueFileName}`);

      previewCanvasRef.current?.toBlob((blob) => {
        if (blob) {
          const uploadTask = uploadBytesResumable(ref, blob, metadata);

          uploadTask.on('state_changed',
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              setProgress(Math.round(progress));
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  toast.info('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  toast.error("You do not have permission to access storage");
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  toast.error("Upload canceled by user");
                  break;

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  toast.error("An unknown error has occured");
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL);

                // set firestore database here with category name and downloadURL
                try {
                  const doc = await addDoc(collection(db, Collections.storage), {
                    name: uniqueFileName,
                    uid: authUser?.uid,
                    url: downloadURL
                  });
                  // Set tab to Browser
                  setActiveTab(ETabsNames.Browse);
                  setUploadedDocID(doc.id);

                } catch (error) {

                }
              });
            }
          );
          // uploadBytes(ref, file).then((snapshot) => {
          //   console.log('Uploaded a blob or file!');
          // });
        }
      }, "image/png", 1);
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scaleValue,
          // rotate,
        );
      }
    },
    100,
    [completedCrop, scaleValue, previewCanvasRef.current, imgRef.current],
  );

  useDebounceEffect(() => {
    // Delay the 'show' class for the fade effect
    setShownTab(activeTab);
  }, 100, [activeTab]);


  useEffect(() => {
    const t = searchParams.get('t');
    if (t) {
      searchParams.delete('t');
      setSearchParams(searchParams);
    }
  }, [routeParams, searchParams]);


  const [imageLink, setImageLink] = useState<string>('');
  const [linkHasError, setLinkHasError] = useState(false);
  const newCategoryNameRef = useRef<HTMLInputElement | null>(null);

  const submitDisabled = useMemo(() => {
    if (activeTab === ETabsNames.Link) {
      return imageLink.length < 5 || categoryName.length === 0 || linkHasError;
    }
    return !selectedImage || categoryName.length === 0;
  }, [activeTab, imageLink, categoryName, selectedImage]);


  // if (isLoadingCategories) {
  //   return (
  //     <CatLoader />
  //   );
  // }

  return (
    <>
      {loadedCategories ? (
        <>
          <div className="flex">
            <Header>Categories</Header>
          </div>
          <div className="grid-flow-row grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-2 grid-cols-1 gap-2 sm:gap-4 md:gap-10 md:mt-16">
            <AddNewCategoryButton onClick={handleOnOpen} />
            {loadedCategories.docs.map((doc, index) => (
              <CategoryItem
                key={index}
                selectedCategory={selectedCategory}
                defaultUrl={defaultImage?.data().url}
                doc={doc}
                onCategoryClick={handleNavlinkClick}
                onEditClick={() => {
                  setShowEditCategoryNameModal(true);
                  setTimeout(() => {
                    newCategoryNameRef.current?.focus();
                    setNewCategoryName(doc.data().name);
                  }, 100);
                }}
                onImageClick={() => {
                  setIsEditingCategory(true);
                  setCategoryName(doc.data().name);
                  setShowModal(true);
                  setSelectedImage((prev) => ({
                    ...prev,
                    url: doc.data().img_url
                  }) as CategoryImageData);
                }}
                onDeleteClick={() => setShowConfirmDeleteModal(true)}
                onToggleClick={setSelectedCategory}
              />
            ))}
          </div>
        </>
      )
        : (<CatLoader />)
      }
      <Modal
        isOpen={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        onSubmit={handleDeleteCategory}
        submitDisabled={false}
        title="Confirm delete"
        submitTitle="Delete"
        dismissTitle="Cancel"
        submitColor="delete"
      >
        <div className="flex flex-row w-full">
          <div className="flex w-full flex-col p-3">
            <div className="w-full p-4">
              <h2 className="text-lg">Are you sure you want to the delete the entire category?</h2>
              <h4 className="font-light mt-5">This is also going to delete <span className="font-semibold text-rose-600">every item</span> in the category !</h4>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showEditCategoryNameModal}
        onClose={() => {
          setShowEditCategoryNameModal(false);
          setNewCategoryName('');
          setShouldResetEditCategoryInput(true);
        }}
        onSubmit={handleUpdateCategoryName}
        submitDisabled={newCategoryName.length === 0}
        title="Edit category name"
        submitTitle="Update"
        dismissTitle="Cancel"
      >
        <div className="flex flex-row w-full">
          <div className="flex w-full flex-col p-3">
            <div className="w-full h-40 aspect-square overflow-hidden p-4">
              {/* <input
                placeholder={editedCategoryName}
                value={editedCategoryName}
                onChange={(event) => setEditedCategoryName(event.target.value)}
              /> */}
              <ItemCardInput
                // value={newCategoryName.length ? newCategoryName : selectedCategory?.data()}
                innerRef={newCategoryNameRef}
                value={newCategoryName}
                onInput={(event) => {
                  setNewCategoryName(event.target.value);
                }}
                containerClassName="flex"
                placeholder="New category name"
                required

                reset={shouldResetEditCategoryInput}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        onClose={closeModal}
        onSubmit={isEditingCategory ? handleOnUpdate : handleOnSubmit}
        submitDisabled={submitDisabled}
        title={isEditingCategory ? "Update category image" : "Add a new category"}
        submitTitle={isEditingCategory ? "Update" : "Save"}
        dismissTitle="Cancel"
      >

        <div className="flex flex-row">
          <div className="flex w-1/3 flex-col p-3">
            {Boolean(completedCrop)
              ? (
                <div className="w-auto h-auto aspect-square overflow-hidden p-4 border-bg-cold">
                  <canvas
                    ref={previewCanvasRef}
                    className="w-full shadow-md shadow-bg-cold-200 object-contain 
                  border border-bg-cold-300 rounded-md"
                  />
                </div>
              )
              : Boolean(selectedImage || defaultImage?.data().url || imageLink.length)
                ?
                <CategoryImagePreview src={
                  selectedImage
                    ? selectedImage?.url
                    : imageLink.length
                      ? imageLink
                      : defaultImage?.data().url
                      ?? ''
                }
                  onImgError={setLinkHasError}
                />
                : <p>Loading</p>
            }
            <div className="mx-3 mt-3">
              <input
                id="category-name"
                placeholder="Category name"
                value={categoryName}
                onChange={handleCategoryName}
                ref={categoryInputRef}
                className="focus:outline-none border-b border-b-accent-300 w-full" />
            </div>
          </div>

          <div className="flex flex-col w-2/3 m-3">
            <ul
              className="nav nav-tabs flex flex-col md:flex-row list-none border-b-0 pl-0 mb-2 relative"
              id="tabs"
              role="tablist">
              <li className="nav-item" role="presentation">
                <TabButton
                  Enum={ETabsNames}
                  index={ETabsNames.Browse}
                  activeTab={activeTab}
                  onClick={handleTabChange} />
                {collectionValues?.docs?.length &&
                  collectionValues.docs.length > 0
                  ? activeTab === ETabsNames.Browse &&
                  <span className="text-xs text-center pr-6 absolute right-0 top-0 translate-y-5 text-bg-light-300">
                    {collectionValues?.docs.length} files
                  </span>
                  : null
                }
              </li>
              <li className="nav-item flex flex-row" role="presentation">
                <TabButton
                  Enum={ETabsNames}
                  index={ETabsNames.Upload}
                  activeTab={activeTab}
                  onClick={handleTabChange} />

              </li>
              <li className="nav-item flex flex-row" role="presentation">
                <TabButton
                  Enum={ETabsNames}
                  index={ETabsNames.Link}
                  activeTab={activeTab}
                  onClick={handleTabChange} />
              </li>
              {activeTab === ETabsNames.Upload &&
                <Tooltip position="bottom" element={
                  <div className="text-bg-light flex items-center">
                    <FontAwesomeIcon icon={faInfoCircle} size="xl" className="text-bg-cold-500 ml-2" />
                  </div>
                }>
                  <AddImageHint />
                </Tooltip>}
            </ul>
            <div className="tab-content w-full" id="tabs-tabContent3">
              <NewCategoryTabPane shownTab={shownTab} activeTab={activeTab} tab={ETabsNames.Browse}>
                {/* Images the user uploads */}

                {collectionValues?.docs.map((image, index) => (
                  <NewCategoryImage
                    key={index}
                    selectedImage={selectedImage}
                    categoryImage={image}
                    onClick={handleImageSelect}
                    onDelete={handleImageDelete}
                  />
                ))}
                {/* Default image */}
                {defaultImage?.data() &&
                  <NewCategoryImage
                    categoryImage={defaultImage}
                    selectedImage={selectedImage}
                    onClick={handleImageSelect}
                    canDelete={false}
                  />
                }
              </NewCategoryTabPane>

              <NewCategoryTabPane shownTab={shownTab} activeTab={activeTab} tab={ETabsNames.Upload}>
                {fileName === null ?
                  <InputFile id="tabbedFileInput" className="grid grid-flow-row col-span-3 h-9" type="file" accept="image/*" onChange={onSelectFile} />
                  :
                  <div className="grid grid-flow-row gap-2 col-span-5 grid-cols-5 h-full">

                    <div className="grid col-span-3 h-[200px]">
                      {Boolean(imgSrc) && (
                        <ReactCrop
                          maxHeight={200}
                          maxWidth={200}
                          crop={crop}
                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={1}
                          className=""
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scaleValue})` }}
                            onLoad={onImageLoad}
                            className="h-[200px] object-cover"
                          />
                        </ReactCrop>
                      )}
                    </div>
                    <div className="grid col-span-2 gap-y-2">
                      <div className="grid space-y-2 gap-y-2">
                        <SimpleButton onClick={onRemoveFile}>
                          Select another file
                        </SimpleButton>
                        <SimpleButton onClick={() => uploadImage()} variant="accent">
                          Upload{progress ? `  ${progress} %` : ``}
                        </SimpleButton>
                      </div>
                      {fileName
                        ? <div className="grid place-content-center text-center gap-y-2">
                          {progress
                            ? <>
                              <span className="">
                                Uploading...
                              </span>
                              <p className="flex">{'Upload is ' + progress + '% done'}</p>
                            </>
                            : null
                          }
                        </div>
                        : null
                      }
                    </div>
                    <div className="Crop-Controls grid col-span-5 grid-cols-5 space-x-2">
                      <div className="grid col-span-3">
                        {Boolean(imgSrc) && (
                          <NewImageRangeInput
                            disabled={!imgSrc}
                            scale={scaleValue}
                            onChange={({ target }) => setScaleValue(parseFloat(target.value))}
                          />
                        )}
                      </div>
                    </div>
                  </div>}
              </NewCategoryTabPane>

              <NewCategoryTabPane shownTab={shownTab} activeTab={activeTab} tab={ETabsNames.Link}>
                <div className="grid grid-flow-col gap-2 gap-x-4 col-span-5 grid-cols-5">
                  <ItemCardInput
                    value={imageLink}
                    onInput={(event) => {
                      setImageLink(event.target.value);
                      setSelectedImage(undefined);
                    }}
                    containerClassName="grid col-span-5 w-[500px] pr-8 items-start"
                    placeholder="Link URL"
                    required
                    reset={shouldResetLinkInput}
                  />
                </div>
              </NewCategoryTabPane>

            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Categories;