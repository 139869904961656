export namespace Collections {
    export const brands = 'brands';
    export const categories = 'categories';
    export const currencies = 'currencies';
    export const items = 'items';
    export const storage = 'storage';
    export const users = 'users';
    // brands: 'brands',
    // categories: 'categories',
    // currencies: 'currencies',
    // items: 'items',
    // storage: 'storage'
};

export enum ECategoryTabs {
    "Wishlist" = 0,
    "Purchased",
    "Gifts"
}