import { createRef, FC, RefObject, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import 'tw-elements';
import reportWebVitals from './reportWebVitals';
import { NavLink, RouterProvider, useLocation, useOutlet, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import { CSSTransition, SwitchTransition, } from 'react-transition-group';
import Wishlist from './pages/Wishlist';
import Collection from './pages/Collection';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';
import { initializeApp } from 'firebase/app';
import './index.scss';
import 'react-image-crop/src/ReactCrop.scss';
import { Categories, CategoryDetail } from './pages/Categories';
import { collection, getFirestore } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { MidnightContext } from './context';
import App from './App';
import { useEffect } from 'react';
// import initFirebase from './firebase/firebase-config';
import { Collections } from './utils/enums';
// import { firebaseConfig } from './firebase/firebase-config';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { authRoutes, routes } from './utils/routes';
// import { ProtectedRoute } from './pages/auth/ProtectedRoute';
import Login from './pages/auth/Login';
import ProtectedRoute from './pages/auth/ProtectedRoute';
import SignUp from './pages/auth/SignUp';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from './pages/auth/AuthProvider';
import ForgotPassword from './pages/auth/ForgotPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
// import app from './firebase/firebase-config';


export function useLoader() {
  // const contacts = await getContacts();
  const db = getFirestore();
  const [value, _loading, error] = useCollection(
    collection(db, Collections.categories),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  return { value, error };
}

export interface IRoute {
  path: string,
  name: string,
  element: JSX.Element,
  nodeRef: RefObject<HTMLDivElement>,
  show: boolean;
}
// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     element: <Home />,
//     nodeRef: createRef<HTMLDivElement>(),
//     show: true
//   },
//   {
//     path: '/wishlist',
//     name: 'WishList',
//     element: <Wishlist />,
//     nodeRef: createRef<HTMLDivElement>(),
//     show: true
//   },
//   {
//     path: '/collection',
//     name: 'Collection',
//     element: <Collection />,
//     nodeRef: createRef<HTMLDivElement>(),
//     show: true
//   },
//   {
//     path: '/profile',
//     name: 'Profile',
//     element: <Profile />,
//     nodeRef: createRef<HTMLDivElement>(),
//     show: true
//   },
//   {
//     path: '/categories',
//     name: 'Categories',
//     element: <Categories />,
//     nodeRef: createRef<HTMLDivElement>(),
//     show: true
//   },
//   {
//     path: '/categories/:slug',
//     name: 'Categories',
//     element: <CategoryDetail />,
//     nodeRef: createRef<HTMLDivElement>(),
//     show: false
//   },
//   // {
//   //   path: '*',
//   //   name: 'Not Found',
//   //   element: <NotFound />,
//   //   nodeRef: createRef<HTMLDivElement>(),
//   // },
// ];

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  }
);

export const auth = getAuth();
export const db = getFirestore();
interface IAppProps {

}
interface IUserDetails { firstName: string, lastName: string; }

export interface AppContext extends IAppProps {
  isModalOpen: boolean;
  isLoggedIn: boolean;
  user: User | null;
  userDetails: IUserDetails;
  setUserDetails: React.Dispatch<React.SetStateAction<IUserDetails>>;
  isAuthLoading: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  // usedRoutes: IRoute[];
}

const MidnightApp: FC<IAppProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [userDetails, setUserDetails] = useState<IUserDetails>({ firstName: '', lastName: '' });
  const [isAuthLoading, setIsAuthLoading] = useState(false);

  useEffect(() => {
    setIsAuthLoading(true);

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        // console.log("uid", user.email);
        setUser(user);
        setIsLoggedIn(true);
        setIsAuthLoading(false);
      } else {
        // User is signed out
        // ...
        console.log("user is logged out");
        setIsLoggedIn(false);
        setIsAuthLoading(false);
      }
    }, error => {
      console.log('there was an error ', error);
    });

  }, []);

  // const usedRoutes = useMemo(() => {
  //   return isLoggedIn ? routes : authRoutes;
  // }, [isLoggedIn]);

  const getContext = (): AppContext => {
    return {
      ...props,
      isModalOpen,
      setIsModalOpen,
      isLoggedIn,
      user,
      userDetails,
      setUserDetails,
      isAuthLoading,
      // usedRoutes
    };
  };
  return (
    <>
      <MidnightContext.Provider value={getContext()}>
        <Router>
          <AuthProvider>
            <Routes>


              <Route path="/" element={
                <ProtectedRoute>
                  <App />
                </ProtectedRoute>
              }>
                <Route index element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                } />
                <Route path="/wishlist" element={
                  <ProtectedRoute>
                    <Wishlist />
                  </ProtectedRoute>
                } />
                <Route path="/collection" element={
                  <ProtectedRoute>
                    <Collection />
                  </ProtectedRoute>
                } />
                <Route path="/categories" element={
                  <ProtectedRoute>
                    <Categories />
                  </ProtectedRoute>
                } />
                <Route path="/categories/:slug" element={
                  <ProtectedRoute>
                    <CategoryDetail />
                  </ProtectedRoute>
                } />
                <Route path="/profile" element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                } />
              </Route>
              {/* <Route path="/" element={
                <ProtectedRoute>


                </ProtectedRoute>}>
              </Route> */}

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/verify" element={<VerifyEmail />} />

              <Route path="*" element={<ProtectedRoute>
                <NotFound />
              </ProtectedRoute>} />
              {/* <Route element={<ProtectedRoute >
            <Login />
          </ProtectedRoute>} /> */}
              {/* <Route element={<Login />} path="*" /> */}

              {/* <Route path="*" element={<NotFound />} /> */}
              {/* <App /> */}
            </Routes>
          </AuthProvider>
        </Router>
      </MidnightContext.Provider>
      <ToastContainer />
    </>
  );
};

const AppOverview = () => {
  return (
    <>
      <Route path="/" element={<Home />} />
      <Route path="/wishlist" element={<Wishlist />} />
    </>
  );
};

// const router = createBrowserRouter([

// Create navigation component separate from the routes - too many route components
// Create categories overview page to show all categories
// Create category details page with back button
// Clean up index page
// Separate all functions into their own components
// Break up into smaller bits
// Create hooks for reusable collection code for Firestore


// {
//   path: '/',
//   element:
//     //  <ProtectedRoute>
//     <MidnightApp />,
//   /* </ProtectedRoute>, */
//   // loader: useLoader,
//   children: authRoutes.concat(routes).map((route) => ({
//     index: route.path === '/',
//     path: route.path === '/' ? undefined : route.path,
//     element: route.element,
//   })),
//   errorElement: <NotFound />,

// },
// {
//   path: '/categories/:id',
//   element: <CategoryDetail />
// }
// ]);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<MidnightApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
