import { FC, useEffect, useMemo, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart as heartOutline, faSquareCheck as checkOutline } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare, faCommentDots, faEllipsis, faGift, faGifts, faHeart as heartFilled, faListDots, faSquareCheck as checkFilled } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import catLogo from '../../assets/404.gif';
import { Link } from "react-router-dom";
import ItemIcon from "./ItemIcon";
import ItemDescription from "./ItemDescription";
import { IBrand, ICategory, ICategoryItem } from "src/utils/interfaces";
import { Dropdown } from "react-bootstrap";
import MenuDropdown from "./MenuDropdown";
import Modal from "src/components/modal";
import { deleteFirebaseDocumentByID } from "src/firebase/delete-firebase-document-by-id";
import ItemModal from "src/components/item-modal/ItemModal";
import { useFirebaseCollection } from "src/firebase/use-firebase-collection";
// import Dropdown from "./Dropdown";

interface IItemCardProps {
  item: ICategoryItem;
  handleFavorite: (id: string, favorite: boolean) => void;
  handleGift: (id: string, favorite: boolean) => void;
  handlePurchase: (id: string, favorite: boolean) => void;
  activeCategory: ICategory;
}
const ItemCard: FC<IItemCardProps> = ({ item, handleFavorite, handleGift, handlePurchase, activeCategory }) => {

  const likedIcon = useMemo(() => item.favorite ? heartFilled : heartOutline as IconProp, [item]);
  const giftIcon = useMemo(() => item.gift ? faGifts : faGift as IconProp, [item]);
  const purchasedIcon = useMemo(() => item.purchased ? checkFilled : checkOutline as IconProp, [item]);


  // should disable the gift button once the item is bought?

  const [imgHasError, setImgHasError] = useState(false);


  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const handleEditMenuClick = () => {

  };
  const handleDropdownClick = () => {

  };
  const handleEditMenuClose = () => {
    setIsEditMenuOpen(false);
  };
  const handleEditMenuOpen = () => {
    setIsEditMenuOpen(true);
  };

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<ICategoryItem | undefined>(undefined);
  const handleDeleteItem = async () => {
    if (selectedItemToDelete?.id) {
      try {
        await deleteFirebaseDocumentByID('items', selectedItemToDelete.id);
        console.log("Item deleted");
        setShowConfirmDeleteModal(false);
      } catch (error) {
        console.log('Error deleting item', error);
        setShowConfirmDeleteModal(false);
      }
    }
  };

  const promptBeforeDelete = (item: ICategoryItem) => {
    setSelectedItemToDelete(item);
    handleEditMenuClose();
    setShowConfirmDeleteModal(true);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState<ICategoryItem | undefined>(undefined);
  const { data: brands } = useFirebaseCollection('brands', 'name', 'asc');
  const [selectedBrand, setSelectedBrand] = useState<IBrand | undefined>(undefined);

  const handleEditClick = (item: ICategoryItem) => {
    setShowEditModal(true);
    setEditItem(item);
    handleEditMenuClose();
  };

  useEffect(() => {
    if (editItem && item.img_url !== editItem.img_url) {
      setImgHasError(false);
    }
  }, [item]);

  useEffect(() => {
    if (!showEditModal) {
      setEditItem(undefined);
    }
  }, [showEditModal]);

  useEffect(() => {
    if (brands && brands.length > 0) {
      const found = brands.find((brand) => brand.id === item.brand_id);
      if (found) {
        setSelectedBrand({
          id: found.id,
          name: found.name
        });
      }
    }
  }, [brands, item]);

  return (
    <div className="grid col-span-4 mb-8 rounded shadow-lg bg-white">
      {showEditModal && <ItemModal
        activeCategoryID={activeCategory.id}
        show={showEditModal}
        setShow={setShowEditModal}
        item={editItem}
      />
      }
      {showConfirmDeleteModal && <Modal
        isOpen={showConfirmDeleteModal}
        onClose={() => {
          setShowConfirmDeleteModal(false);
          setSelectedItemToDelete(undefined);
        }}
        onSubmit={handleDeleteItem}
        submitDisabled={false}
        title="Confirm delete"
        submitTitle="Delete"
        dismissTitle="Cancel"
        submitColor="delete"
      >
        <div className="flex flex-row w-full">
          <div className="flex w-full flex-col p-3">
            <div className="w-full h-40 aspect-square overflow-hidden p-4">
              {selectedItemToDelete && <h2 className="text-lg">Are you sure you want to delete <span className="text-primary-100">{selectedItemToDelete.title}</span> ?</h2>}
              <h4 className="font-light mt-5">This cannot be undone!</h4>
            </div>
          </div>
        </div>
      </Modal>}
      {/* Card Title */}
      <div className="
        p-3 pr-8 
        border-b border-t-secondary-300 
        relative 
        bg-bg-cold-100
        text-accent
        "
      >
        {item.title}
        <a className="absolute top-0 right-6 m-3" href={item.link_urls?.[0]} target="_blank">
          <ItemIcon icon={faArrowUpRightFromSquare} />
        </a>
        {/* <button className="absolute top-0 right-0 m-3 outline-none" onClick={handleEditMenuClick}>
          <ItemIcon icon={faEllipsis} />
        </button> */}
        <MenuDropdown
          containerClassName="absolute top-0 right-0"
          // onClick={handleDropdownClick}
          isOpen={isEditMenuOpen}
          onClose={handleEditMenuClose}
          onOpen={handleEditMenuOpen}
          // selectedOption={activeBrand}
          placement="bottom"
          offset={[-40, 20]}
        >
          {isEditMenuOpen && (
            <ul className="cursor-pointer w-28">
              <li className="hover:bg-bg-cold-200 px-3" onClick={() => handleEditClick(item)}>Edit item</li>
              <li
                onClick={() => promptBeforeDelete(item)}
                className="hover:text-red-600 hover:bg-red-50 text-red-400 px-3">Delete item</li>
            </ul>
          )}
          {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
            Dropdown Button
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu> */}
        </MenuDropdown>
      </div>

      {/* Card Image */}
      <div className="aspect-square max-h-[336px] w-full overflow-hidden object-cover p-6 object-center flex items-center justify-center relative">
        {!imgHasError ?
          <img
            src={item.img_url} onError={() => setImgHasError(true)}
            alt={`${item.title}`} />
          :
          <>
            <img
              src={catLogo}
              alt="not found" />
            <p className="absolute bottom-6 left-1/2 -translate-x-1/2 text-bg-cold-600">
              Oops! Seems like the image URL is broken
            </p>
          </>
        }
      </div>

      {/* Card Actions */}
      <div className="px-3 mt-10 grid grid-cols-12 pb-2">
        <div className="col-span-12 text-primary-200">
          {selectedBrand?.name}
        </div>
        <div className="grid grid-cols-12 col-span-12">
          <ItemIcon icon={likedIcon} onClick={() => handleFavorite(item.id, !item.favorite)} />
          <ItemIcon icon={giftIcon} onClick={() => handleGift(item.id, !item.gift)} />
          <ItemIcon icon={purchasedIcon} onClick={() => handlePurchase(item.id, !item.purchased)} />

          <div className="col-start-10 col-span-3 grid place-items-end text-bg-cold-600">{`${isNaN(item.price) ? 0 : item.price} ${item.currency}`}</div>
        </div>
      </div>

      {/* <div className="p-3 flex h-24 justify-between overflow-hidden">
        <ItemDescription description={item.description} />
      </div> */}

    </div >
  );
};

export default ItemCard;