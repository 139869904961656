import { ChangeEvent, createRef, Fragment, MouseEvent, useEffect, useMemo, useState } from "react";
import TabButton from "../Home/TabButton";
import { useDebounceEffect } from "../Home/useDebounceEffect";
import ItemCardsOverview from "./ItemCardsOverview";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Wishlist from "../Wishlist";
import ItemIcon from "./ItemIcon";
import { faEdit, faInfoCircle, faSquareMinus, faSquarePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "src/components/modal";
import ItemCardInput from "./ItemCardInput";
import { createPopper } from "@popperjs/core";
import Dropdown from "./Dropdown";
import catGif from '../../assets/cat-gif-clipart.gif';
import ItemCardPreview from "./ItemCardPreview";
import ItemCheckbox from "./ItemSwitch";
import { IGetCurrency } from "src/types/currency-types";
// import CurrencyList from 'currency-list';
import { capitalize } from "src/utils/capitalize";
import { withContext } from "src/context";
import { AppContext } from "src";
import { useFirebaseCollection } from "src/firebase/use-firebase-collection";
import { collection, doc, DocumentData, getFirestore, OrderByDirection, updateDoc } from "firebase/firestore";
import BrandItemButtom from "src/components/BrandItemButton";
import { addFirebaseDocument } from "src/firebase/add-firebase-document";
import { Collections, ECategoryTabs } from "src/utils/enums";
import { IBrand, ICategory, ICategoryItem } from "src/utils/interfaces";
import { deleteFirebaseDocumentByID } from "src/firebase/delete-firebase-document-by-id";
import Tooltip from "./Tooltip";
import ItemModal from "../../components/item-modal/ItemModal";

interface ICategoryProps extends Partial<AppContext> {
  activeCategory: ICategory;
  wishlistItems: any[];
  purchasedItems: any[];
  giftItems: any[];
}

const CategoryTabs = (props: ICategoryProps) => {
  const { wishlistItems, purchasedItems, giftItems, activeCategory, setIsModalOpen } = props;

  const [activeTab, setActiveTab] = useState(ECategoryTabs.Wishlist);
  const [shownTab, setShownTab] = useState(ECategoryTabs.Wishlist);
  const [search, setSearch] = useSearchParams();

  const [showAddItemModal, setShowAddItemModal] = useState(false);

  const createSearchParams = (value: number) => {
    const searchParams = new URLSearchParams();
    if (value !== 0) {
      searchParams.set("t", ECategoryTabs[value].toLowerCase());
      setSearch(searchParams);
    } else {
      // First tab doesn't need params
      searchParams.delete('t');
      setSearch(searchParams);
    }
  };

  useEffect(() => {
    // get Active tab from params
    const activeTab = Object.fromEntries(search.entries()).t;

    if (activeTab) {
      // if no active tab param, default to wishlist
      const activeToSet = ECategoryTabs[capitalize(activeTab) as keyof typeof ECategoryTabs];
      const defaultToSet = ECategoryTabs.Wishlist;

      if (activeToSet)
        setActiveTab(activeToSet);
      else
        setActiveTab(defaultToSet);

    } else {
      const defaultToSet = ECategoryTabs.Wishlist;
      setActiveTab(defaultToSet);
    }
  }, [search]);

  const handleTabChange = (_event: MouseEvent, title: number) => {
    // what happens when you switch tabs
    createSearchParams(title);
  };


  useDebounceEffect(() => {
    // Delay the 'show' class for the fade effect
    setShownTab(activeTab);
  }, 100, [activeTab]);

  return (
    <div className="flex flex-col w-full mt-4">
      <ItemModal
        setShow={setShowAddItemModal}
        show={showAddItemModal}
        activeCategoryID={activeCategory.id}
      />

      <ul
        className="nav nav-tabs flex flex-col md:flex-row list-none border-b-0 pl-0 mb-2 relative"
        id="tabs"
        role="tablist">
        <li className="nav-item" role="presentation">
          <TabButton
            Enum={ECategoryTabs}
            index={ECategoryTabs.Wishlist}
            activeTab={activeTab}
            onClick={handleTabChange} />
        </li>
        <li className="nav-item" role="presentation">
          <TabButton
            Enum={ECategoryTabs}
            index={ECategoryTabs.Purchased}
            activeTab={activeTab}
            onClick={handleTabChange} />
        </li>
        <li className="nav-item" role="presentation">
          <TabButton
            Enum={ECategoryTabs}
            index={ECategoryTabs.Gifts}
            activeTab={activeTab}
            onClick={handleTabChange} />
        </li>
        <li className="nav-item flex items-center" role="presentation">
          <FontAwesomeIcon
            onClick={() => {
              setShowAddItemModal(true);
              typeof setIsModalOpen !== 'undefined' && setIsModalOpen(true);
            }}
            icon={faSquarePlus}
            size="2x"
            className={`
              text-accent 
              hover:scale-110 hover:text-secondary
              ease-in duration-100 transition-all
              cursor-pointer active:scale-90
            `} />
          {/* <ItemIcon icon={faSquarePlus}  /> */}
        </li>
      </ul>
      <div className="tab-content" id="tabs-wishlist">
        <div className={`tab-pane fade${activeTab === ECategoryTabs.Wishlist ? ' active' : ' inactive'}${shownTab === ECategoryTabs.Wishlist ? ' show' : ''}`}
          role="tabpanel" aria-labelledby="tabs-wishlist">
          <div className="gap-2 gap-x-4 gap-y-4">
            {/* <span className="text-secondary-100">Wishlist items array</span> */}

            <ItemCardsOverview items={wishlistItems} activeCategory={activeCategory} />
          </div>
        </div>

      </div>
      <div className="tab-content" id="tabs-purchased">
        <div className={`tab-pane fade${activeTab === ECategoryTabs.Purchased ? ' active' : ' inactive'}${shownTab === ECategoryTabs.Purchased ? ' show' : ''}`}
          role="tabpanel" aria-labelledby="tabs-purchased">
          <div className="gap-2 gap-x-4 gap-y-4">
            {/* <span className="text-secondary-100">Purchased items array</span> */}

            <ItemCardsOverview items={purchasedItems} activeCategory={activeCategory} />
          </div>
        </div>

      </div>
      <div className="tab-content" id="tabs-gift">
        <div className={`tab-pane fade${activeTab === ECategoryTabs.Gifts ? ' active' : ' inactive'}${shownTab === ECategoryTabs.Gifts ? ' show' : ''}`}
          role="tabpanel" aria-labelledby="tabs-gift">
          <div className="gap-2 gap-x-4 gap-y-4">
            {/* <span className="text-secondary-100">Gift items array</span> */}

            <ItemCardsOverview items={giftItems} activeCategory={activeCategory} />
          </div>
        </div>

      </div>
    </div>
  );
};

export default withContext(CategoryTabs);