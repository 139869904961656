import { FC, memo, PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {
  imgSrc: string;
  title: string;
}
const AuthContainer: FC<IProps> = ({ imgSrc, title, children }) => {
  return (
    <main className="flex w-screen h-screen flex-wrap">
      <div className="sm:flex hidden sm:w-1/2 xl:w-3/5 w-full h-full relative">
        <div className="w-full h-full bg-opacity-25 bg-bg-cold-600 bg-top absolute"></div>

        <img src={imgSrc} alt="Made by @freestocks on Unsplash" className="w-full object-cover object-left" />
      </div>
      <div className="flex flex-col w-full h-full sm:w-1/2 xl:w-2/5 bg-bg-cold-100 justify-center px-7">
        <h1 className="font-display text-7xl mb-8 w-full text-center tracking-wider">{title}</h1>

        {children}
      </div>
    </main>
  );
};

export default memo(AuthContainer);