import { FC, PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {
  bgSrc?: string;
  className?: string;
  colSpan?: number;
}

const HomeSection: FC<IProps> = ({ children, bgSrc, className = "", colSpan = 1 }) => {
  return (
    <div className={`
        col-span-${colSpan}
        flex flex-col
        relative 
        items-start bg-white h-full md:min-h-[320px] min-h-[200px]
        place-content-start p-4 
        rounded-md shadow-sm ${className}`}>

      {children}
      {bgSrc && <img
        src={bgSrc}
        alt="Gifts"
        className="absolute bottom-1 right-1 w-2/3" />}
    </div>
  );
};

export default HomeSection;