import { useEffect, useState } from "react";
import { AuthError } from "firebase/auth";

const useErrorHook = (err?: AuthError) => {

  const [message, setMessage] = useState('');

  useEffect(() => {
    if (typeof err !== 'undefined')
      switch (err.code) {
        case 'auth/email-already-exists':
          setMessage('The provided email is already in use by an existing user.');
          break;
        case 'auth/id-token-expired':
          setMessage('The session has expired.\r\nPlease log in again.');
          break;
        case 'auth/session-cookie-expired':
          setMessage('The session has expired.\r\nPlease log in again.');
          break;
        case 'auth/id-token-revoked':
          setMessage('The login rights have been revoked.\r\nPlease contact us for more details.');
          break;
        case 'auth/insufficient-permission':
          setMessage('Insufficient permissions.\r\nThe credentials used require additional permissions.');
          break;
        case 'auth/internal-error':
          setMessage('The server encountered an unexpected error while trying to process the request. If the error persists, please contact us.');
          break;
        case 'auth/invalid-argument':
          setMessage('An invalid argument was provided in the authentification process. Please try again.');
          break;
        case 'auth/invalid-credential':
          setMessage('The credentials used to authenticate cannot be used to perform the desired action.');
          break;
        case 'auth/invalid-display-name':
          setMessage('The provided value for the Display Name user property is invalid. It must be a non-empty string');
          break;
        case 'auth/invalid-email':
          setMessage('The provided email is invalid.\r\nPlease enter another email.');
          break;
        case 'auth/invalid-phone-number':
          setMessage('The provided value for the phoneNumber is invalid.');
          break;
        case 'auth/invalid-photo-url':
          setMessage('The provided photo URL is invalid.\r\nIt must be a string URL.');
          break;
        case 'auth/phone-number-already-exists':
          setMessage('The provided phone number is already in use by an existing user.\r\nEach user must have a unique phoneNumber.');
          break;
        case 'auth/user-not-found':
          setMessage('There is no existing user corresponding to the provided email.');
          break;
        case 'auth/weak-password':
          setMessage('The password should have at least 6 characters');
          break;
        case 'auth/wrong-password':
          setMessage('The password provided is incorrect.\r\nPlease try again.');
          break;
        case 'auth/too-many-requests':
          setMessage('Access to this account has been temporarily disabled due to many failed login attempts \r\n You can immediately restore it by resetting your password or you can try again later');
          break;
        case 'auth/email-already-in-use':
          setMessage('E-mail is already in use. \r\nPlease use another e-mail');
          break;

        default:
          setMessage('An error has occurred.\r\nPlease try again');
          break;
      }
  }, [err]);
  return { message };
};

export default useErrorHook;