import { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import ItemCardInput from "../Categories/ItemCardInput";
import homeBg from 'src/assets/home-bg.jpeg';
import { useAuth } from "./hooks";
import { useMidnightContext } from "src/context";
import AuthContainer from "./AuthContainer";
import { EMAIL_REGEX } from "./constants";

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { onSignUp, authUser } = useAuth();
  const { setUserDetails } = useMidnightContext();

  const onRegister = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setUserDetails({ firstName, lastName });
    if (onSignUp)
      onSignUp({
        email,
        password,
        firstName,
        lastName
      });
  };

  useEffect(() => {
    if (email.length)
      setIsEmailValid(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    if (password !== repeatPassword) {
      setPasswordsMatch(false);
    } else setPasswordsMatch(true);
  }, [password, repeatPassword]);

  useEffect(() => {
    setIsFormValid(
      passwordsMatch
      && password.length > 0
      && repeatPassword.length > 0
      && email.length > 0
      && firstName.length > 0
      && lastName.length > 0
      && isEmailValid);
  }, [email, password, repeatPassword, isEmailValid, firstName, lastName, passwordsMatch]);

  if (authUser) return <Navigate to="/" />;

  return (
    <AuthContainer imgSrc={homeBg} title="Create an account">
      <form>
        <ItemCardInput
          value={firstName}
          onInput={(e) => setFirstName(e.target.value)}
          placeholder={"First name"}
        />
        <ItemCardInput
          value={lastName}
          onInput={(e) => setLastName(e.target.value)}
          placeholder={"Last name"}
        />
        <ItemCardInput
          value={email}
          type="email"
          onInput={(e) => setEmail(e.target.value)}
          placeholder={"Email address"}
        />
        <ItemCardInput
          value={password}
          type="password"
          onInput={(e) => setPassword(e.target.value)}
          placeholder={"Password"}
        />
        <ItemCardInput
          value={repeatPassword}
          type="password"
          onInput={(e) => setRepeatPassword(e.target.value)}
          placeholder={"Confirm password"}
        />

        <div className="mt-8 flex flex-col lg:flex-row w-full items-center">
          <button
            disabled={!isFormValid}
            onClick={onRegister}
            type="button"
            className="
                bg-accent-100 hover:bg-accent-50 active::bg-accent-200 focus:bg-accent-200 hover:focus:bg-accent-100 
                disabled:pointer-events-none disabled:bg-bg-light-200 disabled:select-none
                transition-all duration-200 ease-in-out 
                text-white text-lg font-bold
                px-8 py-4 
                rounded-md 
                w-full lg:w-2/6"
          >
            Sign up
          </button>
          <p className="text-base text-center mt-4 lg:ml-8 lg:mt-0">
            Already have an account? {' '}
            <NavLink to="/login" className="
                ml-2 
                text-base font-bold text-accent hover:text-accent-200 
                transition-all duration-200 ease-in-out"
            >
              Login
            </NavLink>
          </p>
        </div>
      </form>
    </AuthContainer>

  );
};

export default SignUp;