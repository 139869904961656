import { useState, createRef, FC, useEffect, useRef, useCallback, ChangeEvent, PropsWithChildren } from "react";
import { createPopper, Placement } from "@popperjs/core";
import { collection, DocumentData, getFirestore } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

import useClickOutside from "src/hooks/useClickOutside";
import { IGetCurrency } from "src/types/currency-types";
import ItemCardInput from "./ItemCardInput";
import { AppContext } from "src";
import { withContext } from "src/context";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import ItemIcon from "./ItemIcon";

// make component more generic
// make a dropdown with external source of list and onCHange + active element

interface IOption {
  [key: string]: any;
}
interface IMenuDropdown extends Partial<AppContext> {
  isOpen: boolean;
  containerClassName?: string;
  //   options: T[];
  //   selectedOption?: T;
  placement?: Placement;
  onClick?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  placeholder?: string;
  firstKey?: string;
  secondKey?: string;
  simple?: boolean;
  searchPlaceholder?: string;
  offset?: number[];
}
const MenuDropdown = (props: PropsWithChildren<IMenuDropdown>) => {

  const {
    isOpen,
    containerClassName,
    onClick,
    onClose,
    onOpen,
    // options,
    // selectedOption,
    placement = "bottom",
    // placeholder = 'Choose an option',
    // firstKey,
    // secondKey,
    // simple = false,
    // searchPlaceholder = "Search",
    // setIsModalOpen,
    offset = [0, 0],
    children
  } = props;

  useEffect(() => {
    console.log('is open', isOpen);

  }, [isOpen]);
  // const inputRef = useRef<HTMLInputElement>(null);

  //   const [shownOptions, setShownOptions] = useState<any[]>([]);
  //   const [searchValue, setSearchValue] = useState('');

  const btnRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const closeModal = useCallback(() => {
    onClose && onClose();
  }, []);

  const openModal = useCallback(() => {
    onOpen && onOpen();
  }, []);

  const openDropdownPopover = useCallback(() => {

    if (btnRef.current && containerRef.current) {

      console.log('seems to be here',);
      createPopper(
        btnRef.current,
        containerRef.current,
        {
          placement, modifiers: [
            {
              name: 'offset',
              options: {
                offset,
              },
            },
          ]
        })
        .update()
        .then(() => {
          openModal();
          console.log('opened modal');

          // inputRef.current?.focus();
        });
    }
  }, [btnRef.current, containerRef.current, placement, offset]);

  //   const handleSelect = () => {
  //     onClick();
  //     closeModal();
  //   };

  const handleButtonClick = useCallback(() => {
    isOpen
      ? closeModal()
      : openDropdownPopover();
  }, [isOpen]);

  //   const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //     setSearchValue(event.target.value);
  //   };


  // Close the dropdown when clicked outside
  useClickOutside(ref, () => closeModal());

  //   useEffect(() => {
  // console.log('options', options.map(option => option.data()));
  // options.map(option => { });

  // const filteredOptions: T[] = options
  //   .filter(option => option[secondKey]?.toLowerCase()
  //     .includes(searchValue.toLowerCase())
  //   );

  // setShownOptions(filteredOptions);
  //   }, [searchValue]);


  return (
    <div ref={ref} className={`${containerClassName ?? ''}`}>
      <div className="relative">

        {/* <button
          className='
            text-white font-bold uppercase text-sm 
            px-6 py-3.5
            rounded shadow hover:shadow-lg outline-none focus:outline-none 
            ease-linear transition-all duration-150 
            bg-secondary-500
            flex w-28 justify-center
            '
          type="button"
          ref={btnRef}
          onClick={handleButtonClick}
        >
          <span className="flex overflow-hidden whitespace-nowrap text-ellipsis">
            {placeholder}
          </span>
        </button> */}
        <button ref={btnRef} className="absolute top-0 right-0 m-3 outline-none" onClick={handleButtonClick}>
          <ItemIcon icon={faEllipsis} />
        </button>

        <div
          ref={containerRef}
          className={`
            overflow-hidden
            bg-bg-cold-50 border border-neutral-200 shadow-sm rounded-md
            ease-in-out transition-all duration-200 
            ${isOpen
              ? ' opacity-100 z-999 '
              : ' opacity-0'}
            `
          }>
          {children}
        </div>
        {/* <ul
          ref={optionsRef}
          className={
            `${isOpen ? "opacity-100 " : "opacity-0 pointer-events-none"} 
            bg-bg-cold-50 
            absolute top-10 left-0 
            text-base py-2 px-2 list-none text-left rounded shadow-lg
            z-50
            max-h-48 overflow-scroll no-scrollbar
            `
          }
          style={{ minWidth: "13rem" }}
        >
        
        </ul> */}
      </div>
    </div>
  );
};

export default withContext(MenuDropdown);