import { createRef } from "react";
import Login from "src/pages/auth/Login";
import { Categories, CategoryDetail } from "src/pages/Categories";
import Collection from "src/pages/Collection";
import Home from "src/pages/Home";
import Profile from "src/pages/Profile";
import Wishlist from "src/pages/Wishlist";

export const routes = [
  {
    path: '/',
    name: 'Home',
    element: <Home />,
    nodeRef: createRef<HTMLDivElement>(),
    show: true
  },
  {
    path: '/wishlist',
    name: 'WishList',
    element: <Wishlist />,
    nodeRef: createRef<HTMLDivElement>(),
    show: true
  },
  {
    path: '/collection',
    name: 'Collection',
    element: <Collection />,
    nodeRef: createRef<HTMLDivElement>(),
    show: true
  },
  {
    path: '/profile',
    name: 'Profile',
    element: <Profile />,
    nodeRef: createRef<HTMLDivElement>(),
    show: true
  },
  {
    path: '/categories',
    name: 'Categories',
    element: <Categories />,
    nodeRef: createRef<HTMLDivElement>(),
    show: true
  },
  {
    path: '/categories/:slug',
    name: 'Categories',
    element: <CategoryDetail />,
    nodeRef: createRef<HTMLDivElement>(),
    show: false
  },
  // {
  //   path: '*',
  //   name: 'Not Found',
  //   element: <NotFound />,
  //   nodeRef: createRef<HTMLDivElement>(),
  // },
];

export const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    element: <Login />,
    nodeRef: createRef<HTMLDivElement>(),
    show: false
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   element: <Home />,
  //   nodeRef: createRef<HTMLDivElement>(),
  //   show: true
  // },
];