import { FC, ReactNode } from "react";
import { PropsWithChildren, useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import CatLoader from "../Categories/CatLoader";
import { AuthContext } from "./AuthContext";
import { useAuth } from "./hooks";



// export const ProtectedRoutes: FC<PropsWithChildren<any>> = () => {
//     const { user } = useAuth();
//     // if (!user) {
//     // user is not authenticated
//     // return <Navigate to="/login" />;
//     // }
//     // return children;
//     return (
//         user ? <Outlet /> : <Navigate to="/login" />
//     );
// };

const ProtectedRoute = (props: PropsWithChildren<any>): JSX.Element => {
    const { children } = props;
    const { authUser, userLoading } = useAuth();
    const { pathname } = useLocation();

    const storedToken = localStorage.getItem('authToken');

    // if (storedToken) return children;
    if (authUser && storedToken && (pathname === '/login' || pathname === '/signup')) {
        // user is authenticated and should not be on the login screen
        return <Navigate to="/" replace />;
    }

    if (!authUser && !storedToken) {
        // user is not authenticated
        return <Navigate to="/login" replace />;
    }

    // set token and dont redirect or  remember paths
    if (!authUser && storedToken && userLoading) {
        // if there's a token show a loader while loading authUser
        return <CatLoader />;
    }

    return children;
};

export default ProtectedRoute;