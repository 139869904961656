import { useState } from "react";
import AuthContainer from "./AuthContainer";
import homeBg from 'src/assets/home-bg.jpeg';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  return (
    <AuthContainer imgSrc={homeBg} title="Reset password">
      <form >
      </form>
    </AuthContainer>
  );
};

export default ForgotPassword;