import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

interface IProps {
  onClick: () => void;
}
const AddNewCategoryButton: FC<IProps> = ({ onClick }) => {
  return (
    <div className="col-span-1 text-center group cursor-pointer relative" role="button" onClick={onClick}>
      {/* <p className="font-display text-4xl my-4 group-hover:text-secondary-500 min-h-[40px]"></p> */}

      <div className={`
        rounded-full overflow-hidden
        transition-all duration-300 ease-in-out
        shadow-spotlight hover:shadow-spotlight-hover
        hover:scale-[0.99]
        relative
        flex w-full aspect-square
        bg-gradient-to-br
        from-bg-cold-500 hover:from-bg-cold-400 hover:to-bg-cold-800
        to-bg-cold-800
        justify-center items-center
      `}>
        <FontAwesomeIcon icon={faPlus} className="h-16 text-white" />
      </div>
    </div>
  );
};

export default AddNewCategoryButton;