import { collection, DocumentData, getFirestore, addDoc } from "firebase/firestore";
import { db } from "src";
// import { db } from "./firebase-config";

export const addFirebaseDocument = async <T extends DocumentData>(collectionName: string, doc: T) => {

    // const db = getFirestore();
    const ref = collection(db, collectionName);
    try {
        const res = await addDoc(ref, doc);
        return res;
    } catch (error) {
        console.log("Error adding document to collection ", collectionName);
    }

};