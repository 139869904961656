import { FC, useEffect } from 'react';
import {
  NavLink,
  useLocation,
  useOutlet,
} from 'react-router-dom';
import './App.scss';
import { CSSTransition, SwitchTransition, } from 'react-transition-group';
import { useMidnightContext, withContext } from './context';
import { faFacebookSquare, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHouse, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from 'src';
import CatLoader from './pages/Categories/CatLoader';
import { routes } from './utils/routes';
import { useAuth } from './pages/auth/hooks';
import Navigation from './components/nav';

interface IApp extends Partial<AppContext> {

}

export const socialIcons = [
  { icon: faHouse },
  { icon: faFacebookSquare },
  { icon: faTwitter },
  { icon: faInstagram },
  { icon: faYoutube },
  { icon: faSearch },
];

const App: FC<IApp> = ({ isModalOpen, isLoggedIn, isAuthLoading }) => {

  // const { onLogout, authUser } = useAuth();

  // const {isLoggedIn} = useMidnightContext()
  const location = useLocation();
  const currentOutlet = useOutlet();

  // const handleLogout = () => {
  //   onLogout && onLogout();
  // };

  const { nodeRef } =
    routes?.find((route) => route.path === location.pathname) ?? {};

  if (isAuthLoading) {
    return <CatLoader />;
  }

  return (
    <>
      {/* <div className="flex flex-col w-48 h-full max-h-screen shadow-md bg-white fixed">
        <div className="pt-4 pb-2 px-6 flex">
          <div className='flex w-full items-center justify-center font-display text-3xl py-6 font-bold'>My Vanity</div>
        </div>
        <hr className="my-2" />
        <div className="flex flex-col h-full justify-between">

          <ul className="nav flex flex-col last:justify-items-end">
            {routes?.length && routes.map((route) => (
              route.show &&
              <li key={route.path}>
                <NavLink
                  className="mx-0 flex items-center text-md py-4 px-6 h-12 overflow-hidden text-bg-cold-600 text-ellipsis whitespace-nowrap hover:text-bg-cold-800 hover:bg-bg-cold-200 transition duration-300 ease-in-out justify-end"
                  to={route.path}
                  end
                >
                  {route.name}
                </NavLink>
              </li>
            ))}

          </ul>
          <div className='flex flex-col'>
            <NavLink
              className="mx-0 flex items-center text-md py-4 px-6 h-12 overflow-hidden text-bg-cold-600 text-ellipsis whitespace-nowrap hover:text-bg-cold-800 hover:bg-bg-cold-200 transition duration-300 ease-in-out justify-end"
              to={'/logout'}
              onClick={handleLogout}
              end
            >
              Logout
            </NavLink>
            <div className="text-center w-full">
              <hr className="m-0" />
              <p className="py-2 text-sm font-light text-primary-200">powered by
                <span className='font-bold text-primary-500'> mindesign.ro</span>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <Navigation>


        <div className={`min-h-full${isModalOpen ? ' overflow-hidden' : ''}`}>
          <SwitchTransition>
            <CSSTransition
              key={location.pathname}
              nodeRef={nodeRef}
              timeout={300}
              classNames="page"
              unmountOnExit
            >
              {() => (
                <div ref={nodeRef} className="page">
                  <main className="mx-auto">
                    {currentOutlet}
                  </main>
                </div>
              )}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </Navigation>

    </>
  );
};

export default withContext(App);
