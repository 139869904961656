import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

interface IItemIconProps {
  onClick?: () => void;
  icon: IconDefinition | IconProp;
  className?: string;
}

const ItemIcon: FC<IItemIconProps> = ({ onClick, icon, className = '' }) => {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      icon={icon}
      className={`
        text-accent 
        hover:scale-110 hover:text-secondary
        ease-in duration-100 transition-all
        cursor-pointer 
        ${className}
        `} />
  );
};

export default ItemIcon;