export const COLORS = {
    primary: {
        DEFAULT: '#502D55',
        50: '#b179b9', //
        100: '#a25fab', //
        200: '#8b4d93', //
        300: '#723f78', //
        400: '#502D55', //
        500: '#4c2a50', //
        600: '#3f2343', //
        700: '#321c35', //
        800: '#261528', //
        900: '#190e1b',
    },
    accent: {
        DEFAULT: '#90326d',
        50: '#da95c1',
        100: '#cf77af',
        200: '#c5599d',//
        300: '#b5408a',//
        400: '#90326d',//
        500: '#792a5c',
        600: '#582045',
        700: '#4b1b3a',
        800: '#3c152e',
        900: '#1e0b17',
    },
    secondary: {
        DEFAULT: '#8e4b71',
        50: '#d5afc4',
        100: '#c794b1',
        200: '#b9799d',
        300: '#ab5f8a',
        400: '#8e4b71', //
        500: '#783f60',
        600: '#5e314a',
        700: '#502a40',
        800: '#351c2a',
        900: '#281520',
        // 400: ////////////////////// '#e84b71', //
    },
    'bg-light': {
        DEFAULT: '#c08fb3',
        50: '#f0e5ed', // 
        100: '#e2cbdc', // 
        200: '#dabed3', // 
        300: '#cca4c1', //
        400: '#c08fb3', //
        500: '#b67ca6', //
        600: '#a76295', //
        700: '#90517f', // 
        800: '#764268', //
        900: '#5b3351',
    },
    'bg-dark': {
        DEFAULT: '#24001d',
        50: '#b80093', // 
        100: '#8f0072', // 
        200: '#660052', // 
        300: '#3d0031', //
        400: '#24001d', //
        500: '#140010', //
        600: '#220017', //
        700: '#190011', // 
        800: '#11000b', //
        900: '#080005',
    },
    'bg-cold': {
        DEFAULT: '#d2d0e8',
        50: '#ffffff', // 
        100: '#f5f5fa', // 
        200: '#f2f1f8', // 
        300: '#e5e4f2', //
        400: '#d2d0e8', //
        500: '#bebbdd', //
        600: '#a39fd0', //
        700: '#8984c2', // 
        800: '#6f69b5', //
        900: '#504a96',
    },
    'bg-warm': {
        DEFAULT: '#e6e0da',
        50: '#ffffff', // 
        100: '#f3f2f1', // 
        200: '#f7f5f3', // 
        300: '#eeebe7', //
        400: '#e6e0da', //
        500: '#d6ccc2', //
        600: '#c5b8aa', //
        700: '#b5a392', // 
        800: '#a48f79', //
        900: '#927a63',
    },
    white: "#fbfbfb",
    white50: "#fbfbfb80",
    white30: "#fbfbfb4d",
    white15: "#fbfbfb26",
    // muted: colors.slate,
};


export const HEX_TRANSPARENCY = {
    "100": "FF",
    "99": "FC",
    "98": "FA",
    "97": "F7",
    "96": "F5",
    "95": "F2",
    "94": "F0",
    "93": "ED",
    "92": "EB",
    "91": "E8",
    "90": "E6",
    "89": "E3",
    "88": "E0",
    "87": "DE",
    "86": "DB",
    "85": "D9",
    "84": "D6",
    "83": "D4",
    "82": "D1",
    "81": "CF",
    "80": "CC",
    "79": "C9",
    "78": "C7",
    "77": "C4",
    "76": "C2",
    "75": "BF",
    "74": "BD",
    "73": "BA",
    "72": "B8",
    "71": "B5",
    "70": "B3",
    "69": "B0",
    "68": "AD",
    "67": "AB",
    "66": "A8",
    "65": "A6",
    "64": "A3",
    "63": "A1",
    "62": "9E",
    "61": "9C",
    "60": "99",
    "59": "96",
    "58": "94",
    "57": "91",
    "56": "8F",
    "55": "8C",
    "54": "8A",
    "53": "87",
    "52": "85",
    "51": "82",
    "50": "80",
    "49": "7D",
    "48": "7A",
    "47": "78",
    "46": "75",
    "45": "73",
    "44": "70",
    "43": "6E",
    "42": "6B",
    "41": "69",
    "40": "66",
    "39": "63",
    "38": "61",
    "37": "5E",
    "36": "5C",
    "35": "59",
    "34": "57",
    "33": "54",
    "32": "52",
    "31": "4F",
    "30": "4D",
    "29": "4A",
    "28": "47",
    "27": "45",
    "26": "42",
    "25": "40",
    "24": "3D",
    "23": "3B",
    "22": "38",
    "21": "36",
    "20": "33",
    "19": "30",
    "18": "2E",
    "17": "2B",
    "16": "29",
    "15": "26",
    "14": "24",
    "13": "21",
    "12": "1F",
    "11": "1C",
    "10": "1A",
    "9": "17",
    "8": "14",
    "7": "12",
    "6": "0F",
    "5": "0D",
    "4": "0A",
    "3": "08",
    "2": "05",
    "1": "03",
    "0": "00",
};