import { collection, doc, getDocs, getFirestore, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { FC } from "react";
import { db } from "src";
// import { db } from "src/firebase/firebase-config";
import { Collections } from "src/utils/enums";
import { ICategory, ICategoryItem } from "src/utils/interfaces";
import ItemCard from "./itemCard";

interface IItemCardsOverviewProps {
  items: any[];
  activeCategory: ICategory;
}
const ItemCardsOverview: FC<IItemCardsOverviewProps> = ({ items, activeCategory }) => {
  // const db = getFirestore();

  const updateAttr = (id: string, key: keyof ICategoryItem, value: boolean) => {
    try {
      const ref = doc(db, Collections.items, id);
      console.log('ref ', ref);

      updateDoc(ref, { [key]: value });
    } catch (error) {
      console.log('Error updating favorite', error);
    }
  };
  const handleFavorite = (id: string, favorite: boolean) => {
    updateAttr(id, "favorite", favorite);
  };
  const handleGift = (id: string, gift: boolean) => {
    updateAttr(id, "gift", gift);
  };
  const handlePurchase = (id: string, purchased: boolean) => {
    updateAttr(id, "purchased", purchased);
  };

  return (
    <div className="grid grid-cols-12 gap-5 mt-2">
      {items.map((item, index) => (
        <ItemCard
          key={index}
          item={item}
          handleFavorite={handleFavorite}
          handleGift={handleGift}
          handlePurchase={handlePurchase}
          activeCategory={activeCategory}
        />
      ))}
    </div>
  );
};

export default ItemCardsOverview;