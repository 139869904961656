import { FC } from 'react';
import loadingCat from 'src/assets/loadcat.gif';
interface IProps {
  showText?: boolean;
  className?: string;
}
const CatLoader: FC<IProps> = ({ showText = true, className = "flex flex-col items-center justify-center h-screen" }) => {
  return (
    <div className={className}>
      <img src={loadingCat} alt="Meows loading..." />
      {showText && <h1 className="text-xl mt-3">Loading...</h1>}
    </div>
  );
};

export default CatLoader;