import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

interface IProps {
  title: string;
  icon: IconProp;
  subheading?: string;
}

const Heading: FC<IProps> = ({ title, icon, subheading }) => {
  return (
    <>
      <FontAwesomeIcon size="2x" icon={icon} className="text-bg-cold-500" />
      <h2 className="text-3xl text-primary-200 z-50 font-semibold tracking-widest mt-2">
        {title}
      </h2>
      {subheading && <span className="text-bg-light-300">{subheading}</span>}
    </>
  );
};

export default Heading;