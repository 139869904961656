import { ChangeEvent, FC } from "react";

interface IInputFile {
  label?: string;
  id: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  accept: HTMLInputElement['accept'];
  type: HTMLInputElement['type'];
  className?: string;
}

const InputFile: FC<IInputFile> = ({ label, id, onChange, accept, type, className = '' }) => {

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('ere ');

    onChange(event);
  };
  return (
    <div className={className}>
      {label && <label htmlFor={id} className="form-label inline-block mb-2 text-primary">{label}</label>}
      <input
        accept={accept}
        className="
          w-full 
          text-base font-normal text-end justify-end items-end flex
          text-primary
          bg-clip-padding
          rounded-md
          transition
          ease-in-out
          m-0
          focus:text-primary-500 focus:border-secondary focus:outline-none"
        type={type}
        id={id}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default InputFile;