import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  collection,
  query,
  where,
  DocumentData,
  addDoc,
  QuerySnapshot,
  QueryDocumentSnapshot,
  onSnapshot,
  CollectionReference
} from 'firebase/firestore';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';
import {
  faChartColumn,
  faCircleCheck as faCircleCheckFilled,
  faGift,
  faHeart,
  faMoneyBill
} from '@fortawesome/free-solid-svg-icons';
import Modal from "src/components/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './CanvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import TabButton from "./TabButton";
import InputFile from "./InputFile";
import { getDownloadURL, getStorage, ref as storeRef, uploadBytesResumable, UploadMetadata } from "firebase/storage";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import giftIcon from 'src/assets/undraw_happy_birthday_re_c16u.svg';
import shoppingIcon from 'src/assets/undraw_gone_shopping_re_2lau.svg';
import wishlistIcon from 'src/assets/undraw_wishlist_re_m7tv.svg';
import { ICategory, ICategoryItem, ICurrencyItem } from "src/utils/interfaces";
import { Collections } from "src/utils/enums";
import {
  Chart as ChartJS,
  CategoryScale,
  ChartData,
  ChartOptions,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartEvent,
  LegendElement,
  LegendItem,
  ArcElement,
  Plugin
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

import { COLORS, HEX_TRANSPARENCY } from "src/utils/constants";
import { db } from "src";
import { useAuth } from "../auth/hooks";
import { toast } from "react-toastify";
import Heading from "src/components/heading";
import { NavLink } from "react-router-dom";
import HomeSection from "./HomeSection";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);



interface IProps { }

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.


// interface ImageData extends DocumentData {
//   name: string;
//   url: string;
// }
interface ICountedCurrency {
  iso: string;
  count: number;
}
const Home: FC<IProps> = () => {

  // const [showModal, setShowModal] = useState(false);

  // const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  // const [showEditCategoryNameModal, setShowEditCategoryNameModal] = useState(false);
  // const [_categoryToAdd, setCategoryToAdd] = useState('');
  const { authUser } = useAuth();

  // const categoryChange = useCallback(
  //   (ev: ChangeEvent<HTMLInputElement>) =>
  //     setCategoryToAdd(ev.target.value)
  //   ,
  //   [],
  // );

  // const ref = useMemo(() => query(
  //   collection(db, Collections.storage),
  //   // where("name", "!=", "default.jpg"),
  //   where("uid", "==", authUser?.uid ?? ''),
  // ), [authUser]);

  // const defaultRef = query(
  //   collection(db, Collections.storage),
  //   where("name", "==", "default.jpg"),
  // );

  // const [collectionValues] = useCollection(ref,
  //   { snapshotListenOptions: { includeMetadataChanges: true } },
  // );


  // const [defaultPhoto] = useCollectionOnce(defaultRef);

  // const [selectedImage, setSelectedImage] = useState<ImageData | undefined>(undefined);


  // const [imgSrc, setImgSrc] = useState<string | undefined>(undefined);
  // const [createdImgUrl, setCreatedImgUrl] = useState<string | undefined>('');
  // const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  // const imgRef = useRef<HTMLImageElement>(null);
  // const categoryInputRef = useRef<HTMLInputElement>(null);
  // const [crop, setCrop] = useState<Crop>();
  // const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  // const [fileName, setFileName] = useState<string | null>(null);
  // const [scale, setScale] = useState(1);
  // const [rotate, setRotate] = useState(0);
  // const [aspect, setAspect] = useState<number | undefined>(1);
  // const [scaleValue, setScaleValue] = useState(1);
  // const [activeTab, setActiveTab] = useState(ETabsNames.Browse);

  // Need to separate 'active' and 'show' classes for the fade effect to work
  // const [shownTab, setShownTab] = useState(ETabsNames.Browse);
  // const [categoryName, setCategoryName] = useState<string>('');


  const [allItems, setAllItems] = useState<QueryDocumentSnapshot<ICategoryItem>[]>([]);
  const [allCategories, setAllCategories] = useState<QueryDocumentSnapshot<ICategory>[]>([]);
  const [wishlistCount, setWishlistCount] = useState<number>(0);
  const [purchasedCount, setPurchasedCount] = useState<number>(0);
  // const [purchasedItems, setPurchasedItems] = useState<ICategoryItem[]>([]);
  const [giftCount, setGiftCount] = useState<number>(0);
  const [wishlistItems, setWishlistItems] = useState<ICategoryItem[]>([]);
  const [purchasedItems, setPurchasedItems] = useState<ICategoryItem[]>([]);
  const [giftItems, setGiftItems] = useState<ICategoryItem[]>([]);
  const [allCurrencies, setAllCurrencies] = useState<QueryDocumentSnapshot<ICurrencyItem>[]>([]);
  const [countedCurrencies, setCountedCurrencies] = useState<ICountedCurrency[]>([]);

  const plugins: Plugin<"doughnut", Object>[] = [{ id: 'Currencies' }];

  const doughnutData: ChartData<"doughnut", number[], string> = useMemo(() => {
    const labels = countedCurrencies.map(item => item.iso);
    const dataValues = countedCurrencies.map(item => item.count);
    return {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor: [
            `${COLORS.primary[50]}${HEX_TRANSPARENCY[80]}`,
            `${COLORS["bg-cold"][600]}${HEX_TRANSPARENCY[80]}`,
            `${COLORS.accent[300]}${HEX_TRANSPARENCY[80]}`,
            `${COLORS["bg-dark"][100]}${HEX_TRANSPARENCY[80]}`,
            `#522988${HEX_TRANSPARENCY[80]}`,
            `#7B53AE${HEX_TRANSPARENCY[80]}`,
          ],
          borderColor: [
            `${COLORS.primary[50]}`,
            `${COLORS["bg-cold"][600]}`,
            `${COLORS.accent[300]}`,
            `${COLORS["bg-dark"][100]}`,
            `#522988${HEX_TRANSPARENCY[80]}`,
            `#7B53AE${HEX_TRANSPARENCY[80]}`,
          ],
          borderWidth: 1,
          hoverOffset: 25,
          borderAlign: 'center',
          borderJoinStyle: 'round',
        }
      ]
    };
  }, [countedCurrencies]);

  const handleAllItemsSnapshot = useCallback((response: QuerySnapshot<ICategoryItem>) => {
    // Find category name
    console.log('wishlist items', response.docs.length);

    setAllItems(response.docs);
  }, []);
  const handleAllCategoriesSnapshot = useCallback((response: QuerySnapshot<ICategory>) => {
    // Find category name
    console.log('category items', response.docs.length);

    setAllCategories(response.docs);
  }, []);
  const handleAllCurrenciesSnapshot = useCallback((response: QuerySnapshot<ICurrencyItem>) => {
    // Find category name
    console.log('currency items', response.docs.length);

    setAllCurrencies(response.docs);
  }, []);

  useEffect(() => {
    const allItems = query(
      collection(db, Collections.items) as CollectionReference<ICategoryItem>,
      where("uid", "==", authUser?.uid ?? ''),
    );

    const unsub = onSnapshot(allItems, handleAllItemsSnapshot);
    return unsub;
  }, [authUser, handleAllItemsSnapshot]);

  useEffect(() => {
    const allCategories = query(
      collection(db, Collections.categories) as CollectionReference<ICategory>,
      where("uid", "==", authUser?.uid ?? ''),
    );
    const unsub = onSnapshot(allCategories, handleAllCategoriesSnapshot);
    return unsub;
  }, [authUser, handleAllCategoriesSnapshot]);

  useEffect(() => {
    const allCurrencies = collection(db, Collections.currencies) as CollectionReference<ICurrencyItem>;
    const unsub = onSnapshot(allCurrencies, handleAllCurrenciesSnapshot);
    return unsub;
  }, [handleAllCurrenciesSnapshot]);

  useEffect(() => {
    if (allItems.length) {
      const purchasedList: ICategoryItem[] = [];
      const wishlistList: ICategoryItem[] = [];
      const giftList: ICategoryItem[] = [];

      allItems.forEach(item => {
        const categoryItem = item.data();
        const { purchased, gift } = categoryItem;

        if (purchased === false && gift === false)
          // Set wishlist list
          wishlistList.push(categoryItem);

        else if (purchased)
          // If product already purchased
          // Set purchased list
          purchasedList.push(categoryItem);
        else if (gift)
          // If product is set as gift and not purchased
          // Set gift list
          giftList.push(categoryItem);


        setPurchasedItems(purchasedList);
        setPurchasedCount(purchasedList.length);

        setWishlistItems(wishlistList);
        setWishlistCount(wishlistList.length);

        setGiftItems(giftList);
        setGiftCount(giftList.length);
      });
    }


  }, [allItems]);

  useEffect(() => {
    const initialArr: ICountedCurrency[] = [];

    const countedCurrencies = allCurrencies.reduce((accumulator, currency) => {
      const { iso } = currency.data();
      // get the iso
      // filter purchased that have current curerncy
      // make a count from purchased items with current iso
      const filtered = purchasedItems.filter(item => item.currency === iso);
      const sum = filtered.reduce((acc, ite) => acc + ite.price, 0);

      return [
        ...accumulator,
        {
          iso,
          count: sum
        }];
    }, initialArr);

    setCountedCurrencies([...new Set(countedCurrencies)]);
  }, [allCurrencies, purchasedItems]);

  const dataLabels = useMemo(() => {
    if (allCategories && allCategories.length) {
      const categories = allCategories.map(category => ({
        name: category.data().name,
        id: category.id
      }));
      return categories;
    }
    return [];
  }, [allCategories]);

  const labels = useMemo(() => {
    return dataLabels.map(label => label.name);
  }, [dataLabels]);

  const data: ChartData<"bar"> = useMemo(() => {
    const mappedAll = allItems.map(item => ({ ...item.data(), id: item.id }));
    const wishItems = mappedAll.filter(item => (!item.purchased && !item.gift));
    const purchased = mappedAll.filter(item => item.purchased);
    const gifts = mappedAll.filter(item => item.gift);

    return {
      labels,
      datasets: [
        {
          label: 'Wishlist items',
          data: dataLabels.map((label) => {
            const found = wishItems.filter(item => item.cat_id === label.id);
            if (found && found.length) return found.length;
            else return 0;
          }),
          backgroundColor: `${COLORS.accent[200]}`, //${HEX_TRANSPARENCY[50]}`,
          borderRadius: 8,
          borderColor: `${COLORS.accent[200]}${HEX_TRANSPARENCY[80]}`,
          borderWidth: 1,
        },
        {
          label: 'Purchased items',
          data: dataLabels.map((label) => {
            const found = purchased.filter(item => item.cat_id === label.id);
            if (found && found.length) return found.length;
            else return 0;
          }),
          backgroundColor: `${COLORS["bg-cold"][600]}`,//${HEX_TRANSPARENCY[50]}`,
          borderRadius: 8,
          borderColor: `${COLORS["bg-cold"][600]}${HEX_TRANSPARENCY[80]}`,
          borderWidth: 1,

        },
        {
          label: 'Gifted items',
          data: dataLabels.map((label) => {
            const found = gifts.filter(item => item.cat_id === label.id);
            if (found && found.length) return found.length;
            else return 0;
          }),
          backgroundColor: `${COLORS.primary[200]}`,//${HEX_TRANSPARENCY[50]}`,
          borderRadius: 8,
          borderColor: `${COLORS.primary[200]}${HEX_TRANSPARENCY[80]}`,
          borderWidth: 1
        },
      ],
    };
  }, [labels, dataLabels, allItems]);


  // Append '4d' to the colors (alpha channel), except for the hovered index
  const handleHover = (_evt: ChartEvent, item: LegendItem, legend: LegendElement<"bar">) => {
    legend.chart.data.datasets.forEach((dataset, index, datasets) => {
      datasets[index].backgroundColor = dataset.label === item.text ||
        (typeof dataset.backgroundColor === 'string' && dataset.backgroundColor.length === 9)
        ? dataset.backgroundColor
        : dataset.backgroundColor + HEX_TRANSPARENCY[25];
    });
    legend.chart.update();
  };
  // Removes the alpha channel from background colors
  const handleLeave = (_evt: ChartEvent, _item: LegendItem, legend: LegendElement<"bar">) => {
    //   (legend?.chart?.data?.datasets?.[0]?.backgroundColor as any).forEach((color: any, index: number, colors: Color[]) => {
    legend.chart.data.datasets.forEach((dataset, index, datasets) => {
      datasets[index].backgroundColor =
        (typeof dataset.backgroundColor === 'string' && dataset.backgroundColor.length === 9)
          ? dataset.backgroundColor.slice(0, -2)
          : dataset.backgroundColor;
    });
    legend.chart.update();
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      y: {
        grid: {
          color: `${COLORS["bg-cold"][300]}${HEX_TRANSPARENCY[70]}`,
          // drawOnChartArea: false,
          lineWidth(ctx, _options) {
            return ctx.index % 2 === 0 ? 1 : 0;
          },
        },
        ticks: {
          callback: val => (val as number) % 1 === 0 ? val : '',
          color: COLORS.accent[100],
          font: {
            family: "'Josefin Sans', 'Arial', sans-serif",
            weight: "700",
            size: 14
          }
        },
        type: 'linear'
      },
      x: {
        grid: {
          color: `${COLORS["bg-cold"][300]}${HEX_TRANSPARENCY[70]}`,
        },
        ticks: {
          color: COLORS.accent[100],
          font: {
            family: "'Josefin Sans', 'Arial', sans-serif",
            weight: "700"
          }
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          // callback: function (val, index) {
          //   // Hide every 2nd tick label
          //   return index % 2 === 0 ? val : '';
          // },
        },

      },

    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        title: {
          font: {
            family: "'Josefin Sans', 'Arial', sans-serif",
            weight: "700"
          }
        },
        labels: {
          font: {
            family: "'Josefin Sans', 'Arial', sans-serif"
          },
          padding: 20,
          boxWidth: 18,
          boxHeight: 18,
          useBorderRadius: true,
          borderRadius: 4
        },
        onHover: handleHover,
        onLeave: handleLeave,
      },
      title: {
        display: false,

      },
      tooltip: {
        backgroundColor: `${COLORS["bg-light"][600]}${HEX_TRANSPARENCY[90]}`,
        caretPadding: 20,
        cornerRadius: 7,
        padding: 15,
        titleFont: {
          family: "'Josefin Sans', 'Arial', sans-serif",
          size: 14
        },
        bodyFont: {
          family: "'Josefin Sans', 'Arial', sans-serif",
          size: 16
        },
        bodyAlign: 'left',
        boxPadding: 6,
        // yAlign: 'center',

        borderColor: COLORS.white,
        borderWidth: 1,
      },

    },

    // options.plugins.tooltip
  };

  // const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {

  //   if (e.target.files && e.target.files.length > 0) {
  //     setCrop(undefined); // Makes crop preview update between images.
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () =>
  //       setImgSrc(reader?.result?.toString() || ''),
  //     );
  //     reader.readAsDataURL(e.target.files[0]);
  //     const url = URL.createObjectURL(e.target.files[0]);
  //     setCreatedImgUrl(url);
  //     setFileName(e.target.files[0].name);
  //     console.log('name', e.target.files[0].name);
  //   }
  // };

  // const resetUploadFile = () => {
  //   setCreatedImgUrl('');
  //   setFileName(null);
  //   setScaleValue(1);
  //   setCompletedCrop(undefined);
  //   setCrop(undefined);
  //   setImgSrc(undefined);
  //   // setSelectedImage(undefined)
  // };

  // const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
  //   const { width, height } = e.currentTarget;
  //   setCrop(centerAspectCrop(width, height, 1));
  // };

  // useDebounceEffect(
  //   async () => {
  //     if (
  //       completedCrop?.width &&
  //       completedCrop?.height &&
  //       imgRef.current &&
  //       previewCanvasRef.current
  //     ) {
  //       // We use canvasPreview as it's much faster than imgPreview.
  //       canvasPreview(
  //         imgRef.current,
  //         previewCanvasRef.current,
  //         completedCrop,
  //         scaleValue,
  //         // rotate,
  //       );
  //     }
  //   },
  //   100,
  //   [completedCrop, scaleValue],
  // );

  // function handleToggleAspectClick() {
  //   if (aspect) {
  //     setAspect(undefined);
  //   } else if (imgRef.current) {
  //     const { width, height } = imgRef.current;
  //     setAspect(16 / 9);
  //     setCrop(centerAspectCrop(width, height, 16 / 9));
  //   }
  // }
  // const handleTabChange = (_event: MouseEvent, title: number) => {
  //   setActiveTab(title);
  // };

  // const handleImageSelect = (data: ImageData) => {
  //   // setCompletedCrop(undefined);
  //   resetUploadFile();
  //   setSelectedImage(data);
  // };


  // const handleOnSubmit = async () => {
  //   if (selectedImage) {
  //     // save an already uploaded image
  //     try {
  //       const docRef = await addDoc(collection(db, Collections.categories), {
  //         img_url: selectedImage.url,
  //         name: categoryName,
  //         uid: authUser?.uid,
  //         slug: getSlug(categoryName)
  //       });
  //       console.log("Document written with ID: ", docRef.id);
  //       closeModal();
  //     } catch (e) {
  //       console.error("Error adding document: ", e);
  //     }
  //   }
  //   else {
  //     // display an error or disable the button
  //     console.log('error');
  //   }
  // };
  // const [progress, setProgress] = useState<number | undefined>(undefined);

  // const uploadImage = () => {
  //   if (completedCrop && fileName && authUser) {
  //     // Create the file metadata
  //     /** @type {UploadMetadata} */
  //     const metadata: UploadMetadata = {
  //       contentType: 'image/jpeg',
  //     };

  //     const storage = getStorage();
  //     const ref = storeRef(storage, `${Collections.categories}/${authUser.uid}/${fileName}-${new Date().toISOString()}`);

  //     previewCanvasRef.current?.toBlob((blob) => {
  //       if (blob) {
  //         const uploadTask = uploadBytesResumable(ref, blob, metadata);

  //         uploadTask.on('state_changed',
  //           (snapshot) => {
  //             // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //             console.log('Upload is ' + progress + '% done');
  //             setProgress(progress);
  //             switch (snapshot.state) {
  //               case 'paused':
  //                 console.log('Upload is paused');
  //                 toast.info('Upload is paused');
  //                 break;
  //               case 'running':
  //                 console.log('Upload is running');
  //                 break;
  //             }
  //           },
  //           (error) => {
  //             // A full list of error codes is available at
  //             // https://firebase.google.com/docs/storage/web/handle-errors
  //             switch (error.code) {
  //               case 'storage/unauthorized':
  //                 // User doesn't have permission to access the object
  //                 toast.error("You do not have permission to access storage");
  //                 break;
  //               case 'storage/canceled':
  //                 // User canceled the upload
  //                 toast.error("Upload canceled by user");
  //                 break;

  //               case 'storage/unknown':
  //                 // Unknown error occurred, inspect error.serverResponse
  //                 toast.error("An unknown error has occured");
  //                 break;
  //             }
  //           },
  //           () => {
  //             // Upload completed successfully, now we can get the download URL
  //             getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
  //               console.log('File available at', downloadURL);

  //               // set firestore database here with category name and downloadURL
  //               try {
  //                 await addDoc(collection(db, Collections.storage), {
  //                   name: fileName,
  //                   uid: authUser?.uid,
  //                   url: downloadURL
  //                 });
  //               } catch (error) {

  //               }
  //             });
  //           }
  //         );
  //         // uploadBytes(ref, file).then((snapshot) => {
  //         //   console.log('Uploaded a blob or file!');
  //         // });
  //       }
  //     }, "image/png", 1);
  //   }
  // };

  // const closeModal = () => {
  //   setShowModal(false);
  //   resetUploadFile();
  //   setSelectedImage(undefined);
  //   setCategoryName('');
  // };

  // useDebounceEffect(() => {
  //   // Delay the 'show' class for the fade effect
  //   setShownTab(activeTab);
  // }, 100, [activeTab]);

  // const ticks = useMemo(() => {
  //   let arr = [];
  //   for (let i = 0; i <= 2.1; i += 0.5) {
  //     arr.push(parseFloat(i.toFixed(1)));
  //   }
  //   return arr;
  // }, []);

  // const handleCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value.length === 1)
  //     setCategoryName(event.target.value.toUpperCase());
  //   else setCategoryName(event.target.value);
  // };
  // useEffect(() => { renderElemen(); }, []);

  // const handleOnOpen = () => {
  //   setShowModal(true);
  //   setTimeout(() => {
  //     if (categoryInputRef.current) {
  //       categoryInputRef.current.focus();
  //     }
  //   }, 200);
  // };

  // const [isEditingCategories, setIsEditingCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<QueryDocumentSnapshot<DocumentData> | undefined>(undefined);
  // const handleDeleteCategory = async () => {

  const handleUpdateCategoryName = () => {

  };

  const [editedCategoryName, setEditedCategoryName] = useState(selectedCategory?.data().name);

  const doughnutOptions: ChartOptions<"doughnut"> = {
    radius: '90%',
    cutout: '60%',
    color: COLORS.accent[100],
    font: {
      family: "'Josefin Sans', 'Arial', sans-serif",
      weight: "700",
      size: 14
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: COLORS.accent[100],
          font: {
            family: "'Josefin Sans', 'Arial', sans-serif",
            weight: "700",
            size: 11,
          },
          padding: 10,
          boxWidth: 18,
          boxHeight: 18,
          useBorderRadius: true,
          borderRadius: 10,
        },
        position: 'bottom',
      },
      tooltip: {
        backgroundColor: `${COLORS["bg-light"][600]}${HEX_TRANSPARENCY[90]}`,
        caretPadding: 20,
        cornerRadius: 7,
        padding: 15,
        titleFont: {
          family: "'Josefin Sans', 'Arial', sans-serif",
          size: 14
        },
        bodyFont: {
          family: "'Josefin Sans', 'Arial', sans-serif",
          size: 16
        },
        bodyAlign: 'left',
        boxPadding: 6,
        borderColor: COLORS.white,
        borderWidth: 1,
      },
    },
  };

  return (
    <div className="flex flex-col w-full">
      {/* <div className="flex">
        <div className="flex">
          <button
            className={`
              bg-pink-500 active:bg-pink-600 text-white shadow hover:shadow-lg
              font-bold uppercase text-sm 
              px-6 py-3 mr-1 mb-1
              rounded outline-none focus:outline-none 
              ease-linear transition-all duration-150
            `}
            type="button"
            onClick={handleOnOpen}
          >
            Add a new category
          </button>
        </div>
      </div> */}
      <div>
        {/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}




      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-0 md:gap-3 lg:gap-5 mt-8 sm:auto-cols-auto auto-cols-fr">
        <HomeSection bgSrc={wishlistIcon}>
          <Heading title="Wishlist" icon={faHeart} subheading={`${wishlistCount} items`} />
          <NavLink
            className="mx-0 flex items-center text-md
              text-bg-cold-600 
              hover:text-bg-cold-800 
              transition duration-300 ease-in-out 
              absolute left-4 bottom-4
            "
            to="/wishlist"
            end
          >
            Go to Wishlist
          </NavLink>
        </HomeSection>
        <HomeSection bgSrc={shoppingIcon}>
          <Heading title="Purchased" icon={faCircleCheckFilled} subheading={`${purchasedCount} items`} />
          <NavLink
            className="mx-0 flex items-center text-md
              text-bg-cold-600 
              hover:text-bg-cold-800 
              transition duration-300 ease-in-out 
              justify-end
              absolute left-4 bottom-4
            "
            to="/collection"
            end
          >
            Go to Collection
          </NavLink>
        </HomeSection>
        <HomeSection bgSrc={giftIcon}>
          <Heading title="Gifts" icon={faGift} subheading={`${giftCount} items`} />
          <NavLink
            className="mx-0 flex items-center text-md
              text-bg-cold-600 
              hover:text-bg-cold-800 
              transition duration-300 ease-in-out 
              justify-end
              absolute left-4 bottom-4
            "
            to="/collection"
            end
          >
            Go to Collection
          </NavLink>
        </HomeSection>

        <HomeSection className="order-2 lg:order-1" colSpan={2}>
          <Heading title="Statistics" icon={faChartColumn} />
          <Bar options={options} data={data} className="z-50" />
        </HomeSection>

        <HomeSection className="order-1 lg:order-2">
          <Heading title="Money spent" icon={faMoneyBill} />

          {countedCurrencies.length ? (
            <ul className="grid grid-flow-col mt-4 grid-cols-4 mb-6">
              {countedCurrencies.map((currency, index) =>
                currency.count > 0 &&
                <li key={index} className="flex items-center justify-center mt-2 shadow-bg-cold shadow bg-bg-cold-100 rounded-lg mx-auto p-2 aspect-square">
                  <div className="flex flex-col justify-center items-center">
                    <span className="flex w-10 text-bg-light-600 font-black text-xl justify-center">
                      {currency.count}
                    </span>
                    <span className="text-lg text-bg-cold-700 font-medium">
                      {currency.iso}
                    </span>
                  </div>
                </li>
              )}
            </ul>
          ) : null}
          {countedCurrencies.length
            ? (
              <div className="w-4/5 mx-auto self-center flex">
                <Doughnut
                  data={doughnutData}
                  className="z-50"
                  plugins={plugins}
                  options={doughnutOptions}
                />
              </div>
            ) : (
              <div className="">
                <span className="text-bg-light-300 flex mt-4">
                  You first need to add some currencies in the settings tab
                </span>

                <NavLink
                  className="mx-0 flex items-center text-md
                    text-bg-cold-600 
                    hover:text-bg-cold-800 
                    transition duration-300 ease-in-out 
                    absolute left-4 bottom-4
                  "
                  to="/profile"
                  end
                >
                  Go to Profile
                </NavLink>
              </div>

            )}
        </HomeSection>
      </div>
      {/* <div className="grid grid-cols-3 gap-x-5 my-12 "> */}
      {/* <div className="col-span-3 md:col-span-2 bg-white p-4 rounded-md shadow-sm flex flex-col justify-between"> */}

      {/* <div className="mb-4">
          <Heading title="Statistics" icon={faChartColumn} />
        </div> */}
      {/* </div> */}
      {/* <div className="col-span-3 md:col-span-1 bg-white p-4 rounded-md shadow-sm relative"> */}

    </div>
    // </div>
    // </div>
  );
};

export default Home;