import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faImage, faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { ICategory } from "src/utils/interfaces";
import Tooltip from "./Tooltip";

interface IProps {
  doc: QueryDocumentSnapshot<ICategory>;
  selectedCategory?: QueryDocumentSnapshot<ICategory>;
  defaultUrl: string;
  onEditClick: () => void;
  onImageClick: () => void;
  onDeleteClick: () => void;
  onCategoryClick: (event: MouseEvent<HTMLAnchorElement>, isSelected: boolean) => void;
  onToggleClick: (value: QueryDocumentSnapshot<ICategory> | undefined) => void;
}

const CategoryItem = ({
  doc,
  selectedCategory,
  defaultUrl,
  onToggleClick,
  onCategoryClick,
  onDeleteClick,
  onEditClick,
  onImageClick
}: IProps) => {

  const { img_url: imgUrl, name, slug } = useMemo(() => {
    return doc.data() as ICategory;
  }, [doc]);

  const selectedData = useMemo(() => {
    return selectedCategory?.data() as ICategory;
  }, [selectedCategory]);

  const isSelected = useMemo(() => selectedData?.slug === slug, [selectedData, slug]);

  useEffect(() => {
    if (imgUrl?.length) {
      setImgHasError(false);
    }
  }, [imgUrl]);

  const [imgHasError, setImgHasError] = useState(false);

  return (
    <div className="col-span-1 mb-16 text-center group cursor-pointer relative">

      {/* <CategoryLoader className={`absolute top-5 transition-all duration-200 ease-in w-full h-auto ${allLoaded ? 'opacity-0 z-0' : 'opacity-100 z-30'}`} /> */}

      <p className="font-display text-4xl text-center w-full leading-7 my-3 group-hover:text-secondary-500 absolute bottom-full">
        {name}
      </p>
      <div className={`
        rounded-full 
        transition-all duration-300 
        shadow-spotlight hover:shadow-spotlight-hover
        hover:scale-[0.99]
        relative
        flex justify-center
        aspect-square
      `}>
        <NavLink
          to={`${slug}`}
          onClick={event => onCategoryClick(event, isSelected)}
          className={`relative flex justify-center w-full h-full aspect-square`}>
          <img
            src={!imgHasError ? imgUrl : defaultUrl}
            alt={imgUrl ? `Image ${name}` : 'Image not available'}
            onError={() => setImgHasError(true)}
            // onLoad={() => handleImageLoaded(slug)}
            className="aspect-square object-cover flex items-center rounded-full"
          />
          {
            <div
              className={
                `absolute flex 
                  ${isSelected
                  ? 'opacity-100 cursor-default'
                  : 'translate-y-10 opacity-0'
                }
                  translate-y-30
                  transition-all duration-200 ease-in
                  items-center justify-center 
                  bg-white 
                  w-full h-full
                  space-x-3
                  rounded-full

              `}>
              <Tooltip simple offset={10} position="top-start" element={
                <FontAwesomeIcon
                  icon={faPenToSquare as IconProp}
                  className="
                    w-5 h-5 p-3 bg-bg-cold-200 rounded-md border border-bg-cold-500
                    text-accent-400 hover:text-accent-100 
                    cursor-pointer
                    ease-in-out transition-all duration-200
                  "
                  onClick={onEditClick}
                />
              } >
                <span className="flex w-40 justify-center text-secondary">
                  Edit name
                </span>
              </Tooltip>
              <Tooltip simple offset={10} position="top" element={
                <FontAwesomeIcon
                  icon={faImage as IconProp}
                  className="
                  w-5 h-5 p-3 bg-bg-cold-200 rounded-md border border-bg-cold-500
                  text-accent-300 hover:text-accent-50 
                  cursor-pointer
                  ease-in-out transition-all duration-200"
                  onClick={onImageClick}
                />
              } >
                <span className="flex w-40 justify-center text-secondary">
                  Edit image
                </span>
              </Tooltip>

              <Tooltip simple offset={10} position="top-end" element={
                <FontAwesomeIcon
                  icon={faTrashCan as IconProp}
                  className="
                  w-5 h-5 p-3 bg-bg-cold-200 hover:bg-red-50 rounded-md border border-bg-cold-500
                  text-red-500 hover:text-red-400
                  cursor-pointer
                  ease-in-out transition-all duration-200"
                  // onClick={() => setShowConfirmDeleteModal(true)}
                  onClick={onDeleteClick}
                />
              } >
                <span className="flex w-40 justify-center text-red-500">
                  Delete category
                </span>
              </Tooltip>

            </div>}
        </NavLink>
        <div className="w-full h-full rounded-full overflow-hidden absolute z-30 pointer-events-none">
          <div
            className={`
              absolute bottom-0 
              pointer-events-auto
              ${isSelected
                ? 'bg-accent-300 text-white group-hover:text-bg-cold-800:text-white'
                : 'bg-white group-hover:text-bg-cold-800'}
              w-full 
              py-4 
              opacity-0 group-hover:hover:opacity-100 group-hover:opacity-60
              ease-in-out transition-all duration-200
              select-none
            `}
            onClick={() => {
              // if (isSelected)
              //   setSelectedCategory(undefined);
              // else setSelectedCategory(doc);
              onToggleClick(isSelected ? undefined : doc);
            }
            }
          >Edit</div>
        </div>
      </div>
    </div>
  );


};

export default CategoryItem;