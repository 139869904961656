import { FC, PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {

}
const Header: FC<IProps> = ({ children }) => {
  return (
    <h2 className="font-bold text-6xl text-primary-200 mt-2 z-50 tracking-widest font-display">{children}</h2>
  );
};

export default Header;