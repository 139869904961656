import { useState, createRef, FC, useEffect, useRef, useCallback, ChangeEvent } from "react";
import { createPopper, Placement } from "@popperjs/core";
import { collection, DocumentData, getFirestore } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

import useClickOutside from "src/hooks/useClickOutside";
import { IGetCurrency } from "src/types/currency-types";
import ItemCardInput from "./ItemCardInput";
import { AppContext } from "src";
import { withContext } from "src/context";

// make component more generic
// make a dropdown with external source of list and onCHange + active element

interface IOption {
  [key: string]: any;
}
interface IDropdown<T> extends Partial<AppContext> {
  isOpen: boolean;
  containerClassName?: string;
  options: T[];
  selectedOption?: T;
  placement?: Placement;
  onClick: (currency: T | any) => void;
  onClose?: () => void;
  onOpen?: () => void;
  placeholder?: string;
  firstKey: string;
  secondKey: string;
  simple?: boolean;
  searchPlaceholder?: string;
}
const Dropdown = <T extends IOption,>(props: IDropdown<T>) => {

  const {
    isOpen,
    containerClassName,
    onClick,
    onClose,
    onOpen,
    options,
    selectedOption,
    placement = "right-start",
    placeholder = 'Choose an option',
    firstKey,
    secondKey,
    simple = false,
    searchPlaceholder = "Search",
    setIsModalOpen
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const [shownOptions, setShownOptions] = useState<T[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const btnRef = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const closeModal = useCallback(() => {
    onClose && onClose();
  }, []);

  const openModal = useCallback(() => {
    onOpen && onOpen();
  }, []);

  const openDropdownPopover = useCallback(() => {
    if (btnRef.current && optionsRef.current) {
      createPopper(
        btnRef.current,
        optionsRef.current,
        { placement })
        .update()
        .then(() => {
          openModal();
          inputRef.current?.focus();
        });
    }
  }, [btnRef.current, optionsRef.current, inputRef.current, placement]);

  const handleSelect = (selectedCurrency: T) => {
    onClick(selectedCurrency);
    closeModal();
  };

  const handleButtonClick = useCallback(() => {
    isOpen
      ? closeModal()
      : openDropdownPopover();
  }, [isOpen]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);
  };


  // Close the dropdown when clicked outside
  useClickOutside(ref, () => closeModal());

  useEffect(() => {
    // console.log('options', options.map(option => option.data()));
    options.map(option => { });

    const filteredOptions: T[] = options
      .filter(option => option[secondKey]?.toLowerCase()
        .includes(searchValue.toLowerCase())
      );

    setShownOptions(filteredOptions);
  }, [searchValue, options]);


  return (
    <div ref={ref} className={`flex flex-wrap relative ${containerClassName ?? ''}`}>
      <div className="relative">

        <button
          className='
            text-white font-bold uppercase text-sm 
            px-6 py-3.5
            rounded shadow hover:shadow-lg outline-none focus:outline-none 
            ease-linear transition-all duration-150 
            bg-secondary-500
            flex w-28 justify-center
            '
          type="button"
          ref={btnRef}
          onClick={handleButtonClick}
        >
          <span className="flex overflow-hidden whitespace-nowrap text-ellipsis">
            {simple
              ? placeholder
              : selectedOption?.[firstKey] ?? placeholder}
          </span>
        </button>

        <ul
          ref={optionsRef}
          className={
            `${isOpen ? "opacity-100 " : "opacity-0 pointer-events-none"} 
            bg-bg-cold-50 
            absolute top-10 left-0 
            text-base py-2 px-2 list-none text-left rounded shadow-lg
            z-50
            max-h-48 overflow-scroll no-scrollbar
            `
          }
          style={{ minWidth: "13rem" }}
        >
          {/* <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={`
            flex w-full
            outline-none border-b
            transition-all ease-in-out duration-200
            text-secondary focus:border-b-accent-100 border-b-bg-cold bg-bg-cold-200
            px-2 pb-1 pt-2 mb-1
            rounded-lg
          `}
          /> */}
          <li>
            <ItemCardInput
              innerRef={inputRef}
              placeholder={searchPlaceholder}
              value={searchValue}
              onInput={handleSearch}
              type="search"
              containerClassName="mb-2"
            />
          </li>
          {/* <input type="search" value={searchValue} onChange={handleSearch} /> */}
          {shownOptions?.length
            ? shownOptions.map((option, index) => (
              <li
                key={index}
                className="cursor-pointer hover:bg-bg-cold-200 px-2"
                onClick={() => handleSelect(option)}>
                {simple
                  ?
                  <p>{option[firstKey]}</p>
                  :
                  <>
                    <span className="text-bg-light-300 absolute">
                      ({option[firstKey]})
                    </span>
                    <span className={`ml-14 text-accent`}>
                      {option[secondKey]}
                    </span>
                  </>
                }
              </li>
            ))
            : searchValue.length
              ?
              <li>No item found</li>
              :
              <li>List is empty</li>


          }
          {/* <a
            href="#id"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
              "text-secondary"
            }
            onClick={e => e.preventDefault()}
          >
            Action
          </a>
          <a
            href="#pablo"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
            }
            onClick={e => e.preventDefault()}
          >
            Another action
          </a>
          <a
            href="#an"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
            }
            onClick={e => e.preventDefault()}
          >
            Something else here
          </a>
          <div className="h-0 my-2 border border-solid border-t-0 border-slate-800 opacity-25" />
          <a
            href="#else"
            className={
              "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white"
            }
            onClick={e => e.preventDefault()}
          >
            Seprated link
          </a> */}
        </ul>
      </div>
    </div>
  );
};

export default withContext(Dropdown);