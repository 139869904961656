import { CSSProperties, PropsWithChildren, useEffect, useMemo, useRef } from "react";

interface ITooltipProps {
  element: JSX.Element;
  position?:
  | 'top' | 'top-right' | 'top-left' | 'top-start' | 'top-end'
  | 'right' | 'left' | 'bottom';
  simple?: boolean;
  offset?: number;
}

/**
 * 
 * @param element - The anchor element
 * @param children - The tooltip content
 * 
 */
const Tooltip = (props: PropsWithChildren<ITooltipProps>) => {
  const { children, element, position = 'bottom', simple, offset = 0 } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const posCls: React.HTMLAttributes<HTMLDivElement>['className'] = useMemo(() => {
    switch (position) {
      case 'top':
        return `rounded-md`;
      case 'top-right':
      case 'top-start':
        return 'rounded-md rounded-bl-none';
      case 'top-left':
      case 'top-end':
        return 'rounded-md rounded-br-none';

      default:
        return '';
    }
  }, [position, containerRef]);

  const styling: CSSProperties | undefined = useMemo(() => {
    if (containerRef.current && tooltipRef.current && children) {
      const containerX = Math.round(containerRef.current.clientWidth);
      const tooltipXHalf = Math.round(tooltipRef.current.clientWidth / 2);

      switch (position) {
        case 'top':
          return {
            translate: -tooltipXHalf + (containerX / 2),
            bottom: '100%',
            marginBottom: offset
          } as CSSProperties;
        case 'top-right':
          return {
            translate: tooltipXHalf,
            bottom: '100%',
            marginBottom: offset
          } as CSSProperties;
        case 'top-left':
          return {
            translate: -(tooltipXHalf + containerX),
            bottom: '100%',
            marginBottom: offset
          } as CSSProperties;
        case 'top-start':
          return {
            // translate: -(tooltipXHalf + containerX),
            left: 0,
            bottom: '100%',
            marginBottom: offset
          } as CSSProperties;
        case 'top-end':
          return {
            // translate: -(tooltipXHalf + containerX),
            right: 0,
            bottom: '100%',
            marginBottom: offset
          } as CSSProperties;
        default:
          return {
            translate: -tooltipXHalf + (containerX / 2),
            top: '100%',
            marginTop: offset
          } as CSSProperties;

      }
    }
    // return undefined;
    return {
      top: '100%',
      marginTop: offset
    } as CSSProperties;
  }, [containerRef.current, position, tooltipRef.current, children, offset]);




  return (
    <div className="flex relative">
      <div ref={containerRef} className="peer flex cursor-pointer">
        {element}
      </div>
      {/* <img src={cat} alt="Cat tooltip" className="absolute opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out w-[480px] h-[134px] top-0" /> */}
      <div
        ref={tooltipRef}
        style={styling}
        className={`
        bg-bg-cold-100
        ${simple ? '' : 'min-w-[300px]'}
        object-center
        absolute ${[posCls]}
        p-2
        opacity-0 pointer-events-none 
        peer-hover:opacity-100 hover:pointer-events-auto 
        transition-all duration-200 ease-in-out
        z-999
        border border-bg-cold-500
        `}
      >{children}</div>
    </div>
  );
};

export default Tooltip;