import { FC, PropsWithChildren } from "react";
import { ETabsNames } from "../Home/utils/category-tabs";

interface IProps extends PropsWithChildren {
  activeTab: number;
  shownTab: number;
  tab: number;
}
const NewCategoryTabPane: FC<IProps> = ({ activeTab, shownTab, children, tab }) => {
  return (
    <div
      className={`tab-pane fade max-h-72 w-full h-72 
        ${activeTab === tab ? ' active' : ' inactive'}
        ${shownTab === tab ? ' show' : ''}`}
      role="tabpanel"
      aria-labelledby={ETabsNames[tab]}>
      {/* <div className="h-full max-h-72 flex w-full flex-wrap gap-2 gap-x-4 gap-y-4 overflow-scroll p-3"> */}
      <div className="max-h-72 grid grid-flow-row gap-4 grid-cols-5 overflow-scroll p-3 pb-0">
        {children}
      </div>
    </div>
  );
};

export default NewCategoryTabPane;