// import { useState } from 'react';
// import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table'  // new

// // Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
// }) {
//   const count = preGlobalFilteredRows.length
//   const [value, setValue] = useState(globalFilter)
//   const onChange = useAsyncDebounce(value => {
//     setGlobalFilter(value || undefined)
//   }, 200)

//   return (
//     <span>
//       Search:{' '}
//       <input
//         value={value || ""}
//         onChange={e => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//       />
//     </span>
//   )
// }

// function Table({ columns, data }) {
//   // Use the state and functions returned from useTable to build your UI
//   const {
//     ...
//     state, // new
//     preGlobalFilteredRows, // new
//     setGlobalFilter, // new
//   } = useTable({
//     columns,
//     data,
//   },
//     useGlobalFilter // new
//   )

//   // Render the UI for your table
//   return (
//     <>
//       <GlobalFilter
//         preGlobalFilteredRows={preGlobalFilteredRows}
//         globalFilter={state.globalFilter}
//         setGlobalFilter={setGlobalFilter}
//       />
//       <table {...getTableProps()} border="1">
//         ...
//       </table>
//     </>
//   )
// }
// src/Table.js
// src/Table.js
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faHeart, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faAngleDoubleLeft, faAngleDoubleRight, faArrowDownWideShort, faArrowUpShortWide, faChevronLeft, faChevronRight, faSort, faHeart as faHeartFilled, faGift, faGifts } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { FC } from "react";
import { RowProps } from "react-bootstrap";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import {
  Column,
  Row,
  TableRowProps,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  UseGlobalFiltersState,
  useTable,
  useSortBy,
  usePagination,
  Cell,
  TableState,
  CellProps,

} from "react-table";
import ItemModal from "src/components/item-modal/ItemModal";
import Modal from "src/components/modal";
import { deleteFirebaseDocumentByID } from "src/firebase/delete-firebase-document-by-id";
import { COLORS } from "src/utils/constants";
import { ICategory, ICategoryItem } from "src/utils/interfaces";
import ItemCardInput from "../Categories/ItemCardInput";
import catLogo from '../../assets/404.gif';
import { collection, CollectionReference, doc, getFirestore, onSnapshot, query, QuerySnapshot, updateDoc } from "firebase/firestore";
import { Collections } from "src/utils/enums";
import { db } from "src";
// import { db } from "src/firebase/firebase-config";

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

type TButtonProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const Button: FC<PropsWithChildren<TButtonProps>> = ({ children, className = '', disabled, ...rest }) => {
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-secondary bg-white hover:bg-gray-50",
        disabled ? "bg-bg-cold-200 text-bg-cold-400" : "bg-white hover:bg-accent-100 text-secondary-100 hover:text-white",
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};


export const PageButton: FC<PropsWithChildren<TButtonProps>> = (props) => {
  const {
    children,
    className = '',
    disabled,
    ...rest
  } = props;
  return (
    <button
      type="button"
      className={classNames(
        "relative inline-flex items-center px-2 py-2",
        "text-sm font-medium",
        "transition-all ease-in-out duration-200",
        disabled ? "bg-bg-cold-200 text-bg-cold-400" : "bg-white hover:bg-accent-100 text-secondary-100 hover:text-white",
        className

      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    render
  },
}: {
  column: {
    filterValue: string,
    setFilter: (val?: string | boolean) => void,
    preFilteredRows: Row[],
    id: string;
    render: (type: string, props?: object | undefined) => React.ReactNode;
  };
}): any => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  // console.log('options ', options);
  // console.log('Boolean(options[0]) ', typeof options[0]);
  // console.log('filtervale) ', filterValue);


  // useEffect(() => {
  //   if (typeof options[0] === 'boolean' && typeof filterValue === 'undefined') {
  //     setFilter(false);
  //   }

  // }, [filterValue, options]);
  return (
    // <select
    //   name={id}
    //   id={id}
    //   value={filterValue}
    //   onChange={(e) => {
    //     setFilter(e.target.value || undefined);
    //   }}
    // >
    //   <option value="">All</option>
    //   {options.map((option, i) => (
    //     <option key={i} value={option}>
    //       {option}
    //     </option>
    //   ))}
    // </select>



    <label className="flex gap-x-2 items-baseline ml-4 text-secondary-100">
      <span className="text-secondary select-none">{render("Header")}: </span>
      {typeof options[0] === 'boolean' ? (
        options.map((option, i) => (
          <button key={i} className={`${filterValue === option ? 'font-black' : ''}`} onClick={e => {
            if (filterValue === option) {
              setFilter(undefined);
            } else {
              setFilter(option);
            }
          }}>
            {option === true ? 'Yes' : 'No'}
          </button>
        ))
      )
        :
        <select
          className="mt-1 p-3 block w-full rounded-lg border-bg-cold border-b outline-none bg-bg-cold-100 "
          name={id}
          id={id}
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      }
    </label>
  );
};



// This is a custom filter UI for selecting
// a unique option from a list
export const CategoryColumnFilter = (props: {
  column: {
    filterValue: string,
    setFilter: (val?: string | boolean) => void,
    preFilteredRows: Row[],
    id: string;
    render: (type: string, props?: object | undefined) => React.ReactNode;
  },
  categories: any;
}): any => {
  const { column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    render
  }, categories } = props;
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set<any>();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  // console.log('options ', options);
  // console.log('Boolean(options[0]) ', typeof options[0]);
  // console.log('filtervale) ', filterValue);


  // useEffect(() => {
  //   if (typeof options[0] === 'boolean' && typeof filterValue === 'undefined') {
  //     setFilter(false);
  //   }

  const getTitle = (id: string) => {
    const found = (categories as ICategory[]).find(cat => cat.id === id);
    return found?.name ?? '';
  };
  // }, [filterValue, options]);
  return (
    // <select
    //   name={id}
    //   id={id}
    //   value={filterValue}
    //   onChange={(e) => {
    //     setFilter(e.target.value || undefined);
    //   }}
    // >
    //   <option value="">All</option>
    //   {options.map((option, i) => (
    //     <option key={i} value={option}>
    //       {option}
    //     </option>
    //   ))}
    // </select>


    <label className="flex gap-x-2 items-baseline ml-4 text-secondary-100">
      <span className="text-secondary select-none">{render("Header")}: </span>

      <select
        className="mt-1 p-3 block w-full rounded-lg border-bg-cold border-b outline-none bg-bg-cold-100 "
        name={id}
        id={id}
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.length && options.map((option, i) => (
          <option key={i} value={option}>
            {getTitle(option)}
          </option>
        ))}
      </select>

    </label>
  );
};


// Define a default UI for filtering
const GlobalFilter = <T extends object>({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: { preGlobalFilteredRows: Row<T>[], globalFilter: string, setGlobalFilter: (value?: string) => void; }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: string) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-accent select-none">Search: </span>
      <ItemCardInput value={value || ''} onInput={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }} placeholder={`${count} records...`} />
      {/* <input
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      /> */}
    </label>
  );
};
export function BooleanCell(props: any) {
  const status = props.value === true ? 'yes' : 'no';
  const { id } = props.cell.row.values;
  const { handleFavorite, handleGift } = props;

  const favorite = props.cell.column.id === 'favorite';


  // ${status.startsWith("yes") ? "bg-green-100 text-green-700" : ''}
  // ${status.startsWith("no") ? "bg-red-100 text-red-700" : ""}
  return (
    <span
      className={`py-3 w-10 uppercase leading-wide font-bold text-xs flex rounded-full shadow-sm items-center justify-center cursor-pointer`}
      onClick={() => favorite ? handleFavorite(id, !props.value) : handleGift(id, !props.value)}
    >
      {status === 'yes'
        ? <FontAwesomeIcon icon={favorite ? faHeartFilled : faGifts} className="text-secondary-300 w-4 h-4 cursor-pointer" />
        : <FontAwesomeIcon icon={favorite ? (faHeart as IconProp) : faGift} className="w-4 h-4 cursor-pointer text-secondary-300" />
      }
    </span>
  );
}
export function ImageCell(props: any) {
  // const status = props.value === true ? 'yes' : 'no';

  const [imgHasError, setImgHasError] = useState(false);

  // ${status.startsWith("yes") ? "bg-green-100 text-green-700" : ''}
  // ${status.startsWith("no") ? "bg-red-100 text-red-700" : ""}
  useEffect(() => {
    if (props.value?.length) {
      setImgHasError(false);
    }
  }, [props.value]);

  return (
    <div className="w-14 h-14 flex items-center justify-center bg-white rounded-md">
      {!imgHasError
        ?
        <img
          src={props.value} onError={() => setImgHasError(true)}
          alt={`Item`}
          className="flex h-10 w-10 rounded-md"
        />
        :
        <img
          src={catLogo}
          alt="not found"
          className="h-10 w-auto rounded-md overflow-hidden" />
      }
    </div>
  );
}
export function CategoryCell(props: any) {
  // const status = props.value === true ? 'yes' : 'no';
  const { categories } = props;
  // const [imgHasError, setImgHasError] = useState(false);
  const category = (categories as ICategory[]).find(cat => cat.id === props.value);
  // ${status.startsWith("yes") ? "bg-green-100 text-green-700" : ''}
  // ${status.startsWith("no") ? "bg-red-100 text-red-700" : ""}
  // useEffect(() => {
  //   if (props.value?.length) {
  //     setImgHasError(false);
  //   }
  // }, [props.value]);

  return (
    <div className="flex items-center">
      <div className="w-14 h-14 flex flex-col items-center justify-center bg-white rounded-md relative mr-3">
        {category
          ?
          <>
            <img
              src={category.img_url}
              alt={`Category`}
              className="flex h-10 w-10 rounded-md"
            />
          </>
          :
          <img
            src={catLogo}
            alt="not found"
            className="h-10 w-auto rounded-md overflow-hidden" />
        }
      </div>
      <span>
        {category?.name}
      </span>
    </div>
  );
}
export function ActionsCell(props: CellProps<any, string[]>) {
  const { value: actions, handleEditClick, setActiveCategoryID, promptBeforeDelete } = props;

  // ${status.startsWith("yes") ? "bg-green-100 text-green-700" : ''}
  // ${status.startsWith("no") ? "bg-red-100 text-red-700" : ""}
  const { brand_id, cat_id, currency, description, favorite, gift, id, img_url, link_urls, price, purchased, title } = props.cell.row.values;
  const item: ICategoryItem = {
    brand_id,
    cat_id,
    currency,
    description,
    favorite,
    gift,
    id,
    img_url,
    link_urls,
    price,
    purchased,
    title,
  };
  const handleDelete = () => {
    promptBeforeDelete(item);
  };
  const handleEdit = () => {

    setActiveCategoryID(cat_id);
    handleEditClick(item);
  };
  return (
    <span
      className={`py-3 px-2 w-20 uppercase leading-wide font-bold text-xs rounded-full shadow-sm space-x-3 flex justify-center items-center `}
    >
      {actions.map((action, index) => (
        <button key={index}>
          <FontAwesomeIcon
            icon={(action === 'edit' ? faEdit : faTrashCan) as IconProp}
            className="w-4 h-4"
            onClick={action === 'edit' ? handleEdit : handleDelete}
          />
        </button>
      ))
      }
    </span>
  );
}

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  hiddenColumns: string[];
}

const Table = <T extends object>({ columns, data, hiddenColumns }: TableProps<T>) => {


  const [categoryItems, setCategoryItems] = useState<ICategory[]>([]);

  /**
   * Get all categories
   */
  useEffect(() => {
    // const db = getFirestore();
    const categoriesQuery = query<ICategory>(
      collection(db, Collections.categories) as CollectionReference<ICategory>
    );

    const handleOnSnapshot = (snapshot: QuerySnapshot<ICategory>) => {
      const { docs } = snapshot;

      const categoryList: ICategory[] = [];
      docs.map(doc => {
        categoryList.push({ ...doc.data(), id: doc.id });
      });
      setCategoryItems(categoryList);
    };

    const unsub = onSnapshot(categoriesQuery, handleOnSnapshot);

    return unsub;
  }, []);

  const categories = useMemo(() => categoryItems, [categoryItems]);


  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    // rows,
    page,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    prepareRowstate,
    preGlobalFilteredRows,
    prepareRow,
    setGlobalFilter,
  } = useTable<T>({
    columns,
    data,
    initialState: {
      hiddenColumns
    }
  },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );


  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<ICategoryItem | undefined>(undefined);
  const [activeCategoryID, setActiveCategoryID] = useState<string | undefined>(undefined);

  const handleDeleteItem = async () => {
    if (selectedItemToDelete?.id) {
      try {
        await deleteFirebaseDocumentByID('items', selectedItemToDelete.id);
        console.log("Item deleted");
        setShowConfirmDeleteModal(false);
      } catch (error) {
        console.log('Error deleting item', error);
        setShowConfirmDeleteModal(false);
      }
    }
  };

  const promptBeforeDelete = (item: ICategoryItem) => {
    setSelectedItemToDelete(item);
    // handleEditMenuClose();
    setShowConfirmDeleteModal(true);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState<ICategoryItem | undefined>(undefined);

  const handleEditClick = (item: ICategoryItem) => {
    setShowEditModal(true);
    setEditItem(item);
    // handleEditMenuClose();
  };

  const updateAttr = (id: string, key: keyof ICategoryItem, value: boolean) => {
    // const db = getFirestore();
    try {
      const ref = doc(db, Collections.items, id);
      console.log('ref ', ref);

      updateDoc(ref, { [key]: value });
    } catch (error) {
      console.log('Error updating favorite', error);
    }
  };
  const handleFavorite = (id: string, favorite: boolean) => {
    updateAttr(id, "favorite", favorite);
  };
  const handleGift = (id: string, gift: boolean) => {
    updateAttr(id, "gift", gift);
  };


  // Render the UI for your table
  return (
    <>
      {activeCategoryID && <ItemModal
        activeCategoryID={activeCategoryID}
        show={showEditModal}
        setShow={setShowEditModal}
        item={editItem}
      />}
      <Modal
        isOpen={showConfirmDeleteModal}
        onClose={() => {
          setShowConfirmDeleteModal(false);
          setSelectedItemToDelete(undefined);
        }}
        onSubmit={handleDeleteItem}
        submitDisabled={false}
        title="Confirm delete"
        submitTitle="Delete"
        dismissTitle="Cancel"
        submitColor="delete"
      >
        <div className="flex flex-row w-full">
          <div className="flex w-full flex-col p-3">
            <div className="w-full h-40 aspect-square overflow-hidden p-4">
              {selectedItemToDelete && <h2 className="text-lg">Are you sure you want to delete <span className="text-primary-100">{selectedItemToDelete.title}</span> ?</h2>}
              <h4 className="font-light mt-5">This cannot be undone!</h4>
            </div>
          </div>
        </div>
      </Modal>
      <div className="flex gap-x-8 items-baseline ml-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div key={column.id} className="flex items-baseline">
                {/* <label htmlFor={column.id}>{column.render("Header")}: </label> */}
                {column.render("Filter", { categories })}
              </div>
            ) : null
          )
        )}
      </div>
      <div className="mt-2 flex flex-col">
        <div className="overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden border-b border-accent sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-bg-cold-100">
                <thead className="bg-bg-cold-200">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-primary uppercase tracking-wider"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex justify-between">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            {column.canSort && (
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? <FontAwesomeIcon icon={faArrowDownWideShort} color={COLORS.accent[400]} />
                                    : <FontAwesomeIcon icon={faArrowUpShortWide} color={COLORS.accent[400]} />
                                  : <FontAwesomeIcon
                                    icon={faArrowUpShortWide}
                                    color={COLORS['bg-cold'][500]}
                                    className="opacity-0 group-hover:opacity-100" />}
                              </span>
                            )}

                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}


                  {/* // column.Filter ? (
              //   <div key={column.id}>
              //     <label htmlFor={column.id}>{column.render("Header")}: </label>
              //     {column.render("Filter")}
              //   </div>

            // ) */}

                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} className="group">
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap group-hover:bg-bg-cold-100 transition-all ease-in-out duration-200">
                            {cell.render("Cell", { setActiveCategoryID, handleEditClick, promptBeforeDelete, handleFavorite, handleGift, categories })}
                          </td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <span className="text-sm text-accent">
              Page <span className="font-medium text-primary">{state.pageIndex + 1}</span> of <span className="font-medium text-primary">{pageOptions.length}</span>
            </span>
            <label className="outline-none text-secondary select-none">
              <span className="sr-only text-secondary select-none">Items Per Page</span>
              <select
                className="mt-1 p-3 block w-full outline-none rounded-md border-accent border-b bg-bg-cold-100"
                value={state.pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
            {/* <span className="text-sm text-gray-700">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <select
              value={state.pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 20].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md border-b border-accent -space-x-px" aria-label="Pagination">
              <PageButton
                className="rounded-l-md"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">First</span>
                <FontAwesomeIcon icon={faAngleDoubleLeft} className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <span className="sr-only">Previous</span>
                <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                onClick={() => nextPage()}
                disabled={!canNextPage
                }>
                <span className="sr-only">Next</span>
                {/* <ChevronRightIcon className="h-5 w-5" aria-hidden="true" /> */}
                <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5" aria-hidden="true" />
              </PageButton>
              <PageButton
                className="rounded-r-md"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <span className="sr-only">Last</span>
                {/* <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" /> */}
                <FontAwesomeIcon icon={faAngleDoubleRight} className="h-5 w-5" aria-hidden="true" />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>



      {/* <div>
        <pre>
          <code>{JSON.stringify(state, null, 2)}</code>
        </pre>
      </div> */}
    </>
  );
};

export default Table;