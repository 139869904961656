import { collection, DocumentData, FieldPath, getFirestore, onSnapshot, orderBy, OrderByDirection, query, QuerySnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "src";
// import { db } from "./firebase-config";
// import { useCollection } from "react-firebase-hooks/firestore";

export const useFirebaseCollection = (name: string, orderAttr: string | FieldPath = 'name', asc?: OrderByDirection) => {


  const [collectionSnapshot, setCollectionSnapshot] = useState<DocumentData[]>([]);


  useEffect(() => {

    // const db = getFirestore();
    const col = collection(db, name);
    const q = query(col, orderBy(orderAttr, asc));

    const observer = onSnapshot(q, docSnapshot => {
      console.log(`Received doc snapshot: ${docSnapshot}`);
      // ...
      const docs = docSnapshot.docs.map(document => ({ ...document.data(), id: document.id }));

      setCollectionSnapshot(docs);
    }, err => {
      console.log(`Encountered error: ${err}`);
    });

    return observer;
  }, [name, orderAttr, asc]);


  // const [value, _loading, error] = useCollection(
  //   collection(db, name),
  //   {
  //     snapshotListenOptions: { includeMetadataChanges: true },
  //   }
  // );
  // return { value, error };
  return { data: collectionSnapshot };
};