import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart, faPerson, faShieldHalved, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { Header } from "src/components";
import { useAuth } from "../auth/hooks";
import ItemCardInput from "../Categories/ItemCardInput";
// import settingsImage from 'src/assets/undraw_personal_settings_re_i6w4.svg';
import settingsImage from 'src/assets/undraw_personal_data_re_ihde.svg';
import ProfileSection from "./ProfileSection";
import InputPlaceholder from "./InputPlaceholder";
import { useRef } from "react";

interface IProps { }

const ProfilePage: FC<IProps> = () => {
  const { authUser } = useAuth();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const [email, setEmail] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {

    if (authUser) {
      const { details, email } = authUser;
      setFirstName(details.firstName);
      setLastName(details.lastName);

      if (email)
        setEmail(email);
    }
  }, [authUser]);

  return (
    // <div className="min-h-screen bg-gray-100 text-gray-900">

    // <main className="mx-auto px-0 sm:px-1 lg:px-2 pt-6">
    <div className="flex flex-col w-full">
      <Header>Profile</Header>

      {authUser && <div className="grid grid-cols-3 gap-x-5 mt-4">


        <div className="col-span-3 md:col-span-1 flex flex-col relative bg-white place-content-start p-4 rounded-md shadow-sm space-y-4">
          <ProfileSection
            icon={faUser}
            title="Profile"
            locked={!isEditing}
            onLockClick={() => setIsEditing(!isEditing)}>

            {isEditing
              ? <ItemCardInput value={firstName} onInput={(event) => setFirstName(event.target.value)} placeholder={"First name"} />
              : <InputPlaceholder title="First name" value={firstName} />
            }
            {isEditing
              ?
              <ItemCardInput value={lastName} onInput={(event) => setLastName(event.target.value)} placeholder={"Last name"} />
              : <InputPlaceholder title="Last name" value={lastName} />
            }
          </ProfileSection>
          Verified email: {authUser.emailVerified ? 'Yes' : 'No'}
        </div>
        <div className="relative place-content-start p-4 rounded-md col-span-3 md:col-span-1">
          <img src={settingsImage} alt="Settings" className="z-40 -scale-x-100" />
        </div>


        <div className="col-span-3 md:col-span-1 flex flex-col relative bg-white place-content-start p-4 rounded-md shadow-sm space-y-4">
          <ProfileSection icon={faUser} title="Account" locked={!isEditingAccount} onLockClick={() => {
            setIsEditingAccount(!isEditingAccount);
            setTimeout(() => {
              emailInputRef.current?.focus();
            }, 400);
          }}>
            {isEditingAccount
              ? <ItemCardInput innerRef={emailInputRef} value={email} onInput={(event) => setEmail(event.target.value)} placeholder={"Email"} />
              : <InputPlaceholder title={authUser.emailVerified ? 'Email verified' : 'Email'} value={email} />
            }
          </ProfileSection>

          <ProfileSection icon={faShieldHalved} title="Security" locked={!isEditingPassword} onLockClick={() => setIsEditingPassword(!isEditingPassword)}>

            {isEditingPassword
              ?
              <>
                <ItemCardInput value={oldPassword} onInput={(event) => setOldPassword(event.target.value)} placeholder={"Old password"} />
                <ItemCardInput value={newPassword} onInput={(event) => setNewPassword(event.target.value)} placeholder={"New password"} />
                <ItemCardInput value={repeatNewPassword} onInput={(event) => setRepeatNewPassword(event.target.value)} placeholder={"Repeat new password"} />
              </>
              : <InputPlaceholder title="Password" value="*******" />
            }
          </ProfileSection>
        </div>


      </div>}
    </div>
    // </main>
    // </div>
  );
};

export default ProfilePage;