import { collection, DocumentData, getFirestore, addDoc, deleteDoc, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "src";
// import { db } from "./firebase-config";

export const deleteFirebaseDocumentByID = async (collectionName: string, id: string) => {

    // const db = getFirestore();
    // const ref = collection(db, collectionName);
    const q = doc(db, `${collectionName}/${id}`);

    try {
        // const snapshot = await getDocs(q);
        // console.log('snapshot', snapshot);
        // console.log('snapshot docs,', snapshot.docs);

        try {
            console.log('About to delete doc with id', q.id);

            return await deleteDoc(q);
        } catch (error) {
            console.log("Error adding document to collection ", collectionName);
        }
    } catch (error) {
        console.log(`Unable to get docs for query ${q} with error `, error);
    }
};