import { FC, useEffect, useMemo, useState } from "react";
import { collection, CollectionReference, getFirestore, onSnapshot, query, QuerySnapshot, where } from "firebase/firestore";
import { Collections } from "src/utils/enums";
import { ICategoryItem } from "src/utils/interfaces";
import Table, { ActionsCell, BooleanCell, CategoryCell, CategoryColumnFilter, ImageCell, SelectColumnFilter } from "./Table";
import { db } from "src";
import { useAuth } from "../auth/hooks";
import { Header } from "src/components";
// import { db } from "src/firebase/firebase-config";


interface IProps { }

interface ITableRow {
  title: string,
  category: string,
  price: number,
  currency: string,
  actions: any[];
}

const DEFAULT_ACTIONS = [
  'edit',
  'delete'
];

const WishlistPage: FC<IProps> = () => {

  const [wishlistItems, setWishlistItems] = useState<ICategoryItem[]>([]);
  const { authUser } = useAuth();

  const columns: any = useMemo( //Column<any>[] = useMemo(
    () => [
      // hidden fields
      {
        Header: "Id",
        accessor: "id" as const,
        disableSortBy: true
      },
      {
        Header: "Brand id",
        accessor: "brand_id" as const,
        disableSortBy: true
      },
      {
        Header: "Description",
        accessor: "description" as const,
        disableSortBy: true
      },
      {
        Header: "Gift",
        accessor: "gift" as const,
        disableSortBy: true
      },
      {
        Header: "Purchased",
        accessor: "purchased" as const,
        disableSortBy: true
      },

      {
        Header: "Link urls",
        accessor: "link_urls" as const,
        disableSortBy: true
      },

      // Visible fields
      {
        Header: "Image",
        accessor: "img_url" as const,
        disableSortBy: true,
        Cell: ImageCell
      },
      {
        Header: "Title",
        accessor: "title" as const,
      },
      {
        Header: "Category",
        accessor: "cat_id" as const,
        Cell: CategoryCell,
        Filter: CategoryColumnFilter,
        filter: 'includes'
        // disableSortBy: true
      },
      {
        Header: "Price",
        accessor: "price" as const,
      },
      {
        Header: "Currency",
        accessor: "currency" as const,
        Filter: SelectColumnFilter,
        filter: 'includes',
      },
      {
        Header: "Favorite",
        accessor: "favorite" as const,
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: BooleanCell,
        disableSortBy: true
        // sortable: false,
        // canSort: false
      },
      {
        Header: "Actions",
        accessor: "actions" as const,
        Cell: ActionsCell,
        disableSortBy: true
        // sortable: false,
        // filterable: false,
      },
    ],
    []
  );

  /**
   * Get all items that are not purchased
   */
  useEffect(() => {
    // const db = getFirestore();
    if (authUser) {
      const categoryQuery = query<ICategoryItem>(
        collection(db, Collections.items) as CollectionReference<ICategoryItem>,
        where("purchased", "==", false),
        where('gift', '==', false),
        where('uid', '==', authUser.uid)
      );

      const handleOnSnapshot = (snapshot: QuerySnapshot<ICategoryItem>) => {
        const { docs } = snapshot;
        // console.log('docs', docs);
        const wishlistList: ICategoryItem[] = [];
        docs.map(doc => {
          wishlistList.push({ ...doc.data(), id: doc.id, actions: DEFAULT_ACTIONS });
        });
        setWishlistItems(wishlistList);
      };

      const unsub = onSnapshot(categoryQuery, handleOnSnapshot);
      return unsub;
    }
  }, [authUser]);

  const data = useMemo(() => wishlistItems, [wishlistItems]);

  return (
    // <div className="min-h-screen bg-gray-100 text-gray-900">
    // <main className="mx-auto px-0 sm:px-1 lg:px-2 pt-6">
    <>
      <div className="flex">
        <Header>Wishlish</Header>
        {/* <h2 className="font-bold text-6xl text-primary-200 mt-2 z-50 tracking-widest font-display">Wishlist</h2> */}
      </div>
      {/* <div className="mt-4 flex-col overflow-scroll"> */}
      <Table columns={columns} data={data} hiddenColumns={['id',
        'brand_id',
        // 'cat_id',
        'description',
        'gift',
        'purchased',
        // 'img_url',
        'link_urls']} />
      {/* </div> */}
    </>
    // </main>
    // </div>
  );
};

export default WishlistPage;
