import { faList, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collection, getDoc, getFirestore, query, where, getDocs, DocumentData, onSnapshot, QuerySnapshot, QueryDocumentSnapshot, DocumentSnapshot, Query, CollectionReference } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { db } from "src";
// import { db } from "src/firebase/firebase-config";
import { Collections } from "src/utils/enums";
import { ICategory, ICategoryItem } from "src/utils/interfaces";
import { useAuth } from "../auth/hooks";
import CategoryTabs from "./CategoryTabs";



const CategoryDetail = () => {
  const params = useParams();
  // const db = getFirestore();
  const [activeCategory, setActiveCategory] = useState<ICategory | undefined>(undefined);
  const [categoryItems, setCategoryItems] = useState<QueryDocumentSnapshot<ICategoryItem>[]>([]);
  const [wishlistItems, setWishlistItems] = useState<ICategoryItem[]>([]);
  const [purchasedItems, setPurchasedItems] = useState<ICategoryItem[]>([]);
  const [giftItems, setGiftItems] = useState<ICategoryItem[]>([]);
  const { authUser } = useAuth();
  // get category from param
  // get ID from category
  // query from ID

  const handleItemsQuery = useCallback((items: QuerySnapshot<ICategoryItem>) => {
    setCategoryItems(items.docs);
  }, []);

  const handleOnSnapshot = useCallback((response: QuerySnapshot<ICategory>) => {
    // Find category name
    const first = response.docs[0];
    if (first.data()) {
      setActiveCategory({
        ...first.data(),
        id: first.id
      });

      // Build query
      const itemsQuery = query<ICategoryItem>(
        collection(db, Collections.items) as CollectionReference<ICategoryItem>,
        where("cat_id", "==", first.id)
      );

      const unsub = onSnapshot(itemsQuery, handleItemsQuery);

      return unsub;
    }
  }, []);

  useEffect(() => {
    const categoryQuery = query<ICategory>(
      collection(db, Collections.categories) as CollectionReference<ICategory>,
      where("slug", "==", params.slug),
      where("uid", "==", authUser?.uid ?? '')
    );

    const unsub = onSnapshot(categoryQuery, handleOnSnapshot);
    // getDocs(categoryQuery) //getDoc(category as any)
    //   .then(response => {
    //     // console.log(response.docs[0].data(), 'response');
    //     // console.log(params);

    //   })
    //   .catch(error => {
    //     console.error(`Error getting docs for category "${params.slug}."`, error);
    //   });
    return unsub;
  }, [db, params, authUser]);

  useEffect(() => {
    if (categoryItems.length) {
      const purchasedList: ICategoryItem[] = [];
      const wishlistList: ICategoryItem[] = [];
      const giftList: ICategoryItem[] = [];

      categoryItems.map(item => {

        const categoryItem = item.data();
        const { purchased, gift } = categoryItem;


        if (purchased)
          // If product already purchased
          // Set purchased list
          purchasedList.push(categoryItem);
        else if (gift)
          // If product is set as gift and not purchased
          // Set gift list
          giftList.push(categoryItem);
        else
          // Set wishlist list
          wishlistList.push(categoryItem);

        setPurchasedItems(purchasedList);
        setWishlistItems(wishlistList);
        setGiftItems(giftList);
      });
    }


  }, [categoryItems]);
  // const [value, _loading, error] = useCollection(
  //     query(
  //         collection(db, "items"),
  //         where("slug", "==", params.id)
  //     ),
  //     {
  //         snapshotListenOptions: { includeMetadataChanges: true },
  //     });

  //   );
  // const ref = query(
  //     collection(db, "products"),
  //     where("slug", "==", params.id)
  // );
  // const myQuery = useFirestoreQuery(["products"], ref, {
  //     subscribe: true,
  //   });
  // console.log('data', value?.docs[0]);

  return (
    <div className="pt-8">
      {/* <h1>{params.slug}</h1> */}
      <div className="flex items-center">
        <img
          className="rounded-full overflow-hidden aspect-square h-20"
          src={activeCategory?.img_url}
          alt={activeCategory?.name}
        />
        <h1 className="ml-4 font-display text-4xxl">{activeCategory?.name}</h1>
      </div>
      {/* <div>
        {categoryItems.map((item, index) => (
          <div key={index}>{item.data().title}</div>
        ))}
      </div> */}
      {activeCategory ?
        <CategoryTabs
          activeCategory={activeCategory}
          wishlistItems={wishlistItems}
          purchasedItems={purchasedItems}
          giftItems={giftItems} />
        :

        <h4>Loading... </h4>
      }
      {/* <div className="w-1/2 aspect-square flex justify-center items-center hover:bg-slate-100">
          <FontAwesomeIcon icon={faList} size="7x" />
        </div>

        <div className="w-1/2 aspect-square flex justify-center items-center hover:bg-slate-100">
          <FontAwesomeIcon icon={faListCheck} size="7x" />
        </div> */}

    </div>
  );
};

export default CategoryDetail;