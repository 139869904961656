import { FC, MouseEvent, PropsWithChildren } from "react";
interface IProps extends PropsWithChildren {
  onClick: (event: MouseEvent) => void;
  className?: string;
  variant?: 'cold' | 'accent';
}
const SimpleButton: FC<IProps> = ({ children, onClick, className = '', variant = 'cold' }) => {
  return (
    <button
      className={`
      ${variant === 'accent'
          ? 'bg-accent hover:bg-accent-300 active:bg-accent-200'
          : 'bg-bg-cold-700 hover:bg-bg-cold-600 active:bg-bg-cold-200'}
         text-white 
        shadow hover:shadow-lg
        text-sm font-bold
        px-4 py-2 
        rounded outline-none focus:outline-none 
        ease-linear transition-all duration-150
        ${className}
      `}
      onClick={onClick}>
      {children}
    </button>
  );
};

export default SimpleButton;