import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useMemo, useState } from "react";

interface IBrandItemButtonProps {
  onClick: () => void;
  id: string;
  allItems: Array<any>;
}

const BrandItemButtom: FC<IBrandItemButtonProps> = (props) => {
  const { id, onClick, allItems } = props;
  const [count, setCount] = useState(0);
  useEffect(() => {
    // const mapped = allItems.map(item => ({ ...item.data(), id: item.id }));
    const count = allItems.filter(item => item.brand_id === id).length;
    setCount(count);
    // return `${count} items`;
  }, [id, allItems]);

  return (
    <>
      <span className="mr-3 select-none">
        {`${count !== 0 ? count : 'no'} ${count !== 1 ? 'items' : 'item'}`}
      </span>
      <button onClick={() => onClick()} className={`${count !== 0 ? 'text-secondary-50 pointer-events-none cursor-default' : ''}`}>
        <FontAwesomeIcon icon={faTrash} className={`${count !== 0 ? 'text-secondary-50 pointer-events-none cursor-default' : ''}`} />
      </button>
    </>
  );
};

export default BrandItemButtom;