import { useLayoutEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import ItemCardInput from "../Categories/ItemCardInput";
import homeBg from 'src/assets/home-bg.jpeg';
import { useAuth } from "./hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AuthContainer from "./AuthContainer";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { authUser } = useAuth();

  const { onLogin } = useAuth();
  const handleLogin = () => {
    if (onLogin) onLogin(email, password);
  };

  if (authUser) return <Navigate to="/" />;

  return (
    <AuthContainer imgSrc={homeBg} title="My Vanity">
      <form>
        <ItemCardInput value={email} onInput={(e) => setEmail(e.target.value)} type="email" placeholder={"Email address"} />
        <ItemCardInput value={password} onInput={(e) => setPassword(e.target.value)} type="password" placeholder={"Password"} />
        <p className="text-base text-end mt-4 lg:mt-2">
          <NavLink to="/forgot-password" className="
              ml-2 
              text-base font-bold text-bg-light-300 hover:text-accent-100 
              transition-all duration-200 ease-in-out"
          >
            Reset password
          </NavLink>
        </p>

        <div className="mt-4 flex flex-col lg:flex-row w-full items-center">
          <button
            onClick={handleLogin}
            type="button"
            className="
              bg-accent-100 hover:bg-accent-50 active::bg-accent-200 focus:bg-accent-200 hover:focus:bg-accent-100 
              transition-all duration-200 ease-in-out 
              text-white text-lg font-bold
              px-8 py-4 
              rounded-md 
              w-full lg:w-2/6"
          >
            Login
          </button>
          <p className="text-base text-center mt-4 lg:ml-8 lg:mt-0">
            No account yet? {' '}
            <NavLink to="/signup" className="
              ml-2 
              text-base font-bold text-accent hover:text-accent-200 
              transition-all duration-200 ease-in-out"
            >
              Sign up
            </NavLink>
          </p>
        </div>

      </form>
    </AuthContainer>
  );
};

export default Login;