import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {
  title: string;
  icon: IconProp;
  locked: boolean;
  onLockClick: () => void;
}

const ProfileSection: FC<IProps> = ({
  title,
  icon,
  children,
  locked,
  onLockClick
}) => {
  return (
    <div className="flex w-full flex-col items-start">

      <FontAwesomeIcon size="2x" icon={icon} className="text-bg-cold-500" />
      <div className="flex items-center justify-between w-full">
        <h2 className="text-3xl text-primary-200 mt-2 z-50 mb-4
      font-semibold tracking-widest
      ">
          <span>
            {title}
          </span>
        </h2>

        <button onClick={() => onLockClick()}>
          <FontAwesomeIcon
            size="xl"
            icon={locked ? faLock : faLockOpen}
            className="text-bg-light-600" />
        </button>
      </div>
      <div className="flex flex-col w-full">
        {children}
      </div>
    </div>
  );
};

export default ProfileSection;