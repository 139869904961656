import { RefObject, useEffect } from "react";

const useClickOutside = (ref: RefObject<HTMLDivElement>, handleClick: () => void) => {

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node))
        handleClick();
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handleClick]);
};

export default useClickOutside;