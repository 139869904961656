import { faBars, faBurger, faHamburger, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { namespace } from 'firebase-functions/v1/firestore';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { NavLink, useLocation, useOutlet } from 'react-router-dom';
import CatLoader from 'src/pages/Categories/CatLoader';
import { useAuth } from 'src/pages/auth/hooks';
import { routes } from 'src/utils/routes';
import NavToggleButton from './NavToggleButton';
import { useDebounceEffect } from 'src/pages/Home/useDebounceEffect';
export namespace Breakpoints {

  export const xs = 0;
  export const sm = 576;
  export const md = 768;
  export const lg = 992;
  export const xl = 1200;
  export const xxl = 1400;
  export const xxxl = 1600;

};

interface IProps extends PropsWithChildren {

}
const Navigation: FC<IProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  const handleScroll = useCallback(() => {
    const { scrollY } = window;
    if (scrollY >= 50)
      setIsSticky(true);
    else
      setIsSticky(false);
  }, [window.scrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

  const { onLogout, authUser } = useAuth();

  const location = useLocation();
  const currentOutlet = useOutlet();

  const handleLogout = () => {
    onLogout && onLogout();
  };

  // const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  const [resizeWidth, setResizeWidth] = useState<number | undefined>(undefined);

  // const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
  // const isMobile = useMemo(() => {
  //   console.log('use memo is happening');

  //   return (typeof resizeWidth === 'number' ? resizeWidth : window.innerWidth) < Breakpoints.md;
  // }
  //   , [resizeWidth]);
  // const handleResize = () => {
  //   const { innerWidth } = window;
  //   if (innerWidth <= Breakpoints.md) {
  //     console.log(' is mobile true',);
  //     setIsMobile(true);
  //   }
  //   else {

  //     console.log(' is mobile false',);
  //     setIsMobile(false);
  //   }

  //   // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
  // };
  // useEffect(() => {

  //   // handleResize();
  //   const handleResize = () => {
  //     setTimeout(() => {

  //       setResizeWidth(window.innerWidth);
  //     }, 200);
  //   };
  //   window.addEventListener(`resize`, handleResize);

  //   return () => {
  //     window.removeEventListener(`resize`, handleResize);
  //   };
  // }, []);

  useEffect(
    () => {
      const handleResize = () => {
        setResizeWidth(window.outerWidth);
      };
      window.addEventListener(`resize`, handleResize);

      handleResize();
      return () => {
        window.removeEventListener(`resize`, handleResize);
      };
    },
    [],
  );

  // useDebounceEffect(() => {
  //   if (resizeWidth) {
  //     if (resizeWidth > Breakpoints.lg) {
  //       setIsMobile(false);
  //       console.log('resized to: ', window.outerWidth, 'x', window.outerHeight);

  //     }
  //     else {
  //       console.log('ismmobile is false',);

  //       setIsMobile(true);
  //     }
  //   }
  // }, 0, [resizeWidth]);
  const isMobile = useMemo(() => {
    if (resizeWidth)
      return resizeWidth < Breakpoints.md;
  }, [resizeWidth]);


  if (typeof isMobile === 'undefined') return <CatLoader />;

  return (
    <div className={`${isMobile ? 'flex flex-col h-full' : 'flex flex-row w-full h-screen'}`}>
      <>
        {isMobile ? (
          <>
            <div className="flex bg-bg-cold-50 border-b border-b-bg-cold-500 p-2">
              <NavToggleButton onClick={() => setIsNavOpen(!isNavOpen)} isOpen={isNavOpen} />
            </div>
            <nav className={`${isNavOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} relative px-4 py-4 flex w-full h-full justify-between items-center bg-bg-cold-100`}>
              <ul className="nav flex flex-col w-full last:justify-items-end bg-bg-cold-50 absolute">
                {routes?.length && routes.map((route) => (
                  route.show &&
                  <li key={route.path}>
                    <NavLink
                      className="mx-0 flex items-center text-md py-4 px-6 h-12 overflow-hidden text-bg-cold-600 text-ellipsis whitespace-nowrap hover:text-bg-cold-800 hover:bg-bg-cold-200 transition duration-300 ease-in-out justify-center"
                      to={route.path}
                      end
                    >
                      {route.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
            {/* <div className={`bg-bg-cold-100 ${!isNavOpen ? 'opacity-100' : 'opacity-0'}`}>
              {children}
            </div> */}
          </>
        )
          : (
            <>
              {/* // Sidebar  */}
              <div className="flex flex-col w-48 shrink-0 h-screen max-h-screen shadow-md bg-white">
                <div className="pt-4 pb-2 px-6 flex">
                  <div className='flex w-full items-center justify-center font-display text-3xl py-6 font-bold'>My Vanity</div>
                </div>
                <hr className="my-2" />
                <nav className="flex flex-col h-full grow justify-between">
                  <ul className="nav flex flex-col last:justify-items-end">
                    {routes?.length && routes.map((route) => (
                      route.show &&
                      <li key={route.path}>
                        <NavLink
                          className="mx-0 flex items-center text-md py-4 px-6 h-12 overflow-hidden text-bg-cold-600 text-ellipsis whitespace-nowrap hover:text-bg-cold-800 hover:bg-bg-cold-200 transition duration-300 ease-in-out justify-end"
                          to={route.path}
                          end
                        >
                          {route.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>

                  <div className='flex flex-col'>
                    <NavLink
                      className="mx-0 flex items-center text-md py-4 px-6 h-12 overflow-hidden text-bg-cold-600 text-ellipsis whitespace-nowrap hover:text-bg-cold-800 hover:bg-bg-cold-200 transition duration-300 ease-in-out justify-end"
                      to={'/logout'}
                      onClick={handleLogout}
                      end
                    >
                      Logout
                    </NavLink>
                    <div className="text-center w-full">
                      <hr className="m-0" />
                      <p className="py-2 text-sm font-light text-primary-200">powered by
                        <span className='font-bold text-primary-500'> mindesign.ro</span>
                      </p>
                    </div>
                  </div>
                </nav>
              </div>
            </>
          )}
        <div className={`bg-bg-cold-100 flex grow ${!isNavOpen ? 'opacity-100' : 'opacity-0'}`}>
          <div className={`max-h-screen w-full bg-neutral-100 overflow-scroll ${isMobile ? 'px-3' : 'px-10'}`}>
            {children}
          </div>
        </div>
      </>

    </div>
  );
};

export default Navigation;

//   {/* <div className="flex items-center justify-between border-b border-gray-400 py-8"> */}

    //     {/* <a href="/">
    //     <img src="https://designbygio.it/images/logo.png" alt="logo" />
    //   </a> */}
    //     {/* <NavLink to="/">
    //     <div className="px-6 flex">
    //       <div className='flex w-full items-center justify-center font-display text-3xl py-6 font-bold'>My Vanity</div>
    //     </div>
    //   </NavLink>
    //   <nav>
    //     <section className="MOBILE-MENU flex lg:hidden">
    //       <div
    //         className="HAMBURGER-ICON space-y-2"
    //         onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
    //       >
    //         <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
    //         <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
    //         <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
    //       </div>

    //       <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}> // toggle className based on isNavOpen state
    //         <div
    //           className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
    //           onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
    //         >
    //           <svg
    //             className="h-8 w-8 text-gray-600"
    //             viewBox="0 0 24 24"
    //             fill="none"
    //             stroke="currentColor"
    //             strokeWidth="2"
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //           >
    //             <line x1="18" y1="6" x2="6" y2="18" />
    //             <line x1="6" y1="6" x2="18" y2="18" />
    //           </svg>
    //         </div>
    //         <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
    //           <li className="border-b border-gray-400 my-8 uppercase">
    //             <a href="/categories">Categories</a>
    //           </li>
    //           <li className="border-b border-gray-400 my-8 uppercase">
    //             <a href="/portfolio">Portfolio</a>
    //           </li>
    //           <li className="border-b border-gray-400 my-8 uppercase">
    //             <a href="/contact">Contact</a>
    //           </li>
    //         </ul>
    //       </div>
    //     </section>

    //     <ul className="DESKTOP-MENU hidden space-x-8 lg:flex">
    //       <li>
    //         <a href="/about">About</a>
    //       </li>
    //       <li>
    //         <a href="/portfolio">Portfolio</a>
    //       </li>
    //       <li>
    //         <a href="/contact">Contact</a>
    //       </li>
    //     </ul>
    //   </nav>
    //   <style>{`
    //   .hideMenuNav {
    //     display: none;
    //   }
    //   .showMenuNav {
    //     display: block;
    //     position: absolute;
    //     width: 100%;
    //     height: 100vh;
    //     top: 0;
    //     left: 0;
    //     background: white;
    //     z-index: 10;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-evenly;
    //     align-items: center;
    //   }
    // `}</style> */}
    //     {/* </div> */}