import { User } from "firebase/auth";
import { createContext, MouseEvent } from "react";
import { SignUpProps, UserProps } from "./AuthProvider";

interface IContext {
    // props for context
    onLogin?: (email: string, password: string) => Promise<void>;
    onLogout?: () => void;
    onSignUp?: (props: SignUpProps) => Promise<void>;
    authUser: UserProps | null;
    authToken?: string;
    userLoading: boolean;
}

export const AuthContext = createContext<IContext>({ authUser: null, userLoading: false });