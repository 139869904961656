import { FC, useEffect, useMemo, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart as heartOutline, faSquareCheck as checkOutline } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare, faChainBroken, faGift, faGifts, faHeart as heartFilled, faSquareCheck as checkFilled } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import catLogo from '../../assets/404.gif';
import { Link } from "react-router-dom";
import ItemIcon from "./ItemIcon";
import ItemDescription from "./ItemDescription";
import { ICategoryItem } from "src/utils/interfaces";

interface IItemCardPreviewProps {
  item: ICategoryItem;
  handleFavorite: (favorite: boolean) => void;
  handleGift: (favorite: boolean) => void;
  handlePurchase: (favorite: boolean) => void;
}
const ItemCardPreview: FC<IItemCardPreviewProps> = ({ item, handleFavorite, handleGift, handlePurchase }) => {

  const likedIcon = useMemo(() => item.favorite ? heartFilled : heartOutline as IconProp, [item]);
  const giftIcon = useMemo(() => item.gift ? faGifts : faGift as IconProp, [item]);
  const purchasedIcon = useMemo(() => item.purchased ? checkFilled : checkOutline as IconProp, [item]);
  const linkIcon = useMemo(() => item.link_urls.length ? faArrowUpRightFromSquare : faChainBroken as IconProp, [item]);


  // should disable the gift button once the item is bought?

  useEffect(() => {
    if (item.img_url?.length) {
      setImgHasError(false);
    }
  }, [item.img_url]);

  const [imgHasError, setImgHasError] = useState(false);
  return (
    <div className="grid col-span-4 mb-8 mt-6 rounded overflow-hidden shadow-lg w-full">
      {/* Card Title */}
      <div className="
        p-3 pr-4
        border-b border-t-secondary-300 
        relative 
        overflow-hidden whitespace-nowrap text-ellipsis
        bg-bg-cold-100
        text-accent
      "
      >
        {item.title.length ? item.title : 'Your title here'}
        <a
          className="absolute top-0 right-0 pointer-events-none m-3"
          href={item.link_url}
          onError={err => console.log('error ', err)}
          target="_blank">
          <ItemIcon icon={linkIcon} className="cursor-default" />
        </a>
      </div>

      {/* Card Image */}
      <div className="aspect-square max-h-[336px] w-full py-2 mt-5 flex items-center justify-center relative">
        {!imgHasError ?
          <img
            src={item.img_url} onError={() => setImgHasError(true)}
            alt={`${item.title.length ? item.title : 'Title here'}`}
            className="h-auto flex"
          />
          :
          <>
            <img
              src={catLogo}
              alt="not found" />
            <p className="absolute bottom-1 left-1/2 -translate-x-1/2 text-bg-cold-600">
              Oops! Seems like the image URL is broken
            </p>
          </>
        }
      </div>

      {/* Card Actions */}
      <div className="px-3 mt-10 grid grid-cols-12 pb-2">
        <ItemIcon icon={likedIcon} onClick={() => handleFavorite(!item.favorite)} />
        <ItemIcon icon={giftIcon} onClick={() => handleGift(!item.gift)} />
        <ItemIcon icon={purchasedIcon} onClick={() => handlePurchase(!item.purchased)} />

        <div className="col-start-10 col-span-3 grid place-items-end text-bg-cold-600 select-none">{`${isNaN(item.price) ? 0 : item.price} ${item.currency}`}</div>
      </div>

      {/* <div className="p-3 flex h-24 justify-between overflow-hidden">
        <ItemDescription description={item.description} />
      </div> */}

    </div >
  );
};

export default ItemCardPreview;