const AddImageHint = () => {
  return (
    <div className="p-2 lg:w-[500px]">
      <h3>1. Select </h3>
      <p className="text-accent-50 ml-2">Choose an image using the input below</p>

      <h3 className="mt-2">2. Crop</h3>
      <p className="text-accent-100 ml-2">Use the cropping controls to crop your image into a square</p>
      <p className="text-accent-200 ml-2">
        To center a non-square image, you can <span className="text-bg-cold-600">zoom out</span> using the scale
      </p>
      <h3 className="mt-2">3. Preview</h3>
      <p className="text-accent-300 ml-2">
        Preview your category image <span className="text-bg-cold-600">on the left side</span>
      </p>
    </div>
  );
};

export default AddImageHint;