import { MouseEvent, PropsWithoutRef } from "react";
interface Enum {
  [id: number]: string;
}
interface ITabButton {
  index: number;
  // id: string;
  // target: string;
  className?: string;
  onClick: (event: MouseEvent, tabNumber: number) => void;
  activeTab: number;
  Enum: Enum;
}

// id,
//  target,
const TabButton = ({ index, className = '', activeTab, onClick, Enum }: PropsWithoutRef<ITabButton>) => {
  return (
    <button
      className={`
        nav-link
        block
        font-medium
        text-xs
        leading-tight
        uppercase
        border-x-0 border-t-0 border-b-2 border-transparent
        px-6
        py-3
        my-2
        hover:border-transparent hover:bg-gray-100
        focus:border-transparent
        focus-visible:border-transparent
        focus-visible:outline-transparent
        focus-visible:bg-bg-light-50
        select-none
        ${activeTab === index ? 'active' : ''}
        ${className}
      `}
      // id={id}
      data-bs-toggle="pill"
      // data-bs-target={`#${target}`}
      role="tab"
      // aria-controls={target}
      aria-selected={activeTab === index}
      onClick={event => onClick(event, index)}
    >
      {Enum[index]}
    </button>
  );
};

export default TabButton;