import { FC, PropsWithChildren, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import {
  AuthError,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAuth,
  updateProfile,
  sendEmailVerification,
  User
} from "firebase/auth";
import { AuthContext } from "./AuthContext";
import { Collections } from "src/utils/enums";
import useError from "./useError";
import { useMidnightContext } from "src/context";

export interface UserProps extends Partial<User> {
  email: string | null;
  emailVerified: boolean;
  uid: string;
  details: {
    firstName: string;
    lastName: string;
  };
}
export interface SignUpProps {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<UserProps | null>(null);
  const [token, setToken] = useState<string | undefined>();
  const navigate = useNavigate();
  const [err, setErr] = useState<AuthError | undefined>(undefined);
  const { message } = useError(err);
  const { userDetails } = useMidnightContext();
  const [isUserLoading, setIsUserLoading] = useState(false);

  useEffect(() => {
    if (message.length)
      toast.error(message);
  }, [message]);

  const handleLogin = async (email: string, password: string) => {
    try {
      setIsUserLoading(true);
      const authentification = getAuth();
      await signInWithEmailAndPassword(authentification, email, password);

      setIsUserLoading(false);
    } catch (error: any) {
      // console.log('something happened');
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // console.log(errorCode, errorMessage);
      console.log('error ', (error as AuthError).code);

      setErr(error as AuthError);
      setIsUserLoading(false);
    }
  };

  const handleSignUp = async ({ email, password, firstName, lastName }: SignUpProps) => {
    try {
      setIsUserLoading(true);
      const authentification = getAuth();
      await createUserWithEmailAndPassword(authentification, email, password);

      setIsUserLoading(false);
    } catch (error) {
      console.log('error ', (error as AuthError).code);
      setIsUserLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      setIsUserLoading(true);
      const authentification = getAuth();
      await signOut(authentification);

      setUser(null);
      localStorage.removeItem('authToken');
      navigate("/login");

      console.log("[Auth]: Signed out successfully");
      sessionStorage.removeItem('isWelcomed');
      toast.success("Signed out successfully");
      setIsUserLoading(false);
    } catch (error) {
      console.log('[Auth]: Error signing out', error);
      setIsUserLoading(false);
    }
  };

  useEffect(() => {
    const authentification = getAuth();
    const observer = authentification.onAuthStateChanged(async (loggedInUser) => {
      setIsUserLoading(true);
      if (loggedInUser) {
        // look for uid in users docs
        // update the user entry if not available

        const db = getFirestore();
        const reference = doc(db, Collections.users, loggedInUser.uid);

        try {
          const snapshot = await getDoc(reference);

          if (snapshot.exists()) {
            // User is already in db, just set user with data
            // console.log('user data', snapshot.data());

            const { details } = snapshot.data() as UserProps;
            setUser({
              ...loggedInUser as UserProps,
              details
            });
            const isWelcomed = sessionStorage.getItem('isWelcomed');

            if (details.firstName && (!isWelcomed || isWelcomed && (JSON.parse(isWelcomed) === false))) {
              toast.success(`Welcome ${details.firstName}`);
              sessionStorage.setItem('isWelcomed', JSON.stringify(true));
            }
            try {
              const token = await loggedInUser.getIdToken();
              console.log('[Auth]: Setting usar token');
              setToken(token);

              localStorage.setItem('authToken', token);
              setIsUserLoading(false);
            } catch (error) {
              console.log('Error setting token ', error);
              setIsUserLoading(false);
            }
          } else {
            // First user login
            // set the dafault db fields
            console.log('snapshot doesnt exist');

            try {
              const userObject: UserProps = {
                emailVerified: loggedInUser.emailVerified,
                // ...loggedInUser as UserProps,
                email: loggedInUser.email,
                uid: loggedInUser.uid,
                details: userDetails
              };
              await setDoc(reference, userObject);

              setUser({
                ...loggedInUser as UserProps,
                details: userDetails
              });
              await sendEmailVerification(loggedInUser, {
                url: `https://my-vanity.com/profile`,
              });
              console.log('Email sent');
              toast.info(`Psst... check your email!`, { position: 'bottom-center' });
              setIsUserLoading(false);
            } catch (error) {
              console.error('[HOOK: useAuth] error creating new snapshot', error);
              setIsUserLoading(false);
            }
            try {
              await updateProfile(loggedInUser, { displayName: `${userDetails.firstName} ${userDetails.lastName}` });
              setIsUserLoading(false);
            } catch (error) {
              console.error('[HOOK: useAuth] error updating profile', error);
              setIsUserLoading(false);
            }
          }
        } catch (error) {
          console.log('Error getting user from db', error);
          setIsUserLoading(false);
        }
      }
      setIsUserLoading(false);
    });

    return () => {
      observer();
    };
  }, [userDetails]);


  const value = {
    authToken: token,
    authUser: user,
    onLogin: handleLogin,
    onLogout: handleLogout,
    onSignUp: handleSignUp,
    userLoading: isUserLoading
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
