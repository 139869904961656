import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import AuthContainer from "./AuthContainer";
import homeBg from 'src/assets/home-bg.jpeg';
import { redirect, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Auth, getAuth, applyActionCode, updateProfile } from "firebase/auth";
import CatLoader from "../Categories/CatLoader";

interface IHandleVerify {
  auth: Auth,
  actionCode: string | null,
  continueUrl: string | null,
  lang: string;
}

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("Are you lost?");
  const [counter, setCounter] = useState(6000);
  const [startCounter, setStartCounter] = useState(false);
  const [canGoNow, setCanGoNow] = useState(false);
  const [path, setPath] = useState('/profile');

  const handleVerifyEmail = async ({ actionCode, auth, continueUrl, lang }: IHandleVerify) => {
    if (actionCode) {
      setMessage('Verifying email...');
      try {
        await applyActionCode(auth, actionCode);
        if (auth.currentUser) {
          try {
            await auth.currentUser.getIdToken(true);
            setStartCounter(true);
            setMessage("Verified successfully!");
            if (continueUrl) {
              const pathname = continueUrl.split(window.origin)[1];
              setPath(pathname);
            }
          } catch (error) {
            console.log('Error refreshing user token', error);
          }
        }
      } catch (error) {
        console.log('Error applying code', error);
      }
    }
  };

  useEffect(() => {
    if ((startCounter && counter <= 0) || canGoNow) {
      console.log('Navigating to profile page...');
      navigate({ pathname: path });
    }
  }, [path, counter, startCounter, canGoNow]);

  useLayoutEffect(() => {
    if (startCounter) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter, startCounter]);

  useLayoutEffect(() => {
    const getParameterByName = (parameter: string): string | null => {
      // console.log('params', searchParams.get(parameter));
      // console.log('params', searchParams);
      return searchParams.get(parameter);
    };
    const auth = getAuth();
    // Get the action to complete.
    const mode = getParameterByName('mode');
    // Get the one-time code from the query parameter.
    const actionCode = getParameterByName('oobCode');
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = getParameterByName('continueUrl');

    console.log('mode', mode);
    console.log('action code', actionCode);
    console.log('continueUrl', continueUrl);

    // (Optional) Get the language code if available.
    const lang = getParameterByName('lang') || 'en';
    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        // handleResetPassword({auth, actionCode, continueUrl, lang});
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        // handleRecoverEmail({auth, actionCode, lang});
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        handleVerifyEmail({ auth, actionCode, continueUrl, lang });
        break;
      default:
      // Error: invalid mode.
    }
  }, [searchParams]);
  return (
    <AuthContainer imgSrc={homeBg} title={message}>
      {/* {!startCounter && <button onClick={() => setStartCounter(true)}>Start counter</button>} */}
      {startCounter && <CatLoader showText={false} className="flex justify-center" />}
      {startCounter && <div className="mt-8 flex flex-col lg:flex-row w-full items-center justify-center">
        {counter > 0 || !canGoNow
          ? `You will be redirected to the Profile page after ${counter} seconds`
          : 'Redirecting...'}
      </div>}
      {startCounter &&
        <button
          onClick={() => setCanGoNow(true)}
          type="button"
          className="
            bg-accent-100 hover:bg-accent-50 active::bg-accent-200 focus:bg-accent-200 hover:focus:bg-accent-100 
            transition-all duration-200 ease-in-out 
            text-white text-lg font-bold
            px-8 py-4 
            rounded-md 
            w-full lg:w-2/6"
        >
          Go now
        </button>
      }

    </AuthContainer>
  );
};

export default VerifyEmail;