import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { FC, useMemo } from "react";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface CategoryImageData extends DocumentData {
  name: string;
  url: string;
  id: string;
}

interface IProps {
  categoryImage: QueryDocumentSnapshot<DocumentData>;
  selectedImage?: CategoryImageData;
  onClick: (image: CategoryImageData) => void;
  onDelete?: (image: CategoryImageData) => void;
  canDelete?: boolean;
}
const NewCategoryImage: FC<IProps> = ({ categoryImage, selectedImage, onClick, onDelete, canDelete = true }) => {
  const image = useMemo(() => ({ ...categoryImage.data(), id: categoryImage.id }) as CategoryImageData, [categoryImage]);

  return (
    <div
      className={`flex transition-all duration-500 aspect-square group col-span-1 rounded-sm overflow-hidden${selectedImage?.url === image.url
        ? ' shadow-sm shadow-accent-50 scale-110'
        : ' shadow-sm hover:scale-105'}`}
      onClick={() => onClick(image)}>
      <div className="relative flex overflow-hidden whitespace-nowrap">
        <FontAwesomeIcon icon={faCircleCheck}
          className={`text-accent-300 bg-bg-cold-50 
          absolute w-4 h-4 
          rounded-br-md
          transition-all duration-500 ease-in-out
          p-2
          ${selectedImage?.url === image.url ? ' opacity-100' : ' opacity-0'}`}
        />
        {canDelete && <FontAwesomeIcon icon={faTrashCan as IconProp} className="
          opacity-0 group-hover:opacity-100 
          absolute top-0 right-0 w-4 h-4 bg-bg-cold-50
          p-2
          text-accent-300
          transition-all duration-500 ease-in-out
          rounded-bl-md
          hover:cursor-pointer
          "
          onClick={() => onDelete && onDelete(image)}
        />}
        <img src={image.url} alt={image.name} />

        <div className="absolute bottom-0 bg-white50 w-full px-1 py-1">
          <p className="text-xs text-primary overflow-ellipsis overflow-hidden" title={image.name}>
            {image.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewCategoryImage;