import { FC } from "react";

interface IProps {
  title: string;
  value: string;
}

const InputPlaceholder: FC<IProps> = ({ title, value }) => {
  return (
    <div className="relative rounded-lg outline-none w-full mt-3">
      <span className={`
          absolute pointer-events-none -top-2 left-2 
          text-accent text-xs font-bold 
          transition-all ease-in-out duration-200
        `}>
        {title}
      </span>
      <span className="flex w-full pt-4 px-3 pb-2 mb-2 
        text-secondary pointer-events-none select-none
        border-b focus:border-b-accent-100 border-b-bg-cold 
        bg-bg-cold-100 rounded-lg"
      >
        {value}
      </span>
    </div>
  );
};

export default InputPlaceholder;