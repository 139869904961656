import { useEffect, useState } from "react";
import catLogo from '../../assets/404.gif';

interface IProps {
  src: string;
  alt?: string;
  onImgError?: (value: boolean) => void;
}
const CategoryImagePreview = ({ src, alt, onImgError }: IProps) => {

  useEffect(() => {
    if (src?.length) {
      setImgHasError(false);
    }
  }, [src]);

  const [imgHasError, setImgHasError] = useState(false);

  useEffect(() => {
    if (onImgError) {
      if (imgHasError) onImgError(true);
      else onImgError(false);
    }
  }, [imgHasError, onImgError]);

  return (
    <div className="w-auto h-auto aspect-square overflow-hidden p-4 border-bg-cold flex items-center relative">

      {!imgHasError && src
        ?
        <img
          src={src}
          onError={() => setImgHasError(true)}
          alt={alt ?? 'No default image'}
          className="w-full fade show shadow-md shadow-bg-cold-500"
        />
        :
        <>
          <img
            src={catLogo}
            alt="not found" />
          <p className="absolute bottom-0 flex w-3/4 left-1/2 -translate-x-1/2 text-bg-cold-600 text-center">
            Oops! Seems like the image URL is broken
          </p>
        </>}
    </div>
  );
};

export default CategoryImagePreview;