import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FC, FocusEvent, HTMLAttributes, HTMLInputTypeAttribute, PropsWithRef, Ref, useEffect, useLayoutEffect, useState } from "react";

interface IItemCardInput {
  value: string | number;
  onInput: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
  required?: boolean;
  className?: HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>["className"];
  containerClassName?: HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>["className"];
  long?: boolean;
  innerRef?: React.RefObject<HTMLInputElement>;
  reset?: boolean;
}

const ItemCardInput = (props: PropsWithRef<IItemCardInput>) => {
  const {
    value,
    onInput,
    placeholder,
    type = 'text',
    required = false,
    className,
    containerClassName,
    long = false,
    innerRef,
    reset = false
  } = props;

  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const handleOnFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (type === 'number') {
      event.preventDefault();
      if (value === 0 || value === '0') {
        const ev = event;
        ev.target.value = '';
        onInput(ev);
      } else if (value < 0 || (typeof value === 'string' && parseInt(value, 10) < 0)) {
        const ev = event;
        ev.target.value = `${parseInt(event.target.value, 10) * -1}`;
        onInput(ev);
      }
    }
  };
  const handleOnBlur = () => {
    setTouched(true);
  };

  useEffect(() => {
    if (type === 'number') {
      if (parseInt(`${isNaN(value as any) ? 0 : value}`[0], 10) === 0 && touched) {
        // Invalidate if first char is 0
        setIsValid(false);
      }
      else if (typeof value === 'string' && value.length === 0 && required && touched) {
        setIsValid(false);
      }
      else if (isNaN(value as number)) {
        setIsValid(false);
      }
      else {
        console.log('I am in here');
        console.log();

        setIsValid(true);
      }
    } else if (type === 'text') {
      if (typeof value === 'string' && value.length === 0 && touched && required) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [value, type, required, touched]);


  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (type === 'number') {
      const currentNumber = isNaN(event.target.value as any) ? 0 : parseInt(event.target.value, 10);
      const ev = event;
      if (currentNumber < 0) {
        ev.target.value = `${currentNumber * -1}`;
        onInput(ev);
        setTouched(true);
        return;
      }

      if (event.target.value.length === 0) {
        onInput(event);
        setTouched(true);
        return;
      }
    }

    onInput(event);
    setTouched(true);
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();

    if (type === 'number') {
      const currentNumber = isNaN(event.target.value as any) ? 0 : parseInt(event.target.value, 10);
      const ev = event;
      if (currentNumber < 0) {
        ev.target.value = `${currentNumber * -1}`;
        onInput(ev);
        setTouched(true);
        return;
      }
      if (event.target.value.length === 0) {
        onInput(event);
        setTouched(true);
        return;
      }
    }
    onInput(event);
    setTouched(true);
  };

  useEffect(() => {
    if (reset) {
      setTouched(false);
    }
  }, [reset]);


  return (
    <div className={`relative  ${containerClassName ? containerClassName : 'flex w-full mt-3'}`}>
      {long ?
        <textarea
          value={value}
          onInput={handleTextareaChange}
          minLength={4}
          className={`
            flex w-full
            outline-none border-b
            transition-all ease-in-out duration-200
            ${isValid
              ? 'text-secondary focus:border-b-accent-100 border-b-bg-cold bg-bg-cold-100'
              : 'text-rose-600 focus:border-b-rose-300 border-b-rose-500 bg-rose-50'
            }
            px-3 pb-2 pt-4 mb-2
            rounded-lg
            ${className ?? ''}
            `}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
        :
        <div className="flex items-center w-full relative">
          <input
            ref={innerRef}
            value={isNaN(value as number) ? value.toString() : value}
            onInput={handleInputChange}
            type={isPasswordShown ? 'text' : type}

            className={`
              item-input
              flex w-full
              outline-none border-b
              transition-all ease-in-out duration-200
              ${isValid
                ? 'text-secondary focus:border-b-accent-100 border-b-bg-cold bg-bg-cold-100'
                : 'text-rose-600 focus:border-b-rose-300 border-b-rose-500 bg-rose-50'
              }
          px-3 pb-2 pt-4 mb-2
          rounded-lg
          ${className ?? ''}
          `}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          {type === 'password' && (
            <FontAwesomeIcon
              icon={isPasswordShown ? faEye as IconProp : faEyeSlash as IconProp}
              className={`
                absolute right-4
                transition-all duration-200 ease-in-out cursor-pointer
                ${isValid
                  ? 'text-secondary focus:border-b-accent-100 border-b-bg-cold bg-bg-cold-100'
                  : 'text-rose-600 focus:border-b-rose-300 border-b-rose-500 bg-rose-50'
                }
                ${`${value}`.length
                  ? 'opacity-100'
                  : 'opacity-0'
                }
              `}
              onClick={() => setIsPasswordShown(!isPasswordShown)}
            />
          )}
        </div>
      }
      <span className={`
        absolute pointer-events-none ${(typeof value === "string" && value.length > 0) || typeof value === 'number'
          ? `-top-2 left-2 ${isValid ? 'text-accent' : 'text-rose-500'} text-xs font-bold`
          : `top-4 left-3 ${isValid ? 'text-secondary-100' : 'text-rose-500'}`} 
        }
          transition-all ease-in-out duration-200`}
      >{placeholder}
        {/* {required && '*'} */}
      </span>
    </div>
  );
};

export default ItemCardInput;