import { FC, HTMLAttributes, useCallback, PropsWithChildren } from "react";
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "src";
import { withContext } from "src/context";

interface IProps extends Partial<AppContext> {
  onClose: (value: any) => void;
  onSubmit: () => void;
  submitDisabled: boolean;
  isOpen: boolean;
  title: string;
  dismissTitle: string;
  submitTitle: string;
  submitColor?: 'default' | 'delete';
  containerClassName?: HTMLAttributes<HTMLDivElement>["className"];
  hideSubmitButton?: boolean;
}

const Modal: FC<IProps> = (props: PropsWithChildren<IProps>) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    children,
    submitDisabled = false,
    title,
    submitTitle,
    dismissTitle,
    submitColor = 'default',
    containerClassName = '',
    hideSubmitButton,
    // setIsModalOpen,
  } = props;


  const closeModal = useCallback(
    () => {
      onClose && onClose(false);
      // setIsModalOpen(false);
    },
    [],
  );

  return (
    <>
      <div
        className={`${isOpen
          ? 'show overflow-x-hidden overflow-y-auto fixed inset-0 z-999 outline-none focus:outline-none justify-center items-center flex pointer-events-none '
          : 'hidden '} fade`}
      >
        <div className={"relative w-auto my-6 mx-auto max-w-3xl min-w-[350px] pointer-events-auto " + containerClassName}>
          {/*content*/}
          <div className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ${submitColor === 'delete' ? 'selection:bg-rose-600' : ''}`}>
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-bg-light-100 rounded-t">
              <h3 className={`text-3xl font-semibold`}>
                {title}
              </h3>
              <button
                className="p-1 ml-auto border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={closeModal}
              >
                <span className="bg-transparent text-secondary font-bold h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <FontAwesomeIcon className={`text-secondary hover:text-primary hover:cursor-pointer ${submitColor === 'delete' ? 'text-rose-600 hover:text-rose-800' : ''}`} icon={faClose} />
                </span>
              </button>
            </div>

            {/*body*/}
            {children}

            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-bg-light-100 rounded-b">
              <button
                className={`
                ${submitColor === 'delete' ? 'text-rose-500 selection:bg-rose-600' : 'text-bg-light'}
                  text-bg-light
                  background-transparent 
                  outline-none
                  font-bold uppercase text-sm
                  px-6 py-2 
                  mr-1 mb-1 
                  focus:outline-none 
                  ease-linear transition-all duration-150"
                `}
                type="button"
                onClick={closeModal}
              >
                {dismissTitle}
              </button>
              {!hideSubmitButton && (
                <button
                  className={`
                  ${submitColor === 'delete' ? 'from-rose-500 to-rose-700 bg-gradient-to-br' : 'bg-accent'} text-white 
                  font-bold uppercase text-sm 
                  px-6 py-3 
                  outline-none rounded shadow 
                  disabled:bg-bg-cold-300 disabled:shadow-none 
                  active:bg-secondary-600 
                  hover:shadow-lg 
                  focus:outline-none mr-1 mb-1 
                  ease-linear transition-all duration-150"
                `}
                  type="button"
                  onClick={() => onSubmit()}
                  disabled={submitDisabled}
                >
                  {submitTitle}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={closeModal}
        className={`${isOpen ? 'opacity-25 fixed w-full h-full inset-0 z-40 bg-primary-900 show' : ''} fade`}></div>
    </>
  );
};

export default withContext(Modal);